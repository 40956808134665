import React from 'react';

export class ClientSupport extends React.Component {

	render() {
		return (
			<ul>
				<li>All clients are assigned an Account Manager, to allow for continuity of services offered and marketing planning.</li>
				<li>Any and all requests or concerns from a client should be addressed to their Account Manager, unless other direct service assignments are made.</li>
				<li>Any client should feel free to escalate any issue to Carole Cross, Founder and CEO, at any time (carole@mobile-marketing.agency; office 484-944-1422 x101; mobile 484-941-4729).</li>
			</ul>
		)
	}

}