import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Statistic } from '../../../helpers/uiComponents';
import { numberToLocal, numberToPrice } from '../../../helpers/uiFunctions';
import { getRugsData } from '../../data/data';

class RugorderQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sales: null,
            count: null,
            commission: null,
        }
    }
    componentDidMount() {
        getRugsData(
            this.props.currentClient.clientCode,
            this.props.endpoints.serviceProducts,
            this.props.dates[0],
            this.props.dates[1],
            this.props.currentClient.rugAffiliateCode,
            localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success) {
                var orders = response.result;
                var total_sales = 0;
                var total_discount = 0;
                var total_commission = 0;
                for (var i = orders.items.length - 1; i >= 0; i--) {
                    if (orders.items[i].total_invoiced) {
                        total_sales = total_sales + orders.items[i].total_invoiced;
                    }
                    total_discount = total_discount + orders.items[i].discount_amount;
                    total_commission = total_commission + this.getCommissionEarned(orders.items[i].subtotal, orders.items[i].discount_amount, orders.items[i].total_refunded, orders.items[i].status)
                }
                this.setState({
                    count: orders.total_count,
                    sales: total_sales,
                    commission: total_commission,
                });
            }
        });
    }
    getCommissionEarned(subtotal, discount, refund, status) {
		if (!refund) { refund = 0 }
		var total = subtotal + discount - refund;
		if (total <= 0 || status === "closed" || status === "canceled") {
			total = 0
		} else {
			total = total * 0.2
		}
		return (total);
	}
    render() {
        const { sales, count, commission } = this.state;
        return(
           <React.Fragment>
                {(this.props.currentClient.rugAffiliateCode !== null && this.props.currentClient.rugAffiliateCode !== "") && (
                    <React.Fragment>
                        {(this.props.onClick) && (
                            <Router>
                                <Link to="metrics-rugsShop" className="button white learn-more" onClick={() => this.props.onClick("metrics-rugsShop")}>
                                    <i className="fas fa-chart-line"></i>
                                </Link>
                            </Router>
                        )}
                        <h3>RUGS.SHOP ORDERS</h3>
                        <section className="stats row">
                            <Statistic
                                label="Total Invoiced"
                                value={numberToPrice(sales)}
                                hideHistorical={true}
                            />
                            <Statistic
                                label="Total Orders"
                                value={numberToLocal(count)}
                                hideHistorical={true}
                            />
                            <Statistic
                                label="Total Commission"
                                value={numberToPrice(commission)}
                                hideHistorical={true}
                            />
                        </section>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
export default RugorderQuickStat;