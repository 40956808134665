import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Statistic } from '../../../helpers/uiComponents';
import { numberToLocal, numberToPrice } from '../../../helpers/uiFunctions';
import { loadFBtotals } from '../../data/facebookService';

class FacebookQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fbData: null,
        }
    }
    componentDidMount() {
        loadFBtotals(
			this.props.endpoints,
			this.props.dates,
			this.props.currentClient.clientCode,
			localStorage.getItem('accessToken')
		).then(response => {
			if (response.success) {
				var data = [];
				var fields = response.result.meta.query.fields;
				var values = response.result.data[1];				
				if (response.result.data.length > 0) {
					for (let index = 0; index < fields.length; index++) {
						data[fields[index].id] = values[index];
					}
					this.setState({
						fbData: data,
					});
				} else {
					this.setState({
						fbData: "empty",
					});
				}
			} else {
				this.setState({
					fbData: "failed",
				});
			}
		});
    }
    render() {
        const { fbData } = this.state;
        return(
            <React.Fragment>
                {(this.props.onClick) && (
                    <Router>
                        <Link to="metrics-socialFB" className="button white learn-more" onClick={() => this.props.onClick("metrics-socialFB")}>
                            <i className="fas fa-chart-line"></i>
                        </Link>
                    </Router>
                )}
                <h3>FACEBOOK Advertising</h3>
                <section className="stats row">
                    {(fbData === "failed") ? (
                        <p>There may have been an error connecting to Facebook, or Facebook is not configured for your Portal.</p>
                    ) : (
                        <React.Fragment>
                            <Statistic
                                label="Total Spend"
                                value={(fbData) ? (
                                            (fbData !== "empty") ? (numberToPrice(fbData.cost)) : (0)
                                        ) : (
                                            "calculating..."
                                        )}
                                hideHistorical={true}
                            />
                            <Statistic
                                label="Total Impressions"
                                value={(fbData) ? (
                                    (fbData !== "empty") ? (numberToLocal(fbData.impressions)) : (0)
                                ) : (
                                    "calculating..."
                                )}
                                hideHistorical={true}
                            />
                            <Statistic
                                label="Total Leads"
                                value={(fbData) ? (
                                    (fbData !== "empty") ? (numberToLocal(fbData["onsite_conversion.lead_grouped"])) : (0)
                                ) : (
                                    "calculating..."
                                )}
                                hideHistorical={true}
                            />
                        </React.Fragment>
                    )}
                </section>
            </React.Fragment>
        )
    }
}
export default FacebookQuickStat;