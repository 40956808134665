import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Statistic } from '../../../helpers/uiComponents';
import { getPercentChange, numberToLocal, numberToPrice, secondsToTime } from '../../../helpers/uiFunctions';

class CallsQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            callsCount: null,
            oldCallsCount: null,
            callDuration: null,
            oldCallDuration: null,
            callValue: null,
            callWon: null,
        }
    }
    componentDidUpdate(prevProps) {
        try {
			if (this.props.data && (this.props.data !== prevProps.data)) {
                this.setState({
                    callsCount: this.props.data[0],
                    oldCallsCount: this.props.data[1],
                    callDuration: this.props.data[2],
                    oldCallDuration: this.props.data[3],
                    callValue: this.props.data[4],
                    callWon: this.props.data[5],
                });
			}
		} catch (error) {
			console.log(error);
		}
    }
    render() {
        const { callsCount, oldCallsCount, callDuration, oldCallDuration, callValue, callWon } = this.state;
        return(
            <React.Fragment>
                {(this.props.onClick) && (
                    <Router>
                        <Link to="metrics-calls" className="button white learn-more" onClick={() => this.props.onClick("metrics-calls")}>
                            <i className="fas fa-chart-line"></i>
                        </Link>
                    </Router>
                )}
                <h3>CALLS</h3>
                <section className="stats row">
                    <Statistic
                        label="Total Calls"
                        value={(callsCount !== null) ? numberToLocal(callsCount) : ("calculating...")}
                        status={(callsCount !== null && oldCallsCount !== null) && getPercentChange(callsCount, oldCallsCount)[1]}
                        percent={(callsCount !== null && oldCallsCount !== null) && getPercentChange(callsCount, oldCallsCount)[0]}
                        hideHistorical={this.props.hideHistorical}
                    />
                    <Statistic
                        label="Average Call Length"
                        value={(callDuration !== null) ? secondsToTime(callDuration) : ("calculating...")}
                        status={(callDuration !== null && oldCallDuration !== null) && getPercentChange(callDuration, oldCallDuration)[1]}
                        percent={(callDuration !== null && oldCallDuration !== null) && getPercentChange(callDuration, oldCallDuration)[0]}
                        hideHistorical={this.props.hideHistorical}
                    />
                    <Statistic
                        label="Total Lead Value"
                        value={(callValue !== null) ? numberToPrice(callValue) : ("calculating...")}
                        hideHistorical={"true"}
                    />
                    <Statistic
                        label="Total Lead Value Won"
                        onClick={this.props.onClick}
                        link="metrics-calls"
                        value={(callWon !== null) ? numberToPrice(callWon) : ("calculating...")}
                        hideHistorical={"true"}
                    />
                </section>
            </React.Fragment>
        )
    }
}
export default CallsQuickStat;