import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Statistic } from '../../../helpers/uiComponents';
import { getPercentChange, numberToLocal } from '../../../helpers/uiFunctions';

class LeadsQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leadCount: null,
            oldLeadCount: null,
            topChannel: null,
            oldTopChannel: null,
            topForm: null,
            oldTopForm: null,
        }
    }
    componentDidUpdate(prevProps) {
        try {
			if (this.props.data && (this.props.data !== prevProps.data)) {
                this.setState({
                    leadCount: this.props.data[0],
                    oldLeadCount: this.props.data[1],
                    topChannel: this.props.data[2],
                    oldTopChannel: this.props.data[3],
                    topForm: this.props.data[4],
                    oldTopForm: this.props.data[5],
                });
			}
		} catch (error) {
			console.log(error);
		}
    }
    render() {
        const { leadCount, oldLeadCount, topChannel, oldTopChannel, topForm, oldTopForm } = this.state;
        return(
            <React.Fragment>
                {(this.props.onClick) && (
                    <Router>
                        <Link to="metrics-leads" className="button white learn-more" onClick={() => this.props.onClick("metrics-leads")}>
                            <i className="fas fa-chart-line"></i>
                        </Link>
                    </Router>
                )}
                <h3>LEADS, CHANNELS, FORMS</h3>
                <section className="stats row">
                    <Statistic
                        label="Total Web Leads"
                        value={(leadCount !== null) ? numberToLocal(leadCount) : ("calculating...")}
                        status={(leadCount !== null && oldLeadCount) && getPercentChange(leadCount, oldLeadCount)[1]}
                        percent={(leadCount !== null && oldLeadCount) && getPercentChange(leadCount, oldLeadCount)[0]}
                        hideHistorical={this.props.hideHistorical}
                    />
                    <Statistic
                        label={(topChannel) ? ((topChannel !== "empty") ? ("Top Lead Channel: " + topChannel.key) : ("Top Lead Channel: ")) : ("Top Lead Channel: ")}
                            onClick={this.props.onClick}
                            link="metrics-leadsChannel"
                        value={(topChannel) ? ((topChannel !== "empty") ? (numberToLocal(topChannel.val)) : ("No Top Channel")) : ("calculating...")}
                        status={(topChannel) ? ((topChannel !== "empty") ? (getPercentChange(topChannel.val, oldTopChannel.val)[1]) : (0)) : ("down")}
                        percent={(topChannel) ? ((topChannel !== "empty") ? (getPercentChange(topChannel.val, oldTopChannel.val)[0]) : (0)) : ("0")}
                        hideHistorical={this.props.hideHistorical}
                    />
                    <Statistic
                        label={(topForm) ? ((topForm !== "empty") ? ("Top Lead Form: " + topForm.key) : ("Top Lead Form: ")) : ("Top Lead Form: ")}
                        value={(topForm) ? ((topForm !== "empty") ? (numberToLocal(topForm.val)) : ("No Top Form")) : ("calculating...")}
                        status={(topForm) ? ((topForm !== "empty") ? (getPercentChange(topForm.val, oldTopForm.val)[1]) : (0)) : ("down")}
                        percent={(topForm) ? ((topForm !== "empty") ? (getPercentChange(topForm.val, oldTopForm.val)[0]) : (0)) : ("0")}
                        hideHistorical={this.props.hideHistorical}
                    />
                </section>
            </React.Fragment>
        )
    }
}
export default LeadsQuickStat;