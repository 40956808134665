import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Statistic } from '../../../helpers/uiComponents';
import { numberToLocal, numberToPrice } from '../../../helpers/uiFunctions';
import { loadAdwords } from '../../data/supermetricsService';


class AdwordsQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ads: null,
        }
    }
    componentDidMount() {
        loadAdwords(
            ["Cost","Impressions","Clicks","Conversions","CostPerConversion",],
            "Campaignstatus != removed",
            null,
            10,
            true,
            this.props.endpoints,
            this.props.dates,
            this.props.currentClient.clientCode,
            localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success && response.result.data.length > 0) {
                var data = {};
                var keys = response.result.data[0];
                var values = response.result.data[1];
                keys.forEach((key, i) => data[key] = values[i]);
                this.setState({ ads: data });
            } else {
				this.setState({
					ads: "failed",
				});
			}
		});
    }
    render() {
        const { ads } = this.state;
        return(
            <React.Fragment>
                {(this.props.onClick) && (
                    <Router>
                        <Link to="metrics-adwords" className="button white learn-more" onClick={() => this.props.onClick("metrics-adwords")}>
                            <i className="fas fa-chart-line"></i>
                        </Link>
                    </Router>
                )}
                <h3>GOOGLE ADWORDS (PAID)</h3>
                <section className="stats row">
                    {(ads === "failed") ? (
                        <p>There may have been an error connecting to Adwords, or Adwords is not configured for your Portal.</p>
                    ) : (
                        <React.Fragment>
                            <Statistic	
                                label="Total Spend"
                                value={(ads && ads.Cost) ? (numberToPrice(ads['Cost'])) : ("calculating...")}
                                status={(ads && (ads['Change % Cost'] < 0)) ? ("down") : ("up")}
                                percent={(ads) ? (ads['Change % Cost']) : (0)}
                                hideHistorical={this.props.hideHistorical}
                            />
                            <Statistic
                                label="Total Impressions"
                                value={(ads && ads.Impressions) ? (numberToLocal(ads['Impressions'])) : ("calculating...")}
                                status={(ads && (ads['Change % Impressions'] < 0)) ? ("down") : ("up")}
                                percent={(ads) ? (ads['Change % Impressions']) : (0)}
                                hideHistorical={this.props.hideHistorical}
                            />
                            <Statistic
                                label="Total Conversions"
                                value={(ads && ads.Conversions) ? (numberToLocal(ads['Conversions'])) : ("calculating...")}
                                status={(ads && (ads['Change % Conversions'] < 0)) ? ("down") : ("up")}
                                percent={(ads) ? (ads['Change % Conversions']) : (0)}
                                hideHistorical={this.props.hideHistorical}
                            />
                        </React.Fragment>
                    )}
                </section>
            </React.Fragment>
        )
    }
}
export default AdwordsQuickStat;