import React from 'react';
import { formatDateDisplay, formatDateToMonthDayAndStaticWord } from '../../helpers/uiFunctions';

export class Term extends React.Component {
	render() {
		const prodSite = this.props.prodSite;

		return (
			<ul>
				<li>The Velocity program is a 12-month term, with automatic renewal as of the day your site went live.</li>
				<li>Cancellation of the automatic renewal must be made in writing 45 days before your renewal date.</li>
				{(prodSite && prodSite.goLive !== null) && (
					<li>Go Live Date: {(prodSite.goLive) && (formatDateDisplay(prodSite.goLive))}</li>
				)}
				{(prodSite && prodSite.termRenewal !== null) && (
					<li>Term Renewal Date: {(prodSite.termRenewal) && (formatDateToMonthDayAndStaticWord(prodSite.termRenewal, 'annually'))}</li>
				)}
			</ul>
		)
	}

}