import React from 'react';
import Chart from 'chart.js/auto';
import * as Data from '../data/data';


import { InputRow } from '../../helpers/inputRow';
import { ReportHeading } from '../../helpers/uiComponents';

class GetStats extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
			distribution: [],
			keywords: [],
			keywordList: [],
			sortVal: 0,   //no sorting
			loading: true,
			message: false,
        };
    }
	componentDidMount() {
		try {
			if (this.props.stats) {
				this.handleData(this.props.stats);
			}
		} catch (error) {
			console.log(error);
		}
	}
    componentDidUpdate(prevProps) {    	
    	try{
			if ( (this.props.stats !== prevProps.stats) && (this.state.loading === true)) {
				this.handleData(this.props.stats);
			}
			
		} catch (error) {
			console.log(error);
		}
    }
	handleData(data) {
		if (data.length !== 0) {
			var arr = [{"name": "all keywords...","value": "clear"},];
			var distinctkeywords = [...new Set(data[1].Response.Result.map(x => x.Keyword))];
			for (var i = distinctkeywords.length - 1; i >= 0; i--) {
				arr.push(
					{"name": distinctkeywords[i], "value": distinctkeywords[i]},
				);
			}
			arr = arr.sort((a, b) => (a["name"] > b["name"]) ? 1 : b["name"] > a["name"] ? -1 : 0);
			this.setState({
				loading: false,
				message: false,
				distribution: data[0].Response.RankDistribution,
				keywords: data[1].Response.Result,
				keywordList: arr,
			});
			this.buildChart(data[0].Response.RankDistribution);

		} else {
			// console.log("has no stats");
			this.setState({
				message: true,
				loading: false,
			});
		}
	}

    getKeywordRanking(keywordId) {
    	var clientCode = this.props.currentClient.clientCode;
    	var dropdown = document.getElementById('graph-'+keywordId);
    	
    	if (!dropdown.classList.contains("rendered")) {
    		this.setState({loading: true});
    		dropdown.classList.add("rendered");
    		
    		Data.getKeywordRankingOverTime(
	            this.props.serviceAnalytics,
	            clientCode,
	            keywordId,
	            this.props.accessToken
	        ).then(data => {
	            var array = data[0].Response.Result.reverse();
	            this.buildKeywordChart(array, keywordId);
	        });
    	}

        dropdown.classList.toggle("active");
    }

    buildChart(distribution) {
		this.setState({
			message: false,
			loading: false,
		});
		if (distribution) {
			distribution.reverse();

			const chartColors = [
				"rgb(51,66,89)", "rgb(162,27,173)", "rgb(35,71,216)", "rgb(50,182,240)",
				"rgb(108,153,40)", "rgb(39,153,126)", "rgb(190,209,47)", "rgb(238,229,8)",
				"rgb(255,163,0)", "rgb(242,102,88)", "rgb(223,34,117)", "rgb(216,37,37)"
			];

			var labels = [];
			
			var OneToFive = []
			var SixToTen = []; var ElevenToTwenty = []; var TwentyOneToThirty = []; var ThirtyOneToForty = []; 
			var FiftyOneToHundred = []; var FortyOneToFifty = []; var NonRanking = [];

			for (var i = distribution.length - 1; i >= 0; i--) {
				labels.push(distribution[i].date);
				OneToFive.push(
					parseInt(distribution[i].Google.One) +
					parseInt(distribution[i].Google.Two) +
					parseInt(distribution[i].Google.Three) +
					parseInt(distribution[i].Google.Four) +
					parseInt(distribution[i].Google.Five)
				)
				SixToTen.push(distribution[i].Google.SixToTen);
				ElevenToTwenty.push(distribution[i].Google.ElevenToTwenty);
				TwentyOneToThirty.push(distribution[i].Google.TwentyOneToThirty);
				ThirtyOneToForty.push(distribution[i].Google.ThirtyOneToForty);
				FortyOneToFifty.push(distribution[i].Google.FortyOneToFifty);
				FiftyOneToHundred.push(distribution[i].Google.FiftyOneToHundred);
				NonRanking.push(distribution[i].Google.NonRanking);
			}

			var chartData = {
				"labels": labels,
				"datasets": [
					{
						"label":"Not Ranking",
						"stack": "0",
						"backgroundColor": chartColors[11],
						"data": NonRanking
					},{
						"label":"51st - 100th",
						"stack": "0",
						"backgroundColor": chartColors[8],
						"data": FiftyOneToHundred
					},{
						"label":"41st - 50th",
						"stack": "0",
						"backgroundColor": chartColors[7],
						"data": FortyOneToFifty
					},{
						"label":"31st - 40th",
						"stack": "0",
						"backgroundColor": chartColors[4],
						"data": ThirtyOneToForty
					},{
						"label":"21st - 30th",
						"stack": "0",
						"backgroundColor": chartColors[3],
						"data": TwentyOneToThirty
					},{
						"label":"11th - 20th",
						"stack": "0",
						"backgroundColor": chartColors[2],
						"data": ElevenToTwenty
					},{
						"label":"6th - 10th",
						"stack": "0",
						"backgroundColor": chartColors[1],
						"data": SixToTen
					},{
						"label":"1st - 5th",
						"stack": "0",
						"backgroundColor": chartColors[0],
						"data": OneToFive
					}
				]
			};

			var ctx = document.getElementById('graph-distribution');
			new Chart(ctx, {
				type: 'bar',
				data: chartData,
				options: {
					plugins:{
						title: {
							display: true,
							text: 'Keyword Distribution',
							fontSize: 18,
							fontColor: '#000'
						},
						aspectRatio: 1,
						maintainAspectRatio: false,
						legend: {
							display: false,
						},
						tooltips: {
							mode: 'index',
							intersect: false
						},
						responsive: true,
					},
					layout: {
						padding: {
							left: 50,
							right: 50,
							top: 25,
							bottom: 25
						}
					},
					scales: {
						xAxes: [{
							stacked: true,
						}],
						yAxes: [{
							stacked: true
						}]
					},
					animations: {
						linear: {
							duration: 1000,
							easing: 'linear',
							from: 1,
							to: 0,
							loop: true
						}
					},
				}
			});
		}
	}


	buildKeywordChart(array, keywordId) {
		if (array) {
			var data = [];
			var options = [];

			for (var i = array.length - 1; i >= 0; i--) {
				data.push(array[i].Google.Rank);
				options.push(array[i].date);
			}
			this.setState({loading: false});
			var ctx = document.getElementById('graph-keyword-'+keywordId);
			new Chart(ctx, {
				"type":"line",
				"data": {
					"labels":options,
					"datasets":[
						{
							data:data,
							backgroundColor: "rgba(72, 152, 187, 0.3)",
							borderColor: "rgba(72, 152, 187, 1)",
							pointBackgroundColor: "rgba(190, 209, 47, 1)",
							pointRadius: 5,
							fill: "start",
						}
					]
				},
				options: {
					plugins: {
						aspectRatio: 1,
						maintainAspectRatio: false,
						legend: {
							display: false,
						},
						title: {
							display: true,
							text: "Keyword Ranking Over Time",
							fontSize: 16,
						},
					},
					layout: {
						padding: {
							left: 40,
							right: 20,
							top: 25,
							bottom: 25
						}
					},
					scales: {
						xAxes: [{
							stacked: true
						}],
						yAxes: [{
							stacked: true
						}]
					},
					animations: {
						linear: {
							duration: 1000,
							easing: 'linear',
							from: 1,
							to: 0,
							loop: true
						}
					},
				}
			});
		}
	}

    sortBy(prop) {
    	console.log(prop);

		var list = this.state.keywords;
		var sort = this.state.sortVal;

		console.log(list);

		if (sort === 0 || sort === 2) {
			list = list.sort((a, b) => (a[prop] > b[prop]) ? 1 : b[prop] > a[prop] ? -1 : 0);
			sort = 1;
		} else {
			list = list.sort((a, b) => (a[prop] > b[prop]) ? -1 : b[prop] > a[prop] ? 1 : 0);
			sort = 2;
		}
		this.setState({
			keywords: list,
			sortVal: sort,  // up or down
		});
	}
	numberSort(prop) {
		console.log("numberSort");


		var list = this.state.keywords;
		var sort = this.state.sortVal;

		console.log(list);

		if (sort === 0 || sort === 2) {
			list = list.sort((a, b) => a[prop] - b[prop]);
			sort = 1;
		} else {
			list = list.sort((a, b) => b[prop] - a[prop]);
			sort = 2;
		}
		this.setState({
			keywords: list,
			sortVal: sort,  // up or down
		});
	}
	filter() {
		var list = this.props.stats[1].Response.Result;
		var val = document.querySelector('select[name="keywordList"]').value;

		if (val !== "clear") {
			list = list.filter(function(e){
				return e.Keyword === val;
			});
			list = list.sort((a, b) => (a["Keyword"] > b["Keyword"]) ? 1 : b["Keyword"] > a["Keyword"] ? -1 : 0);
		}
		this.setState({
			keywords: list,
		})
	}

	printElem() {
		window.print();
	}

    render() {
		const {keywords, loading, message, keywordList } = this.state;
		const hideHistorical = this.props.hideHistorical;

		return(
			(this.props.currentClient) && (
				<div id="metrics-getstats" className="report metrics-getstats printable">					
					<ReportHeading
						title="Keyword Analytics Report"
						name={this.props.currentClient.name}
						dates={this.props.dates}
						hideHistorical={hideHistorical}
					/>

					{loading === true && (
						<React.Fragment>
			    			<div className="report-loading">
			    				<p><i className="fas fa-circle-notch"></i></p>
			    			</div>
			    		</React.Fragment>
					)}

					{message && (
						<React.Fragment>
							<h3>We were unable to fetch your Keyword Analytics Report.</h3>
							<p>There may have been an error connecting to our services, or Keyword Analytics is not configured for your Portal.</p>
							<p>Please contact your Account Manager for assistance.</p>
						</React.Fragment>
					)}

					<button className="white download-report" onClick={() => this.printElem()}>Print/Download Report</button>

					{!message && (
						<section className="stats-graph">
							<div className="graph wide">
								<div className="graph-container">
									<canvas className="chart" id="graph-distribution" />
								</div>
							</div>
						</section>
					)}
					
					{!message && (
						<section className="calls-list">
							<h3>Keywords</h3>
							<InputRow type="select" property="keywordList" options={keywordList} onChange={() => this.filter()} />
							<div className="table-heading">
								<div className="sortable" onClick={() => this.sortBy("Keyword")}>Keyword <span><i className="fas fa-sort"></i></span></div>
								{/* <div className="sortable" onClick={() => this.sortBy("KeywordLocation")}>Location <span><i className="fas fa-sort"></i></span></div> */}
								<div className="sortable" onClick={() => this.sortBy("KeywordDevice")}>Device <span><i className="fas fa-sort"></i></span></div>
								<div className="sortable" onClick={() => this.numberSort("KeywordGoogleRank")}>Rank <span><i className="fas fa-sort"></i></span></div>
								<div className="sortable" onClick={() => this.sortBy("KeywordGoogleUrl")}>URL <span><i className="fas fa-sort"></i></span></div>
								{/*
									<div className="sortable" onClick={() => this.sortBy("GlobalSearchVolume")}>Glogal Searches <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("RegionalSearchVolume")}>Regional Searches <span><i className="fas fa-sort"></i></span></div>
								*/}
							</div>
							<ul className="table-body">
								{keywords.map(keyword => 
									<React.Fragment key={keyword.Id}>
										<li key={keyword.Id} onClick={() => this.getKeywordRanking(keyword.Id)}>
											<div>{keyword.Keyword}</div>
											{/* <div>{keyword.KeywordLocation}</div> */}
											<div>{keyword.KeywordDevice}</div>
											<div>{keyword.KeywordGoogleRank}{(keyword.KeywordGoogleRank === 100) && ("+")}</div>
											<div className="url">{keyword.KeywordGoogleUrl}</div>
											{/*
												<div>{numberToLocal(keyword.KeywordStats.GlobalSearchVolume)}</div>
												<div>{numberToLocal(keyword.KeywordStats.RegionalSearchVolume)}</div>
											*/}
										</li>
										<section className="stats-graph" id={"graph-"+keyword.Id}>
											<div className="graph wide">
												<div className="graph-container">
													<canvas className="chart" id={"graph-keyword-"+keyword.Id} />
												</div>
											</div>
										</section>
									</React.Fragment>
								)}
							</ul>
						</section>
					)}
				</div>
			)
		)
	}

}
export default GetStats;