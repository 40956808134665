import React from 'react';

import { Statistic, ReportHeading } from '../../helpers/uiComponents';
import { numberToPrice, numberToLocal } from '../../helpers/uiFunctions';

import { InputRow } from '../../helpers/inputRow';
import { getSocialAccountsByGroup, loadFBaccounts, loadFBtotals } from '../data/facebookService';
import { errorMessage } from '../../helpers/uiHelpers';

class SocialFacebook extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
			accounts: [],
			allData: [],
			loading: true,
			sortVal: 0,
			message: null,
        };
        this.filterBy = this.filterBy.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
    }
    componentDidMount() {
    	this.setState({hideHistorical: true});
		this.loadData();
		getSocialAccountsByGroup(
			this.props.endpoints,
			this.props.currentClient.clientCode,
			"social",
			localStorage.getItem('accessToken')
		).then(account => {
			if (account.success) {
				let fbCred = account.result.find(x => x.platform === 'facebook');
				this.setState({
					fbURL: fbCred.url,
				});
			}
		})
    }
	loadData() {
		loadFBtotals(
			this.props.endpoints,
			this.props.dates,
			this.props.currentClient.clientCode,
			localStorage.getItem('accessToken')
		).then(response => {			
			if (response.success) {
				var data = [];
				var fields = response.result.meta.query.fields;
				var values = response.result.data;
				values.shift();
				
				if (values.length > 0) {
					for (let index = 0; index < fields.length; index++) {
						data[fields[index].id] = values[index];
					}
					this.setState({
						allData: data,
					});
				}
			} else {
				this.setState({
					message: errorMessage,
					loading: false,
				});
			}
		});
		loadFBaccounts(
			this.props.endpoints,
			this.props.dates,
			this.props.currentClient.clientCode,
			localStorage.getItem('accessToken')
		).then(response => {
			// console.log(response);
			if (response.success) {
				this.setState({loading: false});
				var data = [];
				var fields = response.result.meta.query.fields;
				var campaigns = response.result.data
				campaigns.shift();

				for (let i = 0; i < campaigns.length; i++) {
					var arr = []
					for (let x = 0; x < fields.length; x++) {
						arr[fields[x].id] = campaigns[i][x];
					}
					data.push(arr);
				}								
				var accts = this.groupBy(data, 0);
				console.log(accts);

				this.setState({
					accounts: accts,
				});

			} else {
				this.setState({
					message: errorMessage,
					loading: false,
				});
			}
		})
	}
	getTotal(arrays, index) {
		var value = 0;
		//console.log(arrays, index);
		for (let y = 0; y < arrays.length; y++) {
			value = value + arrays[y][index];
		}
		return value;
	}
	getTotalByType(arrays, index, fbType) {
		var value = 0;
		var leadValue = 0;
		var trafficValue = 0;

		for (let y = 0; y < arrays.length; y++) {
		    if (fbType === 'leads') {
		        if (arrays[y]["adcampaign_name"].startsWith("Lead Gen")) {
			        value = value + arrays[y][index];
			    }
            }
		    if (fbType === 'traffic') {
		        if (!arrays[y]["adcampaign_name"].startsWith("Lead Gen")) {
			        value = value + arrays[y][index];
			    }
            }

		}
		return value;
	}

    groupBy(array, property) {
	    // group the campaigns by accountId
	    var hash = {};
	    for (var i = 0; i < array.length; i++) {
	        if (!hash[array[i][property]]) hash[array[i][property]] = [];
	        hash[array[i][property]].push(array[i]);
	    }
	    // push them into campaigns array so i can map them in render
	    var accts = []	    
	    for (const group in hash) {
	    	accts.push(hash[group]);
	    }

	    return accts
	}

	componentDidUpdate(prevProps) {
		// console.log(this.props.stats);
		try{
			if (this.props.stats && (this.props.stats !== prevProps.stats)) {
				if(!this.props.stats.message) {
					this.setState({
						loading: false,
						message: null,
					});
					if (this.props.isRetailer === true) {
						this.setState({name: this.props.retailer.name})
					} else {
						this.setState({name: this.props.currentClient.name})
					}
				} else {
					this.setState({
						loading: false,
						message: this.props.stats.message,
					});
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	getCpl(account) {
		// console.log("getCpl")
		var spend = 0;
		var leads = 0;
		// console.log(data);

		//console.log(this.state)

		for (var i = account.length - 1; i >= 0; i--) {
			if (
				(account[i].campaignName.indexOf('Lead', 0) !== -1) ||
				(account[i].campaignName.indexOf('lead', 0) !== -1)
			) {
				spend = spend + account[i].spend;
				leads = leads + account[i].leads;
			}
			if (this.state.clientCode === "fyw") {
				spend = spend + account[i].spend;
				leads = leads + account[i].leads;
			}
		}
		// console.log(spend, leads);
		// console.log(spend/leads);
		return spend/leads;
	}

	sortBy(prop, acct, index) {
		var allAccounts = this.state.accounts;

		var list = acct;
		var sort = this.state.sortVal;
		if (sort === 0 || sort === 2) {
			list = list.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1);
			sort = 1;
		} else {
			list = list.sort((a, b) => (a[prop] < b[prop]) ? 1 : -1);
			sort = 2;
		}
		allAccounts[index] = list

		this.setState({
			accounts: allAccounts,
			sortVal: sort,  // up or down
		});
	}



	filterBy(e) {
		var val = e.target.value;
		var accts = this.groupBy(this.props.stats.result,'accountId')
		console.log(val, accts)

		accts[0] = accts[0].filter( campaign => { return val.indexOf( campaign.campaignName.split(" - ")[0] ) === 0 });
		this.setState({
			accounts: accts,
		});
		console.log(val, accts)
	}
	clearFilter() {
		this.setState({
			accounts: this.groupBy(this.props.stats.result,'accountId'),
		});
	}
	viewCamp(a,b) {
    	var elem = document.getElementById("camp-"+a+"-"+b)
    	elem.classList.toggle("active");
    }

	render() {
		const { accounts, name, hideHistorical, loading, allData, fbURL, message } = this.state;

		const isRetailer = this.props.isRetailer;
		const retailerList = this.props.retailerList;
		const roles = this.props.roles;

		return (
			<div id="metrics-socialFB" className="report metrics-socialFB">
				<ReportHeading
					title="Facebook Advertising Campaigns"
					name={name}
					dates={this.props.dates}
					hideHistorical={hideHistorical}
				/>
				{(fbURL) && (
					<p><a href={fbURL} target="_blank" rel="noopener noreferrer" className="button link">View Facebook Account</a></p>
				)}
				{loading === true && (
					<React.Fragment>
						<h3>Fetching your Facebook Advertising Statistics....</h3>
		    			<div className="report-loading">
		    				<p><i className="fas fa-circle-notch"></i></p>
		    			</div>
		    		</React.Fragment>
				)}
				{(message) && (
					<React.Fragment>
						<h3>We were unable to fetch your Facebook Advertising Statistics.</h3>
						<p>There may have been an error connecting to Facebook, or Facebook is not configured for your Portal.</p>
						<p>Please contact your Account Manager for assistance.</p>
					</React.Fragment>
				)}
				{( (isRetailer === false) && (retailerList) && (roles.includes("portalAdm") || roles.includes("portalVlctyMngr")) ) && (
					<div className="retailer-selector">
						Retailer:
						<span className="clear-client"><i className="far fa-window-close" onClick={this.clearFilter}></i></span>
						<InputRow type="select" property="retailerList" options={retailerList} onChange={this.filterBy} />
					</div>
				)}
				{(loading === false && !message) && (
					(accounts) && (
						(accounts.length > 1) && (
							<React.Fragment>
								<h2>All Account Totals</h2>
								<section className="calls-list">
									<div className="table-heading">
										<div>Campaign Name</div>
										<div>Spend</div>
										<div>Impressions</div>
										<div>Reach</div>
										<div>Clicks</div>
										<div>Leads</div>
										<div>$/Click</div>
										<div>$/Lead</div>
									</div>
									<ul className="table-body">
										{(allData) && (
											<li>
												<div>All Campaigns</div>
												<div>{numberToPrice(allData[0])}</div>
												<div>{numberToLocal(allData[1])}</div>
												<div>{numberToLocal(allData[2])}</div>
												<div>{numberToLocal(allData[3])}</div>
												<div>{numberToLocal(allData[4])}</div>
												<div>{(allData[3] !== 0) ? (numberToPrice(allData[0]/allData[3])) : (numberToPrice(allData[0]))}</div>
												<div>{(allData[4] !== 0) ? (numberToPrice(allData[0]/allData[4])) : (numberToPrice(allData[0]))}</div>
											</li>
										)}
									</ul>
								</section>
							</React.Fragment>
						)
					)
				)}

				{(loading === false && !message) && (
					(accounts.length > 0) ? (
						accounts.map((acct, i) =>
							<React.Fragment key={i}>
								<h2 className="accountName">Facebook Account: {acct[i].profile}</h2>
								<section className="stats row">
									<Statistic
										label="Total Overall Spend (Traffic + Leads)"
										value={numberToPrice(this.getTotal(acct, "cost"))}
										hideHistorical={hideHistorical}
									/>
								</section>

                                {(this.getTotalByType(acct, "onsite_conversion.lead_grouped", "leads") > 0) &&
                                    <section className="stats row">
                                    <React.Fragment>
                                        <Statistic
                                            label="Lead Spend $$"
                                            value={numberToPrice(this.getTotalByType(acct, "cost", "leads"))}
                                            hideHistorical={hideHistorical}
                                        />
                                        <Statistic
                                            label="Total Leads"
                                            value={numberToLocal(this.getTotalByType(acct, "onsite_conversion.lead_grouped", "leads"))}
                                            hideHistorical={hideHistorical}
                                        />
                                        <Statistic
                                            label="Cost Per Lead"
                                            value={numberToPrice(this.getTotalByType(acct, "cost", "leads") / this.getTotalByType(acct, "onsite_conversion.lead_grouped", "leads"))}
                                            hideHistorical={hideHistorical}
                                        />
                                    </React.Fragment>
                                    </section>
                                }
								<section className="stats row">
									{(this.getTotalByType(acct, "action_link_click", "traffic") > 0) &&
										<React.Fragment>
                                            <Statistic
                                                label="Traffic Spend $$"
                                                value={numberToPrice(this.getTotalByType(acct, "cost", "traffic"))}
                                                hideHistorical={hideHistorical}
                                            />
											<Statistic
                                                label="Total Traffic Clicks"
                                                value={numberToLocal(this.getTotalByType(acct, "action_link_click", "traffic"))}
                                                hideHistorical={hideHistorical}
                                            />
											<Statistic
												label="Cost Per Traffic Click"
												value={numberToPrice(this.getTotalByType(acct, "cost", "traffic")/this.getTotalByType(acct, "action_link_click", "traffic"))}
												hideHistorical={hideHistorical}
											/>
										</React.Fragment>
									}
									
								</section>


								<section className="calls-list">
									<div className="table-heading">
										<div className="sortable" onClick={() => this.sortBy("campaignName", acct, i)}>Campaign Name <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("spend", acct, i)}>Spend <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("impressions", acct, i)}>Impressions <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("reach", acct, i)}>Reach <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("clicks", acct, i)}>Clicks <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("leads", acct, i)}>Leads <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("cpc", acct, i)}>Cost/Click <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("costPerLead", acct, i)}>Cost/Lead <span><i className="fas fa-sort"></i></span></div>
									</div>
									<ul className="table-body">
										{(acct) && (
											acct.map((camp, index) => 
												<React.Fragment key={index}>
													<li onClick={() => this.viewCamp(acct[i].profileID, index)} >
														<div>{camp.adcampaign_name}</div>
														<div>{numberToPrice(camp.cost)}</div>{/*spend*/}
														<div>{numberToLocal(camp.impressions)}</div>{/*impressions*/}
														<div>{numberToLocal(camp.reach)}</div>{/*reach*/}
														<div>{camp.adcampaign_name.startsWith("Lead Gen") ? "N/A" : (numberToLocal(camp.action_link_click))}</div>{/*clicks*/}
														<div>{camp.adcampaign_name.startsWith("Lead Gen") ? (numberToLocal(camp["onsite_conversion.lead_grouped"])) : "N/A"}</div>{/*leads*/}
														<div>{camp.adcampaign_name.startsWith("Lead Gen") ? "N/A" : numberToPrice(camp.cost/camp.action_link_click)}</div>{/*cpc*/}
														<div>{(parseInt(camp["onsite_conversion.lead_grouped"]) !== 0) ? (numberToPrice(camp.cost/camp["onsite_conversion.lead_grouped"])) : (numberToPrice(camp.cost))}</div>{/*cpl*/}
													</li>
													<div id={"camp-"+acct[i].profileID+"-"+index} className="email-events">
														<h3>Campaign: {camp.adcampaign_name}</h3>
														<div className="columns">
															<div>
																<h5>Spending</h5>
																<div>Total Spend: {numberToPrice(camp.cost)}</div>
																<div>Cost Per Lead: {(camp["onsite_conversion.lead_grouped"] !== 0) ? (numberToPrice(camp.cost/camp["onsite_conversion.lead_grouped"])) : "N/A"}</div>
																<div>Cost Per Link Click: {(!camp.adcampaign_name.startsWith("Lead Gen") && camp.action_link_click !== 0) ? (numberToPrice(camp.cost/camp.action_link_click)) : "N/A"}</div>
															</div>
															<div>
																<h5>Performance</h5>
																<div>Reach: {numberToLocal(camp.reach)}</div>
																<div>Impressions: {numberToLocal(camp.impressions)}</div>
																<div>Leads: {numberToLocal(camp["onsite_conversion.lead_grouped"])}</div>
															</div>
															<div>
																<h5>Engagement</h5>

																<div>Link Clicks: {!camp.adcampaign_name.startsWith("Lead Gen") ? numberToLocal(parseInt(camp.action_link_click)) : "N/A"}</div>
																<div>Reactions: {numberToLocal(parseInt(camp.action_post_like))}</div>
																<div>Comments: {numberToLocal(parseInt(camp.action_comment))}</div>
																<div>Shared Posts: {numberToLocal(parseInt(camp.action_post))}</div>
																<div>Video Views: {numberToLocal(parseInt(camp.unique_action_video_play))}</div>
															</div>
														</div>
														<h3>Ad Preview</h3>
														<div className="columns">
															<div>
																<div><h5>{camp.creative_title}</h5></div>
																<div>{camp.creative_body}</div>
															</div>
															<div>
																<iframe 
																	src={camp.mobile_feed_preview_url}
																	style={{width: "350px", height: "600px"}}
																	title={camp.creative_title}
																></iframe>
															</div>
														</div>
														
													</div>
												</React.Fragment>
											)
										)}
									</ul>
								</section>
							</React.Fragment>
						)
					) : (
						<React.Fragment>
							<h2 className="accountName">Facebook Account: {name}</h2>
							<section className="stats row">
								<Statistic
									label="Total Impressions"
									value={numberToLocal("0")}
									hideHistorical={hideHistorical}
								/>
								<Statistic
									label="Total Spend"
									value={numberToPrice("0")}
									hideHistorical={hideHistorical}
								/>
								<Statistic
									label="Total Leads"
									value={numberToLocal("0")}
									hideHistorical={hideHistorical}
								/>
								<Statistic
									label="Lead Gen - Cost Per Lead"
									value={numberToPrice("0")}
									hideHistorical={hideHistorical}
								/>
							</section>

							<p>No activity is reported by Facebook for this account during this time period.</p>
							<p>Please contact your Account Manager for assistance if this is incorrect.</p>
						</React.Fragment>
					)
				)}
			</div>
		)
	}

}

export default SocialFacebook;