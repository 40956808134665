import { basicGET, basicJSONPOST } from "../../api/fetchHelpers";

export async function getAccountId(endpoints, clientCode, type, token) {
    const response = await fetch(endpoints.serviceClients + clientCode + "/credential/type/" + type, basicGET(token));
    if (!response.ok) {
        const message = 'An error has occured: ' + response.status;
        throw new Error(message);
    }
    const data = await response.json();
    return data;
}


// -----------------------------------------------------------------------------------------------------------------------
// --------------------ANALYTICS
// -----------------------------------------------------------------------------------------------------------------------
export async function loadAnalytics(fields, filter, order, rows, compare, endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics", token);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GAWA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "compare_type": compare ? "custom" : null,
            "compare_start_date": compare ? dates[2] : null,
            "compare_end_date": compare ? dates[3] : null,
            "fields": fields,
            "settings": {
                "blanks_to_zero": true,
            },
            "filter": filter,
            "order_rows": order,
            "max_rows": rows
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}
export async function loadAnalyticsQuickStats(endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics", token);
    // console.log(account);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "compare_type": "custom",
            "compare_start_date": dates[2],
            "compare_end_date": dates[3],
            "fields": [
                "sessions",
                "users",
                "Pageviews",
                "average_session_length_seconds",
                "EntranceBounceRate_calc"
            ],
            "max_rows": 1000
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}
export async function loadAnalyticsQuickStatsGA4(endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics4", token);
    // console.log(account);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GAWA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "compare_type": "custom",
            "compare_start_date": dates[2],
            "compare_end_date": dates[3],
            "fields": [
                "sessions",
                "totalUsers",
                "screenPageViews",
                "bounceRate",
                "averageSessionDuration",
                "avgUserEngagementDuration",
            ],
            "max_rows": 1000
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        // console.log('quick stat-', data);
        return data;
    }
}
export async function loadAnalyticsDailyMetrics(endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics", token);
    //console.log(account);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "fields": [
                "Date",
                "users",
                "sessions",
                "Pageviews",
                "NewVisits_noncalc",
                "average_session_length_seconds",
                "EntranceBounceRate_calc",
                "goalCompletionsAll"
            ],
            "max_rows": 1000
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}
export async function loadAnalyticsDailyMetricsGA4(endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics4", token);
    //console.log(account);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GAWA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "fields": [
                "Date",               
                "sessions",
                "totalUsers",
                "newUsers",
                "screenPageViews",
                "bounceRate",
                "averageSessionDuration",
                "conversions",

            ],
            "max_rows": 1000
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}

export async function loadDimension(endpoints, dates, dimension, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics", token);
    //console.log(account);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "fields": [
                dimension,
                "Sessions"
            ],
            "max_rows": 10
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}

export async function loadDimensionGA4(endpoints, dates, dimension, clientCode, token, filter=null) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics4", token);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GAWA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "fields": [
                dimension,
                "sessions"
            ],
            "settings": {"blanks_to_zero":true},
            // "order_rows": "sessions+desc",
            "max_rows": 10
        }
        if(filter) {
            body['filter'] = filter;
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        // console.log(dimension, data);
        return data;
    }
}

// -----------------------------------------------------------------------------------------------------------------------
// --------------------Google Adwords
// -----------------------------------------------------------------------------------------------------------------------
export async function loadAdwords(fields, filter, order, rows, compare, endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAdwords", token);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "AW",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "compare_type": compare ? "custom" : null,
            "compare_start_date": compare ? dates[2] : null,
            "compare_end_date": compare ? dates[3] : null,
            "fields": fields,
            "settings": {
                "blanks_to_zero": true,
            },
            "filter": filter,
            "order_rows": order,
            "max_rows": rows
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}

// -----------------------------------------------------------------------------------------------------------------------
// --------------------Google Adwords by channel type
// -----------------------------------------------------------------------------------------------------------------------
export async function loadAdwordsChannelType(fields, filter, order, rows, endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAdwords", token);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "AW",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0] ? dates[0] : null,
            "end_date": dates[1] ? dates[1] : null,
            "fields": fields,
            "settings": {
                "blanks_to_zero": true,
            },
            "filter": filter,
            "order_rows": order,
            "max_rows": rows
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}

// -----------------------------------------------------------------------------------------------------------------------
// --------------------ROOMVO
// -----------------------------------------------------------------------------------------------------------------------
export async function loadRoomvoQuickStats(endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics4", token);
    //console.log(account);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GAWA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "compare_type": "custom",
            "compare_start_date": dates[2],
            "compare_end_date": dates[3],
            "filter": "eventName =~ product view|upload room|view details|Download image|Share room",
            "fields": [
                "eventName",
                "eventCount"
            ],
            "settings": {
                "blanks_to_zero": true,
            },
            "max_rows": 10
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}
export async function loadRoomvoDailyViews(endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics4", token);
    //console.log(account);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GAWA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "filter": "eventName == product view",
            "fields": [
                "date",
                "TotalEvents"
            ],
            "settings": {
                "blanks_to_zero": true,
            },
            "max_rows": 500

        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}
export async function loadRoomvoTopEvent(endpoints, dates, clientCode, label, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics", token);
    //console.log(account);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "filter": "EventCategory == Roomvo AND EventAction == " + label,
            "fields": [
                "EventLabel",
                "TotalEvents"
            ],
            "settings": {
                "blanks_to_zero": true,
            },
            "max_rows": 10

        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}

// Roomvo Top Events GA4
export async function loadRoomvoTopEventGA4(endpoints, dates, clientCode, label, token) {
    var account = await getAccountId(endpoints, clientCode, "googleAnalytics4", token);
    //console.log(account);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GAWA",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "filter": "eventName == " + label,
            "fields": [
                "c_customevent_event_label",
                "eventCount"
            ],
            "settings": {
                "blanks_to_zero": true,
            },
            "max_rows": 10

        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }
}

// -----------------------------------------------------------------------------------------------------------------------
// --------------------Google My Business
// -----------------------------------------------------------------------------------------------------------------------
export async function loadGoogleMyBusiness(fields, rows, order, settings, endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "googleMyBusiness", token);
    if (account.success === false) {
        return account;
    } else {
        let body = {
            "ds_id": "GMB",
            "ds_accounts": [
                "accounts/" + account.result[0].accountId
            ],
            "ds_user": "gmb@mobile-marketing.agency",
            "start_date": dates[0],
            "end_date": dates[1],
            "fields": fields,
            "order_rows": order,
            "settings": settings,
            "max_rows": rows
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();

        return data;
    }
}

// -----------------------------------------------------------------------------------------------------------------------
// --------------------Google Search Console
// -----------------------------------------------------------------------------------------------------------------------
export async function loadSearchConsole(fields, rows, order, compare, filter, settings, endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "searchConsole", token);
    if (account.success === false) {
        return { success: false }
    } else {
        let body = {
            "ds_id": "GW",
            "ds_accounts": [
                account.result[0].accountId
            ],
            "ds_user": "seo@flooringmobilemarketing.com",
            "start_date": dates[0],
            "end_date": dates[1],
            "compare_type": compare,
            "compare_start_date": dates[2],
            "compare_end_date": dates[3],
            "fields": fields,
            "order_rows": order,
            "filter": filter,
            "settings": settings,
            "max_rows": rows
        }
        const response = await fetch(endpoints.serviceMetrics + "get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: ' + response.status;
            throw new Error(message);
        }
        const data = await response.json();

        return data;
    }
}