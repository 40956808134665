import React from 'react';

import { formatDateTimeLocal } from '../../../helpers/uiFunctions';
import { InputRow } from '../../../helpers/inputRow';
import { emailCall } from '../../data/callsService';

class CallWindow extends React.Component {
    send(id) {
        var email = document.getElementById("sendEmail-"+id);
		var eAdress = email.value;
        setTimeout(function() {
            email.value = 'Sending Recording....';
            email.classList.add('disabled');
        }, 100);
        emailCall(id, eAdress, this.props.endpoints.serviceCalls)
        .then(response => {
			console.log(response);
            if (response.success) {
                setTimeout(function() {
                    email.value = 'Email Sent....';
                    email.classList.remove('disabled');
                    email.classList.add('success');
                }, 1000);
            } else {
                setTimeout(function() {
                    email.value = 'Email Failed....';
                    email.classList.remove('disabled');
                    email.classList.add('error');
                    
                }, 1000);
            }
            setTimeout(function() {
                email.value = '';
                email.classList.remove('success');
                email.classList.remove('error');
            }, 5000);
		})
	}

    render() {
        const call = this.props.call;
        const save = this.props.save;
        return(
            <div className={(call["conversionStatus"] === null) ? ( "detail-window New" ) : ( "detail-window "+call["conversionStatus"] )}>
                <div className="form">
                    <section>
                        <h3>{(call['name']) ? (call['name']) : ("No Customer Name Set")}</h3>
                        <p>Caller Phone: {call['Caller Id']}</p>
                    </section>
                    <section>
                        <h5>Play/Share Recording</h5>
                        <audio controls="controls">
                            <source src={"//"+call['Call Recording URL']} type="audio/mpeg" />
                            Your browser does not support the audio tag.
                        </audio>
                        <InputRow label="Share To Email" id={"sendEmail-"+call['Call ID']} type="text" property="recipientEmail" />
                        <div className={"detail-actions"}>
                            <button className="white" onClick={() => this.send(call['Call ID'])}>Share</button>
                        </div>
                    </section>
                    <section>
                        <h5>Update Lead</h5>
                        <InputRow
                            label="Lead Status"
                            type="select"
                            property="conversionStatus"
                            id={"status-" + call['Call ID']}
                            options={[
                                {"name": "Qualified","value": "Qualified"},
                                {"name": "Disqualified","value": "Disqualified"},
                                {"name": "Opportunity","value": "Opportunity"},
                                {"name": "Won","value": "Won"},
                                {"name": "Lost","value": "Lost"}
                            ]}
                            value={call["conversionStatus"]}
                        />
                        <InputRow 
                            label={"Add Note"}
                            type="textarea"
                            placeholder="Status Notes"
                            property="conversionDetail"
                            id={"detail-" + call['Call ID']}
                        />
                        <InputRow
                            label={"Customer Name"}
                            type="text"
                            placeholder="Customer Name"
                            property="name"
                            id={"name-" + call['Call ID']}
                            value={call['name']}
                        />
                        <InputRow
                            label={"Add Lead Value"}
                            type="number"
                            placeholder="Lead Value"
                            property="value"
                            step="0.01"
                            id={"value-" + call['Call ID']}
                            value={call["value"]}
                        />
                        <div className={"detail-actions"}>
                            <button className="white" onClick={() => save(call['Call ID'])}>Update</button>
                        </div>
                    </section>

                    <h5>Lead History</h5>
                    <div className="table-heading">
                        <div>Date</div>
                        <div>Status</div>
                        <div>Notes</div>
                    </div>
                    <ul className="detail-history">
                        {call.workflow.sort((a, b) => (a["created"] < b["created"]) ? 1 : -1).map((event, index) => 
                            <li key={index}>
                                <div>{formatDateTimeLocal(event.created)[0]}<br/>{formatDateTimeLocal(event.created)[1]}</div>
                                <div>{event.status}</div>
                                <div>{event.detail}</div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}
export default CallWindow;