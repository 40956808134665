import React from 'react';
import { Chart } from 'chart.js';
import { Breakdown, ReportHeading, Statistic } from '../../helpers/uiComponents';
import { getNumbersAsPercents, numberToLocal, numberToPercent, secondsToTime } from '../../helpers/uiFunctions';
import { loadAnalytics } from '../data/supermetricsService';
import { chartColors } from '../../helpers/uiHelpers';

class Blogs extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
			loading: true,
			errorMessage: null,
			quickStats: null,
			metrics: [],
			topBlogLabels: ["Page path","Sessions","New users","Entrance bounce rate","Avg. time on page"],
			topBlogs: null,
			exitData: null,
			articleExits: null,
			sortVal: 0,
        };
    }

    componentDidMount() {
		loadAnalytics( //blog quickstats
            ["Sessions","NewVisits_noncalc","Pageviews","AvgTimeOnPage_calc"],
			// eslint-disable-next-line
            "PagePath =~ .*\/[0-9]*\/[0-9]*\/[0-9]*\/[a-z0-9].*|.*\/blog\/[a-z0-9].* AND PagePath !~ \\?|category",
            null,
            null,
            !this.state.hideHistorical,
            this.props.endpoints,
			this.props.dates,
			this.props.currentClient.clientCode,
			localStorage.getItem('accessToken')
        ).then(response => {
			var arr = [];
            if (response.success) {
                var keys = response.result.data[0];
                var values = response.result.data[1];
                keys.forEach((key, i) => arr[key] = values[i]);
                this.setState({
					quickStats: arr,
					loading: false,
				});
            } else {
                this.setState({
					quickStats: null,
					errorMessage: response.message,
				});
            }
		})
		loadAnalytics( // load GA metrics for blog
            ["Date","Sessions","users","NewVisits_noncalc","Pageviews","EntranceBounceRate_calc","AvgTimeOnPage_calc"],
			// eslint-disable-next-line
            "PagePath =~ .*\/[0-9]*\/[0-9]*\/[0-9]*\/[a-z0-9].*|.*\/blog\/[a-z0-9].* AND PagePath !~ \\?|category",
            null,
            null,
            false,
            this.props.endpoints,
			this.props.dates,
			this.props.currentClient.clientCode,
			localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success) {
                var metrics = response.result.data;
				for (let index = 0; index < metrics.length; index++) {
					metrics[index][5] = metrics[index][5]*100
				}
				this.setState({
					metrics: metrics
				});
				this.buildDailyChart("Sessions", metrics, 1, "sessions-graph"); /*title, inputData, index, elemId*/
				this.buildDailyChart("Users", metrics, 2, "users-graph"); /*title, inputData, index, elemId*/
				this.buildDailyChart("New Users", metrics, 3, "newusers-graph"); /*title, inputData, index, elemId*/
				this.buildDailyChart("Page Views", metrics, 4, "pageviews-graph"); /*title, inputData, index, elemId*/
				this.buildDailyChart("Entrance Bounce Rate (%)", metrics, 5, "bouncerate-graph"); /*title, inputData, index, elemId*/
				this.buildDailyChart("Average Time On Page (seconds)", metrics, 6, "sessionlength-graph"); /*title, inputData, index, elemId*/
            } else {
                this.setState({
					metrics: null,
					errorMessage: response.message,
				});
            }
        });
		loadAnalytics( // blog top 10 articles
            ["PagePath","Sessions","NewVisits_noncalc","EntranceBounceRate_calc","AvgTimeOnPage_calc"],
			// eslint-disable-next-line
            "PagePath =~ .*\/[0-9]*\/[0-9]*\/[0-9]*\/[a-z0-9].*|.*\/blog\/[a-z0-9].* AND PagePath !~ \\?|category",
            ["Sessions desc"],
            10,
            false,
            this.props.endpoints,
			this.props.dates,
			this.props.currentClient.clientCode,
			localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success) {
                // console.log(response)
				response.result.data.shift();
				var metrics = response.result.data;
				for (let index = 0; index < metrics.length; index++) {
					metrics[index][3] = metrics[index][3]*100
				}
                this.setState({
					topBlogs: metrics
				});
            } else {
                this.setState({
					topBlogs: null,
					errorMessage: response.message,
				});
            }
        });
		loadAnalytics( // blog top 50 exit actions
			["EventLabel","TotalEvents"],
			"EventCategory == Blog Analytics AND EventAction == Exited Blog Section",
			null,
			50,
			false,
			this.props.endpoints,
			this.props.dates,
			this.props.currentClient.clientCode,
			localStorage.getItem('accessToken')
		).then(response => {
			//console.log(response);
			if (response.success) {
				response.result.data.shift();
				if (response.result.data.length > 0) {
					var data = getNumbersAsPercents(response.result.data, 1)
					this.setState({
						exitData: data,
						errorMessage: response.message,
					});
					setTimeout(() => {
						this.buildPieChart(data, "Top Exit Destinations", "exit-graph"); /*inputData, title, elemId*/
					}, 500);
				} else {
					this.setState({exitData: [["No Results Found",0]]});
				}
			} else {
                this.setState({
					exitData: null,
					errorMessage: response.message,
				});
            }
		})
	}
	loadArticleStats(urlPath, i) { // load individual article stats onClick
		console.log("fetching for " + urlPath);
		this.setState({articleExits: null});
		this.viewCamp(i);
		loadAnalytics(
			["EventLabel","TotalEvents"],
			"EventCategory == Blog Analytics AND EventAction == Link Clicked AND PagePath == "+urlPath,
			null,
			10,
			false,
			this.props.endpoints,
			this.props.dates,
			this.props.currentClient.clientCode,
			localStorage.getItem('accessToken')
		).then(response => {
			console.log(response);
			if (response.success) {
				// load row data for each article
				response.result.data.shift();
				var exits = getNumbersAsPercents(response.result.data, 1);
				if (exits.length > 0) {
					this.setState({articleExits: exits});
				} else {
					this.setState({articleExits: [["No Results Found",0]]});
				}
			}
		});
	}
	buildDailyChart(title, inputData, index, elemId) {
		if (inputData) {
			var data = [];
			var options = [...new Set(inputData.map(x => x[0]))];;			
            for (var i = inputData.length - 1; i >= 0; i--) {
				data.push(inputData[i][index]);
			}
			data.reverse();
			var ctx = document.getElementById(elemId);
			new Chart(ctx, {
				"type": "line",
				"data": {
					"labels":options,
					"datasets":[
						{
							"data":data,
							"backgroundColor": "rgba(72, 152, 187, 0.3)",
							"borderColor": "rgba(72, 152, 187, 1)",
							"pointBackgroundColor": "rgba(190, 209, 47, 1)",
							"pointRadius": 5,
						}
					]
				},
				options: {
					aspectRatio: 1,
					maintainAspectRatio: false,
					legend: {display: false,},
					title: {
						display: true,
						text: title,
						fontSize: 16,
					},
					layout: {
			            padding: {left: 50, right: 50, top: 25, bottom: 25}
			        },
			        scales: {
			        	xAxes: [{ stacked: true }],
			        	yAxes: [{ stacked: true }]
			        },
				}
			});
		}
	}
	buildPieChart(inputData, title, elemId) {
		var data = [];
		var options = [];
		for (let i = 0; i < inputData.length; i++) {
			options.push(inputData[i][0]);
			data.push(inputData[i][1]);
		}
		var ctx = document.getElementById(elemId);
		new Chart(ctx, {
			"type": "doughnut",
			"data": {
				"labels": options,
				"datasets":[{
					"data":data,
					"backgroundColor": chartColors,
				}]
			},
			options: {
				maintainAspectRatio: false,
				aspectRatio: 1,
				legend: {display: false,position: "bottom",},
				title: {
					display: true,
					text: title,
					fontSize: 16,
				},
				layout: {
					padding: {left: 50, right: 50, top: 25, bottom: 25}
				},
			}
		});
	}
	toggle(metric) { // toggles daily charts between the top metrics 
		var button = document.querySelector('.breakdown button.'+metric);
		var buttons = document.querySelectorAll('.breakdown button');
		var graph = document.querySelector('.graph-container.'+metric);
		var graphs = document.querySelectorAll('.graph.analytics .graph-container');
		for (var i = buttons.length - 1; i >= 0; i--) {
			buttons[i].classList.remove('active');
			graphs[i].classList.remove('active');
		}
		button.classList.add('active');
		graph.classList.add('active');
	}
	viewCamp(x) {
		var elem = document.getElementById("blog-"+x)

		if (!elem.classList.contains('active')) {
			var buttons = document.querySelectorAll('.email-events');
			for (var i = buttons.length - 1; i >= 0; i--) {
				buttons[i].classList.remove('active');
			}
			
			elem.classList.toggle("active");
		} else {
			elem.classList.toggle("active");
		}
    }
	sortBy(i) {
		var list = this.state.topBlogs;
		var sort = this.state.sortVal;
		if (sort === 0 || sort === 2) {
			list = list.sort((a, b) => (a[i] > b[i]) ? 1 : -1);
			sort = 1;
		} else {
			list = list.sort((a, b) => (a[i] < b[i]) ? 1 : -1);
			sort = 2;
		}
		this.setState({
			topBlogs: list,
			sortVal: sort,  // up or down
		});
	}
	

    render() {
		const {loading, errorMessage, hideHistorical, quickStats, topBlogs, topBlogLabels, exitData, articleExits } = this.state;
		let prodSite = this.props.currentClient.sites.find(x => x.environment === 'prod');
		return (
			(this.props.currentClient) && (
				<div id="content-blogs" className="report content-blogs">
					<ReportHeading
						title="Blog Analytics"
						name={this.props.currentClient.name}
						dates={this.props.dates}
						hideHistorical={hideHistorical}
					/>
					{errorMessage ? ( <p>{errorMessage}</p> ) : (
						(loading === true) && (
	            			<div className="report-loading">
	            				<p><i className="fas fa-circle-notch"></i></p>
	            			</div>
	            		),
	            		<React.Fragment>
							<section className="stats row">
								<Statistic
									label="Sessions"
									value={(quickStats) ? (numberToLocal(quickStats['Sessions'])) : ("calculating..")}
									status={(quickStats && (quickStats['Change % Sessions'] < 0)) ? ("down") : ("up")}
									percent={(quickStats) ? (quickStats['Change % Sessions']) : (0)}
									hideHistorical={hideHistorical}
								/>
								<Statistic
									label="New Users"
									value={(quickStats) ? (numberToLocal(quickStats['New users'])) : ("calculating..")}
									status={(quickStats && (quickStats['Change % New users'] < 0)) ? ("down") : ("up")}
									percent={(quickStats) ? (quickStats['Change % New users']) : (0)}
									hideHistorical={hideHistorical}
								/>
								<Statistic
									label="Page Views"
									value={(quickStats) ? (numberToLocal(quickStats['Pageviews'])) : ("calculating..")}
									status={(quickStats && (quickStats['Change % Pageviews'] < 0)) ? ("down") : ("up")}
									percent={(quickStats) ? (quickStats['Change % Pageviews']) : (0)}
									hideHistorical={hideHistorical}
								/>
								<Statistic
									label="Average Time On Page"
									value={(quickStats) ? (secondsToTime(quickStats['Avg. time on page'])) : ("calculating..")}
									status={(quickStats && (quickStats['Avg. time on page'] < 0)) ? ("down") : ("up")}
									percent={(quickStats) ? (quickStats['Change % Avg. time on page']) : (0)}
									hideHistorical={hideHistorical}
								/>
							</section>


							<section className="stats-graph">
								<div className="graph wide analytics">
									<div className="graph-container sessions active">
										<canvas className="chart" id="sessions-graph" />
									</div>
									<div className="graph-container users">
										<canvas className="chart" id="users-graph" />
									</div>
									<div className="graph-container newusers">
										<canvas className="chart" id="newusers-graph" />
									</div>
									<div className="graph-container pageviews">
										<canvas className="chart" id="pageviews-graph" />
									</div>
									<div className="graph-container bouncerate">
										<canvas className="chart" id="bouncerate-graph" />
									</div>
									<div className="graph-container sessionlength">
										<canvas className="chart" id="sessionlength-graph" />
									</div>
								</div>
								<div className="breakdown">
									<p className="title">Metrics</p>
									<div className="graph-controls">
										<button className="wide white sessions active" onClick={() => this.toggle("sessions")}>Sessions</button>
										<button className="wide white users" onClick={() => this.toggle("users")}>Users</button>
										<button className="wide white newusers" onClick={() => this.toggle("newusers")}>New Users</button>
										<button className="wide white pageviews" onClick={() => this.toggle("pageviews")}>Page Views</button>
										<button className="wide white bouncerate" onClick={() => this.toggle("bouncerate")}>Entrance Bounce Rate</button>
										<button className="wide white sessionlength" onClick={() => this.toggle("sessionlength")}>Average Time On Page</button>
									</div>
								</div>
							</section>

							<h2>Top 10 Blogs</h2>
							{(topBlogs) ? (
								<section className="calls-list">
									<div className="table-heading">
										{topBlogLabels.map((label,index) =>
											<div key={index} onClick={() => this.sortBy(index)}>{label} <span><i className="fas fa-sort"></i></span></div>
										)}
									</div>
									<ul className="table-body">
										{topBlogs.map((blog, index) => 
											<React.Fragment key={index}>
												<li key={index} onClick={() => this.loadArticleStats(blog[0], index)}>
													{blog.map((dataPoint, i) =>
														<div key={i}>
															{([0].indexOf(i) !== -1) 	&& dataPoint}
															{([1,2].indexOf(i) !== -1) 	&& numberToLocal(dataPoint)}
															{([3].indexOf(i) !== -1) 	&& numberToPercent(dataPoint)}
															{([4].indexOf(i) !== -1) 	&& secondsToTime(dataPoint)}
														</div>
													)}
												</li>
												<div id={"blog-"+index} className="email-events">
													<div className="columns">
														<div>
															<h5>Article</h5>
															<a href={prodSite.url.endsWith('/') ? prodSite.url.slice(0, -1)+blog[0] : prodSite.url+blog[0]} target="_blank" rel="noopener noreferrer" className="link">
																{prodSite.url.endsWith('/') ? prodSite.url.slice(0, -1)+blog[0] : prodSite.url+blog[0]}
															</a>
														</div>
													</div>
													<div className="columns">
														<div>
															<h5>Performance</h5>
															{blog.map((dataPoint, i) =>
																<div key={i}>
																	{([1].indexOf(i) !== -1) 	&& "Sessions: "+numberToLocal(dataPoint)}
																	{([2].indexOf(i) !== -1) 	&& "New Users: "+numberToLocal(dataPoint)}
																	{([3].indexOf(i) !== -1) 	&& "Entrance Bounce Rate: "+numberToPercent(dataPoint)}
																	{([4].indexOf(i) !== -1) 	&& "Avg Time on Page: "+secondsToTime(dataPoint)}
																</div>
															)}
														</div>
													</div>
													<div className="columns">
														<div>
															<h5>Top Links Clicked From This Article</h5>
															{(articleExits) ? (
																articleExits.map((link, index) => 
																	<div key={index}>																		 
																		{(link[0] !== "No Results Found") ? (
																			<span>{link[1]} Clicks ({link[2]}%) : <a href={link[0]} target="_blank" rel='noopener noreferrer'>{link[0]}</a></span>
																		) : (
																			link[0]
																		)}
																	</div>
																)
															) : (
																"Loading..."
															)}
														</div>
													</div>
												</div>
											</React.Fragment>
										)}
									</ul>
								</section>
							) : (
								<p>Loading...</p>
							)}

							<h2>Top Places Visited After Leaving The Blog Section</h2>
							{(exitData) ? (
								<section className="stats-graph">									
									<div className="graph">
										<div className="graph-container">
											<canvas className="chart" id="exit-graph" />
										</div>
									</div>
									<Breakdown
										data={exitData}
										title={"Top Exit Destinations"}
										format={"wide"}
										dataIndex={2}
										dataType={"percent"} />
								</section>
							) : (
								<p>Loading...</p>
							)}
						</React.Fragment>
					)}
				</div>
			)
		)

	}

}
export default Blogs;