import React from 'react';

export class Payment extends React.Component {
	render() {
		return (
			<ul>
				<li>Payments can be made by check, credit card, or wire transfer.</li>
				<li>Auto-pay options include credit card on file and ACH (wire transfer) on file.  Clients must fill out either the Credit Card Authorization form or the ACH Wire Transfer Authorization form.  Once received, Mobile Marketing will pay all prior invoices at one time (unless prior arrangements are worked out).  Future invoices will be paid on the day they are created.</li>
			</ul>
		)
	}

}










