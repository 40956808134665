import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { getEmails } from '../../../components/data/data';
import { Statistic } from '../../../helpers/uiComponents';
import { getPercentChange, numberToLocal } from '../../../helpers/uiFunctions';
import { errorMessage } from '../../../helpers/uiHelpers';

class EmailQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        }
    }
    componentDidMount() {
        getEmails(
            this.props.endpoints.serviceCrm,
            this.props.currentClient.clientCode,
            this.props.dates,
            localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success) {
                this.setState({data: response.result});
            } else {
                this.setState({data: errorMessage});
            }
        });
    }
    render() {
        const { data } = this.state;
        return(
            <React.Fragment>
                {(this.props.onClick) && (
                    <Router>
                        <Link to="metrics-emails" className="button white learn-more" onClick={() => this.props.onClick("metrics-emails")}>
                            <i className="fas fa-chart-line"></i>
                        </Link>
                    </Router>
                )}
                <h3>PROMOTIONAL EMAILS SENT</h3>
                <section className="stats row">
                    <Statistic
                        label="Total Sends"
                        value={(data) ? numberToLocal(data[0].delivered) : ("calculating...")}
                        status={(data) && getPercentChange(data[0].delivered, data[2].delivered)[1]}
                        percent={(data) && getPercentChange(data[0].delivered, data[2].delivered)[0]}
                        hideHistorical={this.props.hideHistorical}
                    />
                    <Statistic
                        label="Total Opens"
                        value={(data) ? numberToLocal(data[0].opened) : ("calculating...")}
                        status={(data) && getPercentChange(data[0].opened, data[2].opened)[1]}
                        percent={(data) && getPercentChange(data[0].opened, data[2].opened)[0]}
                        hideHistorical={this.props.hideHistorical}
                    />
                    <Statistic
                        label="Active Promotions"
                        value={(data) ? numberToLocal(data[0].promos.length) : ("calculating...")}
                        status={(data) && getPercentChange(data[0].promos.length, data[2].promos.length)[1]}
                        percent={(data) && getPercentChange(data[0].promos.length, data[2].promos.length)[0]}
                        hideHistorical={this.props.hideHistorical}
                    />
                </section>
            </React.Fragment>
        )
    }
}
export default EmailQuickStat;