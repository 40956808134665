import React from 'react';
import { ServiceAgreement } from './ServiceAgreement';
import { Separation } from './Separation';
import { Term } from './Term';
import { Payment } from './Payment';
import { Invoicing } from './Invoicing';
import { ClientSupport } from './ClientSupport';


class ProgramDetails extends React.Component {
	toggleTabs(i) {
		// console.log(i);
		var menuItems = document.querySelectorAll('#program .tab-menu .tab');
		var bodyItems = document.querySelectorAll('#program .tab-body .tab');

		for (var x = menuItems.length - 1; x >= 0; x--) {
			menuItems[x].classList.remove('active');
			menuItems[i-1].classList.add('active');
			bodyItems[x].classList.remove('active');
			bodyItems[i-1].classList.add('active');
		}
	}
	render() {
		const currentClient = this.props.currentClient;
		const roles = this.props.roles;
		let prodSite = currentClient.sites.find(x => x.environment === 'prod');
		return(
			(this.props.currentClient) && (
				<div id="program" className="report program">
					<section className="heading">
						<h1>Velocity Program Details</h1>
						<h5>{this.props.currentClient.name}</h5>
					</section>
					<section className="stats row">
						<div>
							<div className={"service-heading prime "+currentClient.servicePackage}>Velocity {(currentClient.servicePackage !== "Select...") && ( currentClient.servicePackage )}</div>
							<div className="service-subheading">
								<h2>{(currentClient.servicePackage === "lite") ? ("50") : ("100")}% CO-OP*</h2>
								<p>A SFN-Exclusive, Localized Leads Program at the Speed of Digital</p>
								<small>
									{(currentClient.servicePackage === "platinum") ? 
										("Including: Lead-Generation Site Plus Additional Features & Complete Service") :
										("Including: Lead-Generation Site Plus Additional Features")
									}
								</small>
							</div>
							<div className="details-wrapper">
								<div className="service-details">
									{(currentClient.servicePackage !== "Select...") && (
										<React.Fragment>
											<h3 className="blue">PROGRAM SERVICES</h3>
											
											<div className="service-grouping">
												<h5>Shaw Catalogs</h5>
												{(currentClient.servicePackage === "lite") && ( <small>All Shaw Brands, Fully Integrated and Updated By Your Price List</small> )}
												{(currentClient.servicePackage === "premium") && ( <small>+2 Manufacturers</small> )}
												{(currentClient.servicePackage === "platinum") && ( <small>+4 Manufacturers</small> )}
											</div>

											<div className="service-grouping">
												<h5>Beuatifully Designed Website</h5>
												{(currentClient.servicePackage === "lite") && ( <small>New SFN Velocity Design</small> )}
											</div>

											<div className="service-grouping">
												<h5>Shaw Flooring Network Promotions</h5>
												<small>
													{(currentClient.servicePackage === "lite") && ( <small>Shaw Spring & Fall Promotions</small> )}
													{(currentClient.servicePackage !== "lite") && ( <small>+Year-Round Promotions</small> )}
													{(currentClient.servicePackage === "premium") && ( <small> +2 Custom</small> )}
													{(currentClient.servicePackage === "platinum") && ( <small> +4 Custom</small> )}
												</small>
											</div>

											{(currentClient.servicePackage !== "lite") && (
												<div className="service-grouping">
													<h5>Email - Coupon Response</h5>
													{(currentClient.servicePackage === "premium") && ( <small>+3 Email Campaigns</small> )}
													{(currentClient.servicePackage === "platinum") && ( <small>+5 Email Campaigns</small> )}
													<h5>Social Links</h5>
													{(currentClient.servicePackage !== "prime") && ( <small>+ Embedded Pinterest</small> )}
													<h5>Analytics</h5>
													{(currentClient.servicePackage !== "prime") && ( <small>+ Insights</small> )}
												</div>
											)}

											<div className="service-grouping">
												<h5>Velocity Lead Management</h5>
												{(currentClient.servicePackage === "lite") && ( <small>Via ShawNow</small> )}
												{(currentClient.servicePackage === "premium") && ( <small> + Advanced +2 Seats</small> )}
												{(currentClient.servicePackage === "platinum") && ( <small> + Advanced +4 Seats</small> )}
											</div>

											<div className="service-grouping">
												<h5>
													{(currentClient.servicePackage === "prime") && ( "4 " )}
													{(currentClient.servicePackage === "premium") && ( "6 " )}
													{(currentClient.servicePackage === "platinum") && ( "Unlimited " )}
													Lead Generation Actions
												</h5>
												<ul>
													<li>Contact Form</li>
													{(currentClient.servicePackage !== "lite") && ( <li>Year-Round Coupon</li> )}
													<li>Apply for Financing</li>
													<li>Schedule an Appointment</li>
													{(currentClient.servicePackage === "premium") && (
														<React.Fragment>
														<li>Shop at Home</li>
														<li>In-Home Estimate</li>
														</React.Fragment>
													)}
													{(currentClient.servicePackage === "platinum") && (
														<li>Ability to Custom Your Own Call to Action</li>
													)}
												</ul>
											</div>
											<div className="service-grouping">
												{(currentClient.servicePackage !== "lite") && (
													<React.Fragment>
														<h5>Ratings & Reviews</h5>
														<small>
															+ Monitor
															{(currentClient.servicePackage === "premium") && ( " + Gather" )}
															{(currentClient.servicePackage === "platinum") && ( " + Gather + Respond" )}
														</small>
													</React.Fragment>
												)}
												<h5>SEO</h5>
												{(currentClient.servicePackage !== "lite") && (
													<small>
														+ {(currentClient.servicePackage === "prime") && ( "125" )}
														{(currentClient.servicePackage === "premium") && ( "135" )}
														{(currentClient.servicePackage === "platinum") && ( "150" )} Keywords
													</small>
												)}
												<h5>Phone Leads</h5>
												<small>+ Recording {(currentClient.servicePackage === "platinum") && ( "+ Scoring" )}</small>
												
												{(currentClient.servicePackage !== "lite") && (
													<React.Fragment>
														<h5>Rug Shop</h5>
														{(currentClient.servicePackage === "platinum") && (
															<React.Fragment>
															<h5>Sample Order Fulfillment</h5>
															<h5>Online Chat</h5>
															</React.Fragment>
														)}
													</React.Fragment>
												)}
											</div>

											<div className="service-grouping purple">
												<h3 className="purple">SOCIAL</h3>
												{(currentClient.servicePackage === "lite") ? (
													<h5>Social Links</h5>
												) : (
													<React.Fragment>
														<h5>Promoboxx Social Content</h5>
														<h5>Social Content for Spring and Fall Sales</h5>
														<small>+ Year-Round Promotions</small>
														
														<h5>Blog Posts</h5>
														<small>+{(currentClient.servicePackage === "prime") && ( "1" )}
															{(currentClient.servicePackage === "premium") && ( "2" )}
															{(currentClient.servicePackage === "platinum") && ( "3" )} Per Month
														</small>
													</React.Fragment>
												)}
											</div>
											{(currentClient.servicePackage !== "lite") && (
												<div className="service-grouping orange">
													<h3 className="orange">MORE</h3>
													<h5>Inclusion in FlooringYouWell.com</h5>
													<small>+ Enhanced Landing Page + Website Link</small>
													<h5>Store Mobile Business Card</h5>
												</div>
											)}
										</React.Fragment>
									)}

								</div>
								<div className="account-details">
									<h3 className="green">ACCOUNT DETAILS</h3>
									{currentClient.logo && (
										<img src={"https://"+currentClient.logo} alt={currentClient.name} />
									)}
									<h5>Website</h5>
									<small><a href={prodSite.url} target="_blank" rel="noopener noreferrer" className="link" >{prodSite.url}</a></small>
									<h5>Account Manager</h5>
									<small>{currentClient.manager}</small>
									<h5>Social Media Manager</h5>
									<small>{currentClient.socialManager}</small>

									<h3 className="orange">STORE LOCATIONS</h3>
									{currentClient.locations && (
										currentClient.locations.map(location => (location.type !== "billing") && (
										<div className="store" key={location.id}>
											<h5>{location.city ? ( location.city ) : (location.name)}</h5>
											<small>Address: {location.address+", "+location.state+" "+location.postalCode}</small>
											<small>Store Email: {location.email}</small>
											<small>Store Phone #: {location.phone}</small>
											<small>Lead Email: {location.leadEmail}</small>
											<small>Lead Phone#: {location.forwardingPhone}</small>
										</div>
									)))}

									<h3 className="purple">CONTACTS</h3>
									{currentClient.contacts && (
										currentClient.contacts.map(contact => (contact.type !== "billing") && (
										<div className="contact" key={contact.id}>
											<h5>{contact.name}</h5>
											<small>Email: {contact.email}</small>
											<small>Phone: {contact.phone}</small>
										</div>
									)))}
								</div>
							</div>
							<div>
								<div className="tab-menu">
									<div onClick={() => this.toggleTabs(1)} className="tab active">Client Support</div>
									<div onClick={() => this.toggleTabs(2)} className="tab">Invoicing</div>
									<div onClick={() => this.toggleTabs(3)} className="tab">Payment</div>
									<div onClick={() => this.toggleTabs(4)} className="tab">Term</div>
									<div onClick={() => this.toggleTabs(5)} className="tab">Separation</div>
									<div onClick={() => this.toggleTabs(6)} className="tab">Service Agreement</div>
								</div>
								<div className="tab-body">
									<section className="tab active">
										<ClientSupport />
									</section>
									<section className="tab">
										<Invoicing />
									</section>
									<section className="tab">
										<Payment />
									</section>
									<section className="tab">
										<Term prodSite={prodSite} />
									</section>
									<section className="tab">
										<Separation roles={roles} />
									</section>

									<section className="tab service-agreement">
										<ServiceAgreement currentClient={currentClient} prodSite={prodSite} />
									</section>
								</div>
							</div>
							{(currentClient.servicePackage !== "Select...") && (
								<div className="service-billing">
									<h5> Billing Details </h5>
									<h2>${(currentClient.servicePackage === "lite") && ( "350" )}
									{(currentClient.servicePackage === "prime") && ( "750" )}
									{(currentClient.servicePackage === "premium") && ( "1,150" )}
									{(currentClient.servicePackage === "platinum") && ( "1,700" )}/Month</h2>
									<h5>${(currentClient.servicePackage === "lite") && ( "399" )}
									{(currentClient.servicePackage === "prime") && ( "1,700" )}
									{(currentClient.servicePackage === "premium") && ( "2,900" )}
									{(currentClient.servicePackage === "platinum") && ( "3,500" )} Setup</h5>
								</div>
							)}
							<div className="service-fineprint">
								<small>*LIMITED TIME OFFER - Early adopters, claim Velocity expenses (including set up fees) at 100% CO-OP through December 2020.
								After this date, rate will revert back to 50% CO-OP.</small>
							</div>
						</div>
					</section>
				</div>
			)
		)
	}
}

export default ProgramDetails;