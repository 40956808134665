import { mon } from './uiHelpers';


// converts camelCase => Camel Case
export const camelToUpperCase = (val) => {
		val = val.replace(/([A-Z])/g, ' $1');
		val = val.replace(/^./, function(str){
			return str.toUpperCase();
		});
		return val;
}

// converts null values to empty string so input placeholders will work
export const valueEmpty = (value) => {
	if (value === null || value === "null") {
		value = "";
	}
	return value;
}
// is checkbox checked or not based on value
export const isChecked = (value) => {
	if (value && value !== 0 && value !== false && value !== "0" && value !== "false") {
		return "checked";
	}
}
// is a select option selected or not based on value
export const isSelected = (option, value) => {
	if (String(option) === String(value)) {
		return "selected";
	} else {
		return false;
	}
}
export const getstatRange = () => {
	var year, month, day, nowStartDate, nowEndDate;
    var today = new Date();
    // console.log(range);
    year = today.getFullYear();
    month = formatMonth( today.getMonth()+1 );
    day = formatMonth( today.getDate()-1 );
    nowEndDate = year+"-"+month+"-"+day;

    nowStartDate = new Date(new Date().setDate(new Date().getDate() - 32));
    year = nowStartDate.getFullYear();
    month = formatMonth( nowStartDate.getMonth()+1 );
    day = formatMonth( nowStartDate.getDate() );
    nowStartDate = year+"-"+month+"-"+day;

    return[nowStartDate, nowEndDate]
}

// calculates 4 dates based on a given defined range : week, month, quarter, year
export const getQuickRange = (range) => {
	var year, month, day;
    var today = new Date();
    // console.log(range);
    year = today.getFullYear();
    month = formatMonth( today.getMonth()+1 );
    day = formatMonth( today.getDate() );
    var nowEndDate = year+"-"+month+"-"+day;

    var nowStartDate, thenEndDate, thenStartDate;
    if (range === "week") {
    	nowStartDate = new Date(new Date().setDate(new Date().getDate() - 7));
	    year = nowStartDate.getFullYear();
	    month = formatMonth( nowStartDate.getMonth()+1 );
	    day = formatMonth( nowStartDate.getDate() );
	    nowStartDate = year+"-"+month+"-"+day;

	    thenEndDate = new Date(new Date().setDate(new Date().getDate() - 8));
	    year = thenEndDate.getFullYear();
	    month = formatMonth( thenEndDate.getMonth()+1 );
	    day = formatMonth( thenEndDate.getDate() );
	    thenEndDate = year+"-"+month+"-"+day;

	    thenStartDate = new Date(new Date().setDate(new Date().getDate() - 15));
	    year = thenStartDate.getFullYear();
	    month = formatMonth( thenStartDate.getMonth()+1 );
	    day = formatMonth( thenStartDate.getDate() );
	    thenStartDate = year+"-"+month+"-"+day;
    }
    if (range === "month") {
    	nowStartDate = new Date(new Date().setDate(new Date().getDate() - 30));
	    year = nowStartDate.getFullYear();
	    month = formatMonth( nowStartDate.getMonth()+1 );
	    day = formatMonth( nowStartDate.getDate() );
	    nowStartDate = year+"-"+month+"-"+day;

	    thenEndDate = new Date(new Date().setDate(new Date().getDate() - 31));
	    year = thenEndDate.getFullYear();
	    month = formatMonth( thenEndDate.getMonth()+1 );
	    day = formatMonth( thenEndDate.getDate() );
	    thenEndDate = year+"-"+month+"-"+day;

	    thenStartDate = new Date(new Date().setDate(new Date().getDate() - 61));
	    year = thenStartDate.getFullYear();
	    month = formatMonth( thenStartDate.getMonth()+1 );
	    day = formatMonth( thenStartDate.getDate() );
	    thenStartDate = year+"-"+month+"-"+day;
    }
    if (range === "quarter") {
    	nowStartDate = new Date(new Date().setDate(new Date().getDate() - 90));
	    year = nowStartDate.getFullYear();
	    month = formatMonth( nowStartDate.getMonth()+1 );
	    day = formatMonth( nowStartDate.getDate() );
	    nowStartDate = year+"-"+month+"-"+day;

	    thenEndDate = new Date(new Date().setDate(new Date().getDate() - 91));
	    year = thenEndDate.getFullYear();
	    month = formatMonth( thenEndDate.getMonth()+1 );
	    day = formatMonth( thenEndDate.getDate() );
	    thenEndDate = year+"-"+month+"-"+day;

	    thenStartDate = new Date(new Date().setDate(new Date().getDate() - 181));
	    year = thenStartDate.getFullYear();
	    month = formatMonth( thenStartDate.getMonth()+1 );
	    day = formatMonth( thenStartDate.getDate() );
	    thenStartDate = year+"-"+month+"-"+day;
    }
    if (range === "year") {
    	nowStartDate = new Date(new Date().setDate(new Date().getDate() - 365));
	    year = nowStartDate.getFullYear();
	    month = formatMonth( nowStartDate.getMonth()+1 );
	    day = formatMonth( nowStartDate.getDate() );
	    nowStartDate = year+"-"+month+"-"+day;

	    thenEndDate = new Date(new Date().setDate(new Date().getDate() - 366));
	    year = thenEndDate.getFullYear();
	    month = formatMonth( thenEndDate.getMonth()+1 );
	    day = formatMonth( thenEndDate.getDate() );
	    thenEndDate = year+"-"+month+"-"+day;

	    thenStartDate = new Date(new Date().setDate(new Date().getDate() - 731));
	    year = thenStartDate.getFullYear();
	    month = formatMonth( thenStartDate.getMonth()+1 );
	    day = formatMonth( thenStartDate.getDate() );
	    thenStartDate = year+"-"+month+"-"+day;
    }

    return [nowStartDate, nowEndDate, thenStartDate, thenEndDate];
}
// converts 2019-10-22 to October 22, 2019
export const formatDateDisplay = (date) => {
	var year = date.split('-')[0];
	var month = date.split('-')[1];
	var day = date.split('-')[2];
	
	month = mon[month-1];

	return month+" "+day+", "+year;
}

// converts 2019-10-22 to October 22 (Anually)
export const formatDateToMonthDayAndStaticWord = (date, staticWord) => {
	var year = date.split('-')[0];
	var month = date.split('-')[1];
	var day = date.split('-')[2];
	month = mon[month-1];

	return month+" "+day+" ("+ staticWord +")";
}
// converts time stamp to date
export const formatDate = (timestamp) => {
	timestamp = new Date(timestamp).toLocaleString();
	var date = timestamp.split(',')[0];
	var time = timestamp.split(',')[1];
	return [date, time];
}
export const formatDateTimeLocal = (timestamp) => {
	var offset = new Date().getTimezoneOffset();
	var myDate = new Date(timestamp);
	console.log(myDate);
	myDate.setMinutes( myDate.getMinutes() - offset );
	console.log(myDate);
	var localTime = myDate.toLocaleString();
	console.log(localTime);
	var date = localTime.split(',')[0];
	var time = localTime.split(',')[1];
	return [date, time];
}
// adds leading 0 to dates below 10
export const formatMonth = (date) => {
    if (date < 10) { date = "0"+date }
    return date;
}
// converts seconds to timer format 1234 => hr:mn:sc
export const secondsToTime = (s) => {
	var time;
	s = Math.round(s)
	if (s) {
		var h = Math.floor(s/3600);
		if (h<10) {
			h = "0"+h;
		}

		s %= 3600;
		var m = Math.floor(s/60);
		if (m<10) {
			m = "0"+m;
		}

		s = s % 60;
		if (s<10) {
			s = "0"+s;
		}

		time = h+":"+m+":"+s;
		// 00:00:00
	} else {
		time = "00:00:00";
	}
	return time;
}

export const numberToLocal = (number) => {
	if (number === "") {
		number = 0
	}
	number = parseFloat(number);
	number = number.toLocaleString();
	return number;
}
// converts decimals to price: 5432.1 => $5,432.10
export const numberToPrice = (number) => {
	if (number === "" || number === Infinity) {
		number = 0
	}
	number = parseFloat(number);
	number = number.toLocaleString("en-US", {style:"currency", currency:"USD"});

	if (number === "$NaN" || number === "-$NaN") {
		number = "$0.00";
	}
    return number;
}
// converts decimals to percent: 54.3210987654321 => 54.32%
export const numberToPercent = (number) => {
	if (number === "") {
		number = 0
	}
	number = parseFloat(number);
	number = number.toFixed(1);
	if (number === "NaN" || number === "-NaN") {
		number = "0";
	}
	number = number+"%";
	return number;
}
// calculates average of key values in object
export const getCallAverageTime = (calls, key) => {
	var times = [];
	var avg;
	for (var i = calls.length - 1; i >= 0; i--) {
		times.push(calls[i][key])
	}
	if (times.length > 0) {
		avg = Math.round(times.reduce((a,b) => a + b, 0) / times.length);
	} else {
		avg = 0
	}
	return avg
}
// caclulates the total of given values
export const totalValues = (obj, key) => {
	var total = 0
	for (var i = obj.length - 1; i >= 0; i--) {
		total = total + parseInt(obj[i][key]);
	}
	if (key !== "cost") {
		// total = total.toLocaleString();
	}
	return total;
}
//converts array of values to array of percents, used in charts
export const getNumbersAsPercents = (arr, i) => {
	var total = 0
	if (!i) { i = 0 }
	for (var a = arr.length - 1; a >= 0; a--) {
		total = total + parseInt(arr[a][i]);
	}
	for (let b = 0; b < arr.length; b++) {
		arr[b].push(
			((arr[b][i]/total)*100).toFixed(1)
		)
	}
	return arr;
}

// gets the different in values
export const getDifference = (a, b) => {
	var number = a - b;
	return number;
}
// calculates the percentage of change between 2 values
export const getPercentChange = (a, b) => {
	a = parseFloat(a); // current month
	b = parseFloat(b); // past month

	//console.log(a, b)

	if (Number.isNaN(a)) { a = 0 }
	if (Number.isNaN(b)) { b = 0 }
	var status = "";
	if (a < b) { status = "down"; } else { status = "up"; }

	var number = Math.abs(b - a);
	var percent;
	if (number !== 0) {
		if (b === 0) {
			percent = 100
		} else {
			percent = (number/b)*100;
		}
	} else {
		percent = 0
	}
	percent = percent.toFixed(1);
	//console.log(a, b, number, percent, status);
	return [percent, status];
}
// counts the number of leads that match a given type
export const countLeads = (leads, type, attribute) => {
	var number;
	if (type === "other") {
		number = leads.reduce(function (n, lead) {
		    return n + (
		    	lead[attribute] === null ||
		    	lead[attribute] === ""
		    	);
		}, 0);
	} else {
		number = leads.reduce(function (n, lead) {
		    return n + (lead[attribute] === type);
		}, 0);
	}
	//console.log(leads, type, attribute, number)
	return number;
}
export const countUniqueKeys = (data, key) => {
	var counts = {};
    data.forEach(function (item) {
        // add the form to the hash if it is missing;
        // set initial count to 0
        if (!counts.hasOwnProperty(item[key])) {
            counts[item[key]] = 0;
        }
        // increment the count based on the form
        counts[item[key]] += 1;
    });
    counts = Object.entries(counts);
    counts.sort((a, b) => b[1] - a[1]);
    return counts;
}
//sorts the data by the type in desc order and returns the key/value of the type in the given position
export const getTopResult = (data, type, position) => {
	var filtered = [];
	if (data) {
		var distinctTypes = [...new Set(data.map(x => x[type]))];
		for (var i = distinctTypes.length - 1; i >= 0; i--) {
			var count = countLeads(data, distinctTypes[i], type);			
			var obj = {}
			obj["key"] = distinctTypes[i];
			obj["val"] = count;
			filtered.push(obj);
		}
		filtered = filtered.sort(function(a,b) {
			return b.val - a.val;
		});
	} else {
		filtered = [{"key": undefined, "val": 0}]
	}
	return filtered[position];
}
// returns the value of a given peram from the URL
export const getUrlParameter = (name) => {
	var urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(name);
};

export const checkStringMatch = (idA, idB, idStatus) => {
	var A = document.querySelector('#'+idA).value;
	var B = document.querySelector('#'+idB).value;
	var status = false;
	if (A === B) {
		status = true;
	}
	return status;	
}
