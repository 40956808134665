import React from 'react';
import Chart from 'chart.js/auto';

import { ReportHeading, Statistic } from '../../helpers/uiComponents';
import { numberToLocal } from '../../helpers/uiFunctions';
import { errorMessage } from '../../helpers/uiHelpers';
import { loadSearchConsole } from '../data/supermetricsService';

class SearchConsole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quickStats: [[],[]],
            loading: true,
            errorMessage: null,
        }
    }
    componentDidMount() {
        loadSearchConsole( // fields, rows, order, compareType, filter, settings, endpoints, dates, clientCode, token
            ["impressions", "clicks", "ctr",],
            10,
            null,
            "custom",
            null,
            {"blanks_to_zero": true},
            this.props.endpoints,this.props.dates,this.props.currentClient.clientCode,this.props.accessToken
        ).then(response => {
            if (response.success) {
                this.setState({
                    loading: false,
                    quickStats: response.result.data,
                });
            } else {
                console.log(errorMessage);
                this.setState({
                    loading: false,
                    errorMessage: errorMessage.message,
                });
            }
            
        });
        loadSearchConsole( // fields, rows, order, compareType, filter, settings, endpoints, dates, clientCode, token
            ["date", "impressions", "clicks", "ctr",],
            500,
            null,
            null,
            null,
            null,
            this.props.endpoints,this.props.dates,this.props.currentClient.clientCode,this.props.accessToken
        ).then(response => {
            // console.log(response);
            if (response.success) {
                this.setState({
                    chartsLoading: false,
                    loading: false,
                });
                let data = response.result.data;
                setTimeout(() => {
                    this.buildDailyChart("Impressions", data, 1, 'graph-impressions')
                    this.buildDailyChart("Clicks", data, 2, 'graph-clicks')
                    this.buildDailyChart("Click Through-Rate", data, 3, 'graph-ctr')
                }, 100);
            } else {
                this.setState({
                    loading: false,
                    errorMessage: errorMessage.message,
                });
            }
        });
        loadSearchConsole( // fields, rows, order, compareType, filter, settings, endpoints, dates, clientCode, token
            ["query", "impressions", "clicks", "ctr",],
            10,
            ["clicks desc"],
            null,
            "query != (unknown)",
            null,
            this.props.endpoints,this.props.dates,this.props.currentClient.clientCode,this.props.accessToken
        ).then(response => {
            if (response.success) {
                this.setState({
                    loading: false,
                    topSearchLables: response.result.data.shift(),
                    topSearchQueries: response.result.data,
                });
                //console.log(this.state);
            } else {
                this.setState({
                    loading: false,
                    errorMessage: errorMessage.message,
                });
            }
        });
        loadSearchConsole( // fields, rows, order, compareType, filter, settings, endpoints, dates, clientCode, token
            ["page", "impressions", "clicks", "ctr",],
            10,
            ["clicks desc"],
            null,
            "query != (unknown)",
            null,
            this.props.endpoints,this.props.dates,this.props.currentClient.clientCode,this.props.accessToken
        ).then(response => {
            //console.log(response);
            if (response.success) {
                this.setState({
                    loading: false,
                    topPageLables: response.result.data.shift(),
                    topPageQueries: response.result.data,
                });
                //console.log(this.state);
            } else {
                this.setState({
                    loading: false,
                    errorMessage: errorMessage.message,
                });
            }
        });
    }
    
    buildDailyChart(title, inputData, index, elemId) {
		if (inputData) {
			var data = [];
			var options = [...new Set(inputData.map(x => x[0]))];
            
            //console.log(data);
            for (var i = inputData.length - 1; i >= 0; i--) {
				data.push(inputData[i][index]);
			}
			data.reverse(); //options.reverse();

			var ctx = document.getElementById(elemId);
			new Chart(ctx, {
				"type":"line",
				"data": {
					"labels":options,
					"datasets":[
						{
							data:data,
							backgroundColor: "rgba(72, 152, 187, 0.3)",
							borderColor: "rgba(72, 152, 187, 1)",
							pointBackgroundColor: "rgba(190, 209, 47, 1)",
							pointRadius: 5,
							fill: "start",
						}
					]
				},
				options: {
                    plugins: {
                        aspectRatio: 1,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: title,
                            fontSize: 16,
                        },
                    },
                    layout: {
                        padding: {
                            left: 50,
                            right: 50,
                            top: 25,
                            bottom: 25
                        }
                    },
                    scales: {
						xAxes: [{
							stacked: true,
						}],
						yAxes: [{
							stacked: true
						}]
					},
					animations: {
						linear: {
							duration: 1000,
							easing: 'linear',
							from: 1,
							to: 0,
							loop: true
						}
					},
				}
			});
		}
	}
    toggleTabs(id, index) {
        var tabs = document.querySelectorAll(".tab-menu-"+id+" > .tab");
        var body = document.querySelectorAll(".tab-body-"+id+" > .tab");
        Object.entries(tabs).forEach(([tabs, tab]) => {
            tab.classList.remove("active");
            if(tab.classList.contains("tab-"+index)) {
                tab.classList.add("active");
            }
        });
        Object.entries(body).forEach(([body, tab]) => {
            tab.classList.remove("active");
            if(tab.classList.contains("tab-"+index)) {
                tab.classList.add("active");
            }
        });
    }
    render() {
        const {quickStats, topSearchLables, topSearchQueries, topPageLables, topPageQueries, chartsLoading, loading, errorMessage} = this.state
        return(
            <div id="metrics-searchConsole" className="report metrics-searchConsole">
                <ReportHeading
                    title="Search Console"
                    name={this.props.currentClient.name}
                    dates={this.props.dates}
                    hideHistorical={this.props.hideHistorical}
                />
                {loading && (
                    <React.Fragment>
                        <h3>Fetching your Search Console Results...</h3>
                        <div className="report-loading">
                            <p><i className="fas fa-circle-notch"></i></p>
                        </div>
                    </React.Fragment>
                )}
                {(errorMessage) && (
                    <p>{errorMessage}</p>
                )}
                {(!loading && !errorMessage) && (
                    <React.Fragment>
                        <section className="stats row">
                            <Statistic
                                label={quickStats[0][0]}
                                value={(quickStats[1][0]) ? numberToLocal(quickStats[1][0]) : ("Loading...")}
                                status={(quickStats[1][1] < 0) ? ("down") : ("up")}
                                percent={(quickStats[1][1]) ? (quickStats[1][1]) : (0)}
                                hideHistorical={this.props.hideHistorical}
                            />
                            <Statistic
                                label={quickStats[0][2]}
                                value={(quickStats[1][2]) ? numberToLocal(quickStats[1][2]) : ("Loading...")}
                                status={(quickStats[1][3] < 0) ? ("down") : ("up")}
                                percent={(quickStats[1][3]) ? (quickStats[1][3]) : (0)}
                            />
                            <Statistic
                                label={quickStats[0][4]+"%"}
                                value={(quickStats[1][4]) ? numberToLocal(quickStats[1][4]) : ("Loading...")}
                                status={(quickStats[1][5] < 0) ? ("down") : ("up")}
                                percent={(quickStats[1][5]) ? (quickStats[1][5]) : (0)}
                            />
                        </section>

                        <div className="tab-menu tab-menu-0">
                            <div onClick={() => this.toggleTabs(0, 1)} className="tab tab-1 active">Impressions</div>
                            <div onClick={() => this.toggleTabs(0, 2)} className="tab tab-2">Clicks</div>
                            <div onClick={() => this.toggleTabs(0, 3)} className="tab tab-3">Click Through-Rate</div>
                        </div>
                        <div className="tab-body tab-body-0">
                            <div className="tab tab-1 active">
                                <h4><strong>Impressions</strong> - The number of times any URL from your site appeared in Google Search results viewed by a user, not including paid Google Ads search impressions.</h4>
                                <section className="stats-graph">
                                    <div className="graph wide">
                                        <div className="graph-container">
                                            {(chartsLoading) && (
                                                <div className="report-loading small">
                                                    <p><i className="fas fa-circle-notch"></i></p>
                                                </div>
                                            )}
                                            <canvas className="chart" id="graph-impressions" />
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="tab tab-2">
                                <h4><strong>Clicks</strong> - The number of clicks on your website URLs from a Google Search results page, not including clicks on paid Google Ads search results.</h4>
                                <section className="stats-graph">
                                    <div className="graph wide">
                                        <div className="graph-container">
                                            <canvas className="chart" id="graph-clicks" />
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="tab tab-3">
                                <h4><strong>Click Through-Rate</strong> - Also known as "Click-Through-Rate" is the % of Impressions that clicked on your website URL from a Google Search results page</h4>
                                <section className="stats-graph">
                                    <div className="graph wide">
                                        <div className="graph-container">
                                            <canvas className="chart" id="graph-ctr" />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>

                        <div className="tab-menu tab-menu-1">
                            <div onClick={() => this.toggleTabs(1, 4)} className="tab tab-4 active">Top Search Queries</div>
                            <div onClick={() => this.toggleTabs(1, 5)} className="tab tab-5">Top URLs</div>
                        </div>

                        <div className="tab-body tab-body-1">
                            <div className="tab tab-4 active">
                                <section className="calls-list gmb-list">
                                    {(topSearchLables) ? (
                                        <React.Fragment>
                                            <div className="table-heading">
                                                {topSearchLables.map((label, index) =>
                                                    <div key={index}>{label}</div>
                                                )}
                                            </div>
                                            <ul className="table-body no-hover">
                                                {topSearchQueries.map((query, index) =>
                                                    <li key={index} >
                                                    <div>{query[0]}</div>
                                                    <div>{numberToLocal(query[1])}</div>
                                                    <div>{numberToLocal(query[2])}</div>
                                                    <div>{numberToLocal(query[3])}</div>
                                                </li>
                                                )}
                                            </ul>
                                        </React.Fragment>
                                    ): (
                                        <div className="report-loading small">
                                            <p><i className="fas fa-circle-notch"></i></p>
                                        </div>
                                    )}
                                </section>
                            </div>
                            <div className="tab tab-5">
                            <section className="calls-list gmb-list">
                                    {(topPageLables) ? (
                                        <React.Fragment>
                                            <div className="table-heading">
                                                {topPageLables.map((label, index) =>
                                                    <div key={index}>{label}</div>
                                                )}
                                            </div>
                                            <ul className="table-body no-hover">
                                                {topPageQueries.map((query, index) =>
                                                    <li key={index} >
                                                    <div>{query[0]}</div>
                                                    <div>{numberToLocal(query[1])}</div>
                                                    <div>{numberToLocal(query[2])}</div>
                                                    <div>{numberToLocal(query[3])}</div>
                                                </li>
                                                )}
                                            </ul>
                                        </React.Fragment>
                                    ): (
                                        <div className="report-loading small">
                                            <p><i className="fas fa-circle-notch"></i></p>
                                        </div>
                                    )}
                                </section>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }
}
export default SearchConsole;