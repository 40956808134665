import React from 'react';

export class Separation extends React.Component {

	render() {
		const roles = this.props.roles;

		return (
			<ul>
				<li>Separation activities will commence only after all outstanding Mobile Marketing invoices are paid.</li>
				<li>Separation will occur within the 90 day period from notification.  The client will be immediately billed for the hosting and site maintenance charges required to span the 90 day work period.</li>
				<li>Separation from the Velocity program grants the Retailer the following assets and transition activities :</li>
				<ul>
					<li>Domain (including any associated email services)</li>
					<li>All existing site pages (pages, content, navigation)</li>
					<li>All Blogs</li>
					<li>Continuity of Google Analytics</li>
					<li>Return of Facebook account credit card to client’s card</li>
					<li>Removal of client’s credit card information from Mobile Marketing billing system (if present)</li>
				</ul>
				{(roles.includes("portalAdm")) && (
					<React.Fragment>
						<li>Separation from the Velocity program removes the following Mobile Marketing assets :</li>
						<ul>
							<li>Item details</li>
							<li>Item catalog management service</li>
							<li>Reputation management service</li>
							<li>Automated lead tracking</li>
							<li>Mobile business cards</li>
							<li>Social advertising</li>
							<li>Google Paid advertising</li>
							<li>Call tracking numbers (replaced by 999-999-9999)</li>
							<li>Automated email responses (client can enable email responses on site forms)</li>
							<li>Rugs.Shop (could be offered on their new site, if desired)</li>
							<li>Website hosting</li>
							<li>Image hosting and management</li>
							<li>Website security certificate management</li>
						</ul>
					</React.Fragment>
				)}
			</ul>
		)
	}
}