import React from "react";
import {
  formatDateDisplay,
  numberToPrice,
  formatDate,
  numberToLocal,
  numberToPercent,
} from "./uiFunctions";
import { chartColors } from "./uiHelpers";
import { BrowserRouter as Router, Link } from "react-router-dom";

export class Statistic extends React.Component {
  render() {
    const props = this.props;
    return (
      <div className="stat">
        <div className="stat-label">
          <label>
            {String(props.label).toLocaleLowerCase() === "share room"
              ? "Shared Room Visualization"
              : props.label}
          </label>
        </div>
        <div className={"stat-value " + props.status}>
          <p>{props.value}</p>
        </div>
        {!props.hideHistorical && (
          <div className="stat-performance">
            <p className="percent">
              {props.percent ? props.percent + "%" : "0%"}
            </p>
            {!props.reverse ? (
              <div className="arrow">
                <i className={"fas fa-caret-" + props.status}></i>
              </div>
            ) : (
              <div className="arrow reverse">
                <i className={"fas fa-caret-" + props.status}></i>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export class Breakdown extends React.Component {
  render() {
    const data = this.props.data;
    const title = this.props.title;
    const format = this.props.format;
    const dataIndex = this.props.dataIndex;
    const dataType = this.props.dataType;

    return (
      <div className={"breakdown " + format}>
        <p className="title">{title}</p>

        {data.length > 0 ? (
          data.map((item, index) => (
            <div className="stat" key={index}>
              <p className="stat-value">
                <span className="value">
                  {dataType === "percent"
                    ? numberToPercent(item[dataIndex])
                    : numberToLocal(item[dataIndex])}
                </span>
                <span
                  className="chartColor"
                  style={{ background: chartColors[index] }}
                ></span>
              </p>
              <p>{item[0]}</p>
            </div>
          ))
        ) : (
          <div
            style={{
              width: "100%",
              height: "67%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p style={{ textAlign: 'center', fontWeight: 'bold'}}>There are no stats reported for Room Visualizer during this time.</p>
          </div>
        )}
      </div>
    );
  }
}

export class BreakdownNotFound extends React.Component {
  render() {
    return (
      <div className="graph">
        <div
          className="graph-container"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "space-around",
          }}
        >
          <p
            className="breakdown-content"
            style={{ textAlign: "center", fontWeight: "bold", padding: "20px" }}
          >
            There are no stats reported for Room Visualizer during this time.
          </p>
        </div>
      </div>
    );
  }
}

export class ReportHeading extends React.Component {
  render() {
    const props = this.props;
    return (
      <section className="heading">
        {props.title === "Google Analytics Statistics" && (
          <div className="banner-notice">
            <p className="banner-notice-text">
              Due to Google Analytics 4 transitions, this tab will stop
              collecting data after July 1, 2023. For future performance
              metrics, please refer to our new GA4 tab.
            </p>
            <Router>
              <Link
                to="metrics-ga4analytics"
                onClick={() => this.props.onClick("metrics-ga4analytics")}
              >
                <button className="launch-btn-red">LAUNCH GA4</button>
              </Link>
            </Router>
          </div>
        )}
        {props.title === "Google Analytics 4 Statistics" && (
          <div className="banner-notice-green">
            <p className="banner-notice-text">
              Welcome to Google Analytics 4! After July 1, 2023, your website
              traffic performance will be measured here.
            </p>
          </div>
        )}
        <h1>
          {props.name && props.name + " - "}
          {props.title}
        </h1>
        <div className="date-ranges">
          <div>
            {props.dates !== "hide" && (
              <h5>
                {formatDateDisplay(props.dates[0])}{" "}
                <i className="fas fa-long-arrow-alt-right"></i>{" "}
                {formatDateDisplay(props.dates[1])}
              </h5>
            )}
            {props.hideHistorical === false && (
              <small>
                VS:{" "}
                <span>
                  {formatDateDisplay(props.dates[2])}{" "}
                  <i className="fas fa-long-arrow-alt-right"></i>{" "}
                  {formatDateDisplay(props.dates[3])}
                </span>
              </small>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export class Invoice extends React.Component {
  render() {
    const currentClient = this.props.currentClient;
    const order = this.props.order;
    const closeInvoice = this.props.closeInvoice;
    const printInvoice = this.props.printInvoice;

    return (
      <div className="invoice_container">
        <div className="invoice_actions">
          <i className="fas fa-print" onClick={printInvoice}></i>
          <i className="far fa-window-close" onClick={closeInvoice}></i>
        </div>

        <div className="invoice_logo">
          {currentClient.logo && (
            <img
              src={"https://" + currentClient.logo}
              alt={currentClient.name}
            />
          )}
        </div>

        <div className="invoice_heading">
          <div>Order ID: {order.increment_id}</div>
          <div>Order Date: {formatDate(order.created_at)[0]}</div>
        </div>

        <div className="invoice_customer">
          <div className="invoice_bill_to">
            <h3>Billed To:</h3>
            <div>
              {order.billing_address.firstname +
                " " +
                order.billing_address.lastname}
            </div>
            <div>{order.billing_address.street[0]}</div>
            <div>
              {order.billing_address.city +
                ", " +
                order.billing_address.region +
                " " +
                order.billing_address.postcode}
            </div>
            <div>
              <i className="far fa-envelope"></i>: {order.billing_address.email}
            </div>
            <div>
              <i className="fas fa-phone"></i>:{" "}
              {order.billing_address.telephone}
            </div>
          </div>
          <div className="invoice_ship_to">
            <h3>Shipped To:</h3>
            <div>
              {order.shipping_address.firstname +
                " " +
                order.shipping_address.lastname}
            </div>
            <div>{order.shipping_address.street[0]}</div>
            <div>
              {order.shipping_address.city +
                ", " +
                order.shipping_address.region +
                " " +
                order.shipping_address.postcode}
            </div>
            <div>
              <i className="far fa-envelope"></i>:{" "}
              {order.shipping_address.email}
            </div>
            <div>
              <i className="fas fa-phone"></i>:{" "}
              {order.shipping_address.telephone}
            </div>
          </div>
        </div>

        <div className="invoice_transaction">
          <div className="invoice_payment">
            <h3>Payment Method:</h3>
            <div>{order.payment_method}</div>
          </div>
          <div className="invoice_shipping">
            <h3>Shipping Method:</h3>
            <div>{order.shipping_description}</div>
            <div>
              <em>
                Shipping Charges: {numberToPrice(order.shipping_invoiced)}
              </em>
            </div>
          </div>
        </div>

        <div className="invoice_items">
          <div className="table-heading">
            <div>Product</div>
            <div>SKU</div>
            <div>Price</div>
            <div>Qty</div>
            <div>Tax</div>
            <div>Subtotal</div>
          </div>
          <div className="table-body">
            {order.items.map(
              (item) =>
                item.product_type !== "configurable" && (
                  <React.Fragment key={item.sku}>
                    <li>
                      <div>{item.name}</div>
                      <div>{item.sku}</div>
                      <div>{numberToPrice(item.price)}</div>
                      <div>{item.qty_invoiced}</div>
                      <div>{numberToPrice(item.tax_invoiced)}</div>
                      <div>{numberToPrice(item.price + item.tax_invoiced)}</div>
                    </li>
                  </React.Fragment>
                )
            )}
          </div>
        </div>
        <div className="invoice_totals">
          <div className="invoice_subtotal">
            <span className="label">Subtotal:</span>
            <span className="value">{numberToPrice(order.subtotal)}</span>
          </div>
          <div className="invoice_subtotal">
            <span className="label">Shipping:</span>
            <span className="value">
              {numberToPrice(order.shipping_invoiced)}
            </span>
          </div>
          <div className="invoice_discount">
            <span className="label">
              Discount {order.coupon_code && "(" + order.coupon_code + ")"}:
            </span>
            <span className="value">
              {numberToPrice(order.discount_amount)}
            </span>
          </div>
          <div className="invoice_grandtotal">
            <span className="label">Grand Total:</span>
            <span className="value">{numberToPrice(order.total_invoiced)}</span>
          </div>
        </div>
      </div>
    );
  }
}
