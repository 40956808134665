export const printStyles =
"<style>"+
	"body{padding:25px;background:white}"+
	"body .invoice_actions{float:right;font-size:20px}"+
	"body .invoice_actions>i{margin-left:10px;cursor:pointer}"+
	"body .invoice_actions>i:hover{color:#bed12f}"+
	"body .invoice_logo{margin-bottom:15px}"+
	"body .invoice_logo img{height:85px}"+
	"body .invoice_heading{background:#334259;padding:10px 15px;color:white;font-weight:600;line-height:25px}"+
	"body .invoice_customer,body .invoice_transaction{display:inline-flex;width:100%;margin-bottom:15px}"+
	"body .invoice_customer>*,body .invoice_transaction>*{width:100%;line-height:20px}"+
	"body .invoice_customer>* h3,body .invoice_transaction>* h3{margin-bottom:7px}"+
	"body .invoice_items .table-heading{border-top:3px solid #334259;margin:15px 0px 10px}"+
	"body .invoice_items .table-heading>*{font-weight:900 !important;text-transform:uppercase}"+
	"body .invoice_items .table-heading,body .invoice_items .table-body{min-width:100%}"+
	"body .invoice_items .table-heading,body .invoice_items .table-body li{display:block}"+
	"body .invoice_items .table-heading>*,body .invoice_items .table-body li>*{font-size:12px;display:inline-block;text-overflow:ellipsis;overflow:hidden;vertical-align:top}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(1){width:calc(100% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(1){width:calc(50% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(3),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(3):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(3),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(3):nth-last-child(1){width:calc(33.3% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(4),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(3),body .invoice_items .table-heading>*:nth-child(3):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(4):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(4),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(3),body .invoice_items .table-body li>*:nth-child(3):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(4):nth-last-child(1){width:calc(25% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(5),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(4),body .invoice_items .table-heading>*:nth-child(3):nth-last-child(3),body .invoice_items .table-heading>*:nth-child(4):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(5):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(5),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(4),body .invoice_items .table-body li>*:nth-child(3):nth-last-child(3),body .invoice_items .table-body li>*:nth-child(4):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(5):nth-last-child(1){width:calc(20% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(6),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(5),body .invoice_items .table-heading>*:nth-child(3):nth-last-child(4),body .invoice_items .table-heading>*:nth-child(4):nth-last-child(3),body .invoice_items .table-heading>*:nth-child(5):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(6):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(6),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(5),body .invoice_items .table-body li>*:nth-child(3):nth-last-child(4),body .invoice_items .table-body li>*:nth-child(4):nth-last-child(3),body .invoice_items .table-body li>*:nth-child(5):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(6):nth-last-child(1){width:calc(16.66% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(7),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(6),body .invoice_items .table-heading>*:nth-child(3):nth-last-child(5),body .invoice_items .table-heading>*:nth-child(4):nth-last-child(4),body .invoice_items .table-heading>*:nth-child(5):nth-last-child(3),body .invoice_items .table-heading>*:nth-child(6):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(7):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(7),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(6),body .invoice_items .table-body li>*:nth-child(3):nth-last-child(5),body .invoice_items .table-body li>*:nth-child(4):nth-last-child(4),body .invoice_items .table-body li>*:nth-child(5):nth-last-child(3),body .invoice_items .table-body li>*:nth-child(6):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(7):nth-last-child(1){width:calc(14.28% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(8),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(7),body .invoice_items .table-heading>*:nth-child(3):nth-last-child(6),body .invoice_items .table-heading>*:nth-child(4):nth-last-child(5),body .invoice_items .table-heading>*:nth-child(5):nth-last-child(4),body .invoice_items .table-heading>*:nth-child(6):nth-last-child(3),body .invoice_items .table-heading>*:nth-child(7):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(8):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(8),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(7),body .invoice_items .table-body li>*:nth-child(3):nth-last-child(6),body .invoice_items .table-body li>*:nth-child(4):nth-last-child(5),body .invoice_items .table-body li>*:nth-child(5):nth-last-child(4),body .invoice_items .table-body li>*:nth-child(6):nth-last-child(3),body .invoice_items .table-body li>*:nth-child(7):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(8):nth-last-child(1){width:calc(12.5% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(9),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(8),body .invoice_items .table-heading>*:nth-child(3):nth-last-child(7),body .invoice_items .table-heading>*:nth-child(4):nth-last-child(6),body .invoice_items .table-heading>*:nth-child(5):nth-last-child(5),body .invoice_items .table-heading>*:nth-child(6):nth-last-child(4),body .invoice_items .table-heading>*:nth-child(7):nth-last-child(3),body .invoice_items .table-heading>*:nth-child(8):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(9):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(9),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(8),body .invoice_items .table-body li>*:nth-child(3):nth-last-child(7),body .invoice_items .table-body li>*:nth-child(4):nth-last-child(6),body .invoice_items .table-body li>*:nth-child(5):nth-last-child(5),body .invoice_items .table-body li>*:nth-child(6):nth-last-child(4),body .invoice_items .table-body li>*:nth-child(7):nth-last-child(3),body .invoice_items .table-body li>*:nth-child(8):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(9):nth-last-child(1){width:calc(11.11% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(10),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(9),body .invoice_items .table-heading>*:nth-child(3):nth-last-child(8),body .invoice_items .table-heading>*:nth-child(4):nth-last-child(7),body .invoice_items .table-heading>*:nth-child(5):nth-last-child(6),body .invoice_items .table-heading>*:nth-child(6):nth-last-child(5),body .invoice_items .table-heading>*:nth-child(7):nth-last-child(4),body .invoice_items .table-heading>*:nth-child(8):nth-last-child(3),body .invoice_items .table-heading>*:nth-child(9):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(10):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(10),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(9),body .invoice_items .table-body li>*:nth-child(3):nth-last-child(8),body .invoice_items .table-body li>*:nth-child(4):nth-last-child(7),body .invoice_items .table-body li>*:nth-child(5):nth-last-child(6),body .invoice_items .table-body li>*:nth-child(6):nth-last-child(5),body .invoice_items .table-body li>*:nth-child(7):nth-last-child(4),body .invoice_items .table-body li>*:nth-child(8):nth-last-child(3),body .invoice_items .table-body li>*:nth-child(9):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(10):nth-last-child(1){width:calc(10% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(11),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(10),body .invoice_items .table-heading>*:nth-child(3):nth-last-child(9),body .invoice_items .table-heading>*:nth-child(4):nth-last-child(8),body .invoice_items .table-heading>*:nth-child(5):nth-last-child(7),body .invoice_items .table-heading>*:nth-child(6):nth-last-child(6),body .invoice_items .table-heading>*:nth-child(7):nth-last-child(5),body .invoice_items .table-heading>*:nth-child(8):nth-last-child(4),body .invoice_items .table-heading>*:nth-child(9):nth-last-child(3),body .invoice_items .table-heading>*:nth-child(10):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(11):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(11),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(10),body .invoice_items .table-body li>*:nth-child(3):nth-last-child(9),body .invoice_items .table-body li>*:nth-child(4):nth-last-child(8),body .invoice_items .table-body li>*:nth-child(5):nth-last-child(7),body .invoice_items .table-body li>*:nth-child(6):nth-last-child(6),body .invoice_items .table-body li>*:nth-child(7):nth-last-child(5),body .invoice_items .table-body li>*:nth-child(8):nth-last-child(4),body .invoice_items .table-body li>*:nth-child(9):nth-last-child(3),body .invoice_items .table-body li>*:nth-child(10):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(11):nth-last-child(1){width:calc(9.09% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading>*:nth-child(1):nth-last-child(12),body .invoice_items .table-heading>*:nth-child(2):nth-last-child(11),body .invoice_items .table-heading>*:nth-child(3):nth-last-child(10),body .invoice_items .table-heading>*:nth-child(4):nth-last-child(9),body .invoice_items .table-heading>*:nth-child(5):nth-last-child(8),body .invoice_items .table-heading>*:nth-child(6):nth-last-child(7),body .invoice_items .table-heading>*:nth-child(7):nth-last-child(6),body .invoice_items .table-heading>*:nth-child(8):nth-last-child(5),body .invoice_items .table-heading>*:nth-child(9):nth-last-child(4),body .invoice_items .table-heading>*:nth-child(10):nth-last-child(3),body .invoice_items .table-heading>*:nth-child(11):nth-last-child(2),body .invoice_items .table-heading>*:nth-child(12):nth-last-child(1),body .invoice_items .table-body li>*:nth-child(1):nth-last-child(12),body .invoice_items .table-body li>*:nth-child(2):nth-last-child(11),body .invoice_items .table-body li>*:nth-child(3):nth-last-child(10),body .invoice_items .table-body li>*:nth-child(4):nth-last-child(9),body .invoice_items .table-body li>*:nth-child(5):nth-last-child(8),body .invoice_items .table-body li>*:nth-child(6):nth-last-child(7),body .invoice_items .table-body li>*:nth-child(7):nth-last-child(6),body .invoice_items .table-body li>*:nth-child(8):nth-last-child(5),body .invoice_items .table-body li>*:nth-child(9):nth-last-child(4),body .invoice_items .table-body li>*:nth-child(10):nth-last-child(3),body .invoice_items .table-body li>*:nth-child(11):nth-last-child(2),body .invoice_items .table-body li>*:nth-child(12):nth-last-child(1){width:calc(8.3333% - 30px);padding:10px 15px}"+
	"body .invoice_items .table-heading{border-bottom:1px solid lightgrey;min-width:1000px;width:calc(100% - 15px)}"+
	"body .invoice_items .table-heading>*{font-weight:400}"+
	"body .invoice_items .table-heading>*.sortable{cursor:pointer}"+
	"body .invoice_items .table-body{min-width:1000px;padding-right:15px}"+
	"body .invoice_items .table-body li{border:1px solid lightgrey;margin-bottom:10px;width:calc(100% - 2px);position:relative}"+
	"body .invoice_items .table-body li:nth-child(odd){background-color:#f4f4f4}"+
	"body .invoice_items .table-body li span.play,body .invoice_items .table-body li span.read{padding:10px 20px 10px 0px;cursor:pointer;color:#f26658}"+
	"body .invoice_items .table-body li span.play:hover,body .invoice_items .table-body li span.read:hover{color:#bed12f}"+
	"body .invoice_items .table-body li.row-totals{font-weight:900}"+
	"body .invoice_items .table-body li img{display:block;height:75px}"+
	"body .invoice_items .table-body li .call-window{position:absolute;z-index:999999;right:15%;width:auto;background:white;border:1px solid #334259;top:-14px;padding:10px 15px 8px;border-radius:10px}"+
	"body .invoice_items .table-body li .call-window i{font-size:20px;margin-left:15px;line-height:57px;vertical-align:top}"+
	"body .invoice_items .table-body li .call-window i:hover{color:#bed12f;cursor:pointer}"+
	"body .invoice_totals{margin-top:10px;font-weight:900;line-height:25px;text-align:right}"+
	"body .invoice_totals .value{display:inline-block;width:120px}"+
"</style>";