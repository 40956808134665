import React from 'react';
import Chart from 'chart.js/auto';

import { Breakdown, ReportHeading, Statistic } from '../../helpers/uiComponents';
import { numberToLocal } from '../../helpers/uiFunctions';
import { chartColors } from '../../helpers/uiHelpers';
import { loadGoogleMyBusiness } from '../data/supermetricsService';

class GoogleMyBusiness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			loading: true,
            dailyLoading: true,
            sortVal: 0,
        };
    }
    componentDidMount() {
        console.log("gmb did mount")
        loadGoogleMyBusiness( //load quick stats
            ["location_address_lines","views_search","views_total","actions_total","actions_website","actions_phone","actions_driving_directions"],
            1000,
            null,
            null,
            this.props.endpoints,
            [this.props.dates[0], this.props.dates[1],],
            this.props.currentClient.clientCode,
            localStorage.getItem('accessToken')
        ).then(response => {
            console.log("gmb response", response);
            if (response.success === false) {
                this.setState({
                    loading: false,
                    errorMessage: response.message,
                });
            }
            else {
                response.result.data.shift();
                var totals = response.result.data.reduce((r, a) => a.map((b, i) => (r[i] || 0) + b), []);
                totals.shift();
    
                this.setState({
                    loading: false,
                    errorMessage: null,
                    data: response.result.data,
                    totals: totals,
                });
                setTimeout(() => {
                    this.buildChart("Search Impressions By Location", response.result.data, 1, 'graph-impressions')
                    this.buildChart("Views By Location", response.result.data, 2, 'graph-views')
                    this.buildChart("Actions By Location", response.result.data, 3, 'graph-actions')
                }, 500);
            }
        });
        loadGoogleMyBusiness( // load daily stats
            ["date","views_search","views_total","actions_total"],
            1000,
            null,
            null,
            this.props.endpoints,
            [this.props.dates[0], this.props.dates[1],],
            this.props.currentClient.clientCode,
            localStorage.getItem('accessToken')
        ).then(response => {
            console.log("gmb daily response", response);
            if (response.success === false) {
                this.setState({
                    loading: false,
                    errorMessage: response.message,
                });
            }
            else {
                response.result.data.shift();
                this.setState({
                    daily: response.result.data,
                    dailyLoading: false,
                });
                setTimeout(() => {
                    this.buildDailyChart("Total Daily Search Impressions", response.result.data, 1, 'graph-dailyImpressions')
                    this.buildDailyChart("Total Daily Views", response.result.data, 2, 'graph-dailyViews')
                    this.buildDailyChart("Total Daily Actions", response.result.data, 3, 'graph-dailyActions')
                }, 500);
            }
        });
    }

    buildChart(title, inputData, index, elemId) {
		if (inputData) {
			var data = [];
			var options = [...new Set(inputData.map(x => x[0]))];

            for (var i = inputData.length - 1; i >= 0; i--) {
				data.push(inputData[i][index]);
			}
			data.reverse(); //options.reverse();

			var ctx = document.getElementById(elemId);
			new Chart(ctx, {
				"type":"doughnut",
				"data": {
					"labels":options,
					"datasets":[
						{
							"data":data,
							"backgroundColor": chartColors,
						}
					]
				},
				options: {
                    plugins: {
                        maintainAspectRatio: false,
                        aspectRatio: 1,
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: title,
                            fontSize: 16,
                        },
                    },
                    layout: {
                        padding: {
                            left: 50,
                            right: 50,
                            top: 25,
                            bottom: 25
                        }
                    },
					animations: {
						linear: {
							duration: 1000,
							easing: 'linear',
							from: 1,
							to: 0,
							loop: true
						}
					},
				}
			});
		}
	}
    buildDailyChart(title, inputData, index, elemId) {
		if (inputData) {
			var data = [];
			var options = [...new Set(inputData.map(x => x[0]))];
            
            //console.log(data);
            for (var i = inputData.length - 1; i >= 0; i--) {
				data.push(inputData[i][index]);
			}
			data.reverse(); //options.reverse();

			var ctx = document.getElementById(elemId);
			new Chart(ctx, {
				"type":"line",
				"data": {
					"labels":options,
					"datasets":[
						{
							data:data,
							backgroundColor: "rgba(72, 152, 187, 0.3)",
							borderColor: "rgba(72, 152, 187, 1)",
							pointBackgroundColor: "rgba(190, 209, 47, 1)",
							pointRadius: 5,
							fill: "start",
						}
					]
				},
				options: {
                    plugins: {
                        aspectRatio: 1,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: title,
                            fontSize: 16,
                        },
                    },
                    layout: {
                        padding: {
                            left: 50,
                            right: 50,
                            top: 25,
                            bottom: 25
                        }
                    },
                    scales: {
						xAxes: [{
							stacked: true,
						}],
						yAxes: [{
							stacked: true
						}]
					},
					animations: {
						linear: {
							duration: 1000,
							easing: 'linear',
							from: 1,
							to: 0,
							loop: true
						}
					},
				}
			});
		}
	}
    toggleTabs(index) {
        var tabs = document.querySelectorAll(".tab-menu > .tab");
        var body = document.querySelectorAll(".tab-body > .tab");
        Object.entries(tabs).forEach(([tabs, tab]) => {
            tab.classList.remove("active");
            if(tab.classList.contains("tab-"+index)) {
                tab.classList.add("active");
            }
        });
        Object.entries(body).forEach(([body, tab]) => {
            tab.classList.remove("active");
            if(tab.classList.contains("tab-"+index)) {
                tab.classList.add("active");
            }
        });
    }

    render() {
        const { loading, dailyLoading, errorMessage, data, totals } = this.state;
        const hideHistorical = this.props.hideHistorical;

        return(
            <div id="metrics-gmb" className="report metrics-gmb">
                <ReportHeading
                    title="Google My Business"
                    name={this.props.currentClient.name}
					dates={this.props.dates}
					hideHistorical={hideHistorical}
                />
                {loading === true && (
                    <React.Fragment>
                        <h3>Fetching your Google My Business Statistics...</h3>
                        <div className="report-loading">
                            <p><i className="fas fa-circle-notch"></i></p>
                        </div>
                    </React.Fragment>
                )}
                {errorMessage ? (
                    <React.Fragment>
                        <h3>We were unable to fetch your Google My Business Statistics.</h3>
                        <p>There may have been an error connecting to Google, or Google My Business is not configured for your Portal.</p>
                        <p>Please contact your Account Manager for assistance.</p>
                    </React.Fragment>
                ) : (
                    (data) && (
                        <React.Fragment>
                            <section className="stats row">
                                <Statistic
                                    label="Total Search Impressions"
                                    value={numberToLocal(totals[0])}
                                    hideHistorical={hideHistorical}
                                />
                                <Statistic
                                    label="Total Views"
                                    value={numberToLocal(totals[1])}
                                    hideHistorical={hideHistorical}
                                />
                                <Statistic
                                    label="Total Actions"
                                    value={numberToLocal(totals[2])}
                                    hideHistorical={hideHistorical}
                                />
                            </section>

                            <div className="tab-menu">
                                <div onClick={() => this.toggleTabs(1)} className="tab tab-1 active">Impressions</div>
                                <div onClick={() => this.toggleTabs(2)} className="tab tab-2">Views</div>
                                <div onClick={() => this.toggleTabs(3)} className="tab tab-3">Actions</div>
                            </div>
                            <div className="tab-body">
                                <div className="tab tab-1 active">
                                    <h4><strong>Total Search Impressions</strong> - The number of times users have found your business listing via Google Search</h4>
                                    <section className="stats-graph">
                                        <div className="graph wide">
                                            <div className="graph-container">
                                                {(dailyLoading) && (
                                                    <div className="report-loading small">
                                                        <p><i className="fas fa-circle-notch"></i></p>
                                                    </div>
                                                )}
                                                <canvas className="chart" id="graph-dailyImpressions" />
                                            </div>
                                        </div>
                                    </section>
                                    <section className="stats-graph">
                                        <div className="graph">
                                            <div className="graph-container">
                                                <canvas className="chart doughnut" id="graph-impressions" />
                                            </div>
                                        </div>
                                        <Breakdown
                                            data={data}
                                            title={"Search Impressions By Location"}
                                            format={"wide"}
                                            dataIndex={1}
                                        />
                                    </section>
                                </div>
                                <div className="tab tab-2">
                                    <h4><strong>Total Views</strong> - The number of times users have viewed our business listing</h4>
                                    <section className="stats-graph">
                                        <div className="graph wide">
                                            <div className="graph-container">
                                                <canvas className="chart" id="graph-dailyViews" />
                                            </div>
                                        </div>
                                    </section>
                                    <section className="stats-graph">
                                        <div className="graph">
                                            <div className="graph-container">
                                                <canvas className="chart doughnut" id="graph-views" />
                                            </div>
                                        </div>
                                        <Breakdown
                                            data={data}
                                            title={"Views By Location"}
                                            format={"wide"}
                                            dataIndex={2}
                                        />
                                    </section>
                                </div>
                                <div className="tab tab-3">
                                    <h4><strong>Total Actions</strong> - The number of times users have taken an action on your business listing (phone call, get directions, visited website, etc.)</h4>
                                    <section className="stats-graph">
                                        <div className="graph wide">
                                            <div className="graph-container">
                                                <canvas className="chart" id="graph-dailyActions" />
                                            </div>
                                        </div>
                                    </section>
                                    <section className="stats-graph">
                                        <div className="graph">
                                            <div className="graph-container">
                                                <canvas className="chart doughnut" id="graph-actions" />
                                            </div>
                                        </div>
                                        <Breakdown
                                            data={data}
                                            title={"Actions By Location"}
                                            format={"wide"}
                                            dataIndex={3}
                                        />
                                    </section>
                                </div>
                            </div>
                            
                            <h3>All Metric Totals By Location</h3>
                            <section className="calls-list gmb-list">
                                <div className="table-heading">
                                    <div>Listing Address</div>
                                    <div>Total Search Impressions</div>
                                    <div>Total Views</div>
                                    <div>Total Actions</div>
                                    <div>Action:<br/> Website Visits</div>
                                    <div>Action:<br/> Phone Calls</div>
                                    <div>Action:<br/> Directions</div>
                                </div>
                                <ul className="table-body no-hover">
                                    {data.map((stat, index) =>
                                        <li key={index} >
                                            <div>{stat[0]}</div>
                                            <div>{numberToLocal(stat[1])}</div>
                                            <div>{numberToLocal(stat[2])}</div>
                                            <div>{numberToLocal(stat[3])}</div>
                                            <div>{numberToLocal(stat[4])}</div>
                                            <div>{numberToLocal(stat[5])}</div>
                                            <div>{numberToLocal(stat[6])}</div>
                                        </li>
                                    )}
                                </ul>
                            </section>
                        </React.Fragment>
                    )
                )}
            </div>
        )
    }
}
export default GoogleMyBusiness;