
export const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
export const mon = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

export const currentMonth = months[new Date().getMonth()];

export const errorMessage = {"message": "An Error Occured While Fetching Your Data, Please Try Again Shortly"};

export const managers = [
	{ "name": "Alex Yarbrough", "value": "Alex Yarbrough"},
	{ "name": "Amanda Mincey", "value": "Amanda Mincey"},
	{ "name": "Carlee Padgett", "value": "Carlee Padgett"},
	{ "name": "Carole Cross", "value": "Carole Cross"},
	{ "name": "Christine Dugan", "value": "Christine Dugan"},
	{ "name": "Cristy Ponder", "value": "Cristy Ponder"},
	{ "name": "David Geipel", "value": "David Geipel"},
	{ "name": "Gavin Milligan", "value": "Gavin Milligan"},
	{ "name": "Jamie Hamilton", "value": "Jamie Hamilton"},
	{ "name": "Justice Welshans", "value": "Justice Welshans"},
	{ "name": "Kensli Reed", "value": "Kensli Reed"},
	{ "name": "Konte Stokes", "value": "Konte Stokes"},
	{ "name": "Peter Crowell", "value": "Peter Crowell" },
	{ "name": "Sandi Griffith", "value": "Sandi Griffith" },
	{ "name": "Teresa Robinson", "value": "Teresa Robinson" },
	{ "name": "Sassy Hackett", "value": "Sassy Hackett" }
];

export const testLoginExpires = () => {
	let expires = localStorage.getItem('expires');
	let token = localStorage.getItem('accessToken');
	let now = Date.now();

	if (expires < now) {
		// console.log("expired");
		localStorage.clear();
		window.location.reload();
	} else if (!token) {
		// console.log("no token");
		localStorage.clear();
		window.location.reload();
	} else {
		// console.log("valid");
	}
}

export const chartColors = [
	"rgb(242,102,88)", //statOrange
	"rgb(250,121,2)", //darkOrange
	"rgb(190,209,47)", // statYellowGreen
	"rgb(69,202,138)", //seagreen
	"rgb(51,66,89)", //statDarkBlue
	"rgb(72,152,187)", //lightBlue
	"rgb(2,182,197)", //statTeal
	"rgb(24,83,209)", //royalBlue
	"rgb(145,90,214)", //mediumPurple
	"rgb(126,73,96)", //statPurple
	// repeat this list of 10 colors for brackdowns with more results
	"rgb(242,102,88)", //statOrange
	"rgb(250,121,2)", //darkOrange
	"rgb(190,209,47)", // statYellowGreen
	"rgb(69,202,138)", //seagreen
	"rgb(51,66,89)", //statDarkBlue
	"rgb(72,152,187)", //lightBlue
	"rgb(2,182,197)", //statTeal
	"rgb(24,83,209)", //royalBlue
	"rgb(145,90,214)", //mediumPurple
	"rgb(126,73,96)", //statPurple
	// repeat
	"rgb(242,102,88)", //statOrange
	"rgb(250,121,2)", //darkOrange
	"rgb(190,209,47)", // statYellowGreen
	"rgb(69,202,138)", //seagreen
	"rgb(51,66,89)", //statDarkBlue
	"rgb(72,152,187)", //lightBlue
	"rgb(2,182,197)", //statTeal
	"rgb(24,83,209)", //royalBlue
	"rgb(145,90,214)", //mediumPurple
	"rgb(126,73,96)", //statPurple
	// repeat
	"rgb(242,102,88)", //statOrange
	"rgb(250,121,2)", //darkOrange
	"rgb(190,209,47)", // statYellowGreen
	"rgb(69,202,138)", //seagreen
	"rgb(51,66,89)", //statDarkBlue
	"rgb(72,152,187)", //lightBlue
	"rgb(2,182,197)", //statTeal
	"rgb(24,83,209)", //royalBlue
	"rgb(145,90,214)", //mediumPurple
	"rgb(126,73,96)", //statPurple
	// repeat
	"rgb(242,102,88)", //statOrange
	"rgb(250,121,2)", //darkOrange
	"rgb(190,209,47)", // statYellowGreen
	"rgb(69,202,138)", //seagreen
	"rgb(51,66,89)", //statDarkBlue
	"rgb(72,152,187)", //lightBlue
	"rgb(2,182,197)", //statTeal
	"rgb(24,83,209)", //royalBlue
	"rgb(145,90,214)", //mediumPurple
	"rgb(126,73,96)", //statPurple
];