import React from 'react';

import { Statistic, ReportHeading } from '../../helpers/uiComponents';
import { getPercentChange, numberToLocal, numberToPercent } from '../../helpers/uiFunctions';

class EmailSends extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
			stats1: [],
			promos1: [],
			stats2: [],
			error: "Loading...",
        };
    }
	componentDidMount() {
		try {
			if (this.props.stats) {
				this.handleData(this.props.stats);
			}
		} catch (error) {
			console.log(error);
		}
	}
    componentDidUpdate(prevProps) {
		try {
			if (this.props.stats && this.props.stats !== prevProps.stats) {
				this.handleData(this.props.stats);
			}
		} catch (error) {
			console.log(error);
		}
		
    }

	handleData(data) {
		if (data.success) {
			this.setState({
				stats1: data.result[0],
				promos1: data.result[0].promos,
				stats2: data.result[2],
				error: null,
			});
		} else if (!data.success) {
			this.setState({
				stats1: [],
				promos1: [],
				stats2: [],
				error: "No Email Send Data Found",
			});
		}
	}

    viewEvents(i) {
		console.log(i)
    	var elem = document.getElementById("promo"+i)
    	elem.classList.toggle("active");
    }

// chart stuff here

    render() {
		const { stats1, promos1, stats2, error } = this.state;
		const hideHistorical = this.props.hideHistorical;

		const renderPromos = promos1.map((promo, index) => {
			//console.log(promo, index);

			if (promo.events.length > 1) {
				//console.log("more than one event");
			} else {
				//console.log("one event or less");
			}

			return (
				<React.Fragment key={promo.name}>
					<li key={promo.name} onClick={() => this.viewEvents(index)}>
						<div>{promo.name}</div>
						<div>{promo.delivered}</div>
						<div>{numberToPercent((promo.opened/promo.delivered)*100)} ({promo.opened})</div>
						<div>{numberToPercent((promo.clicked/promo.opened)*100)} ({promo.clicked})</div>
						<div>{promo.bounced}</div>
						<div>{promo.deferred}</div>
						<div>{promo.dropped}</div>
						<div>{numberToPercent((promo.unsubscribed/promo.delivered)*100)} ({promo.unsubscribed} )</div>
						<div>{promo.spamreport}</div>
					</li>
				
					{(promo.events.length > 0) && (
						<div id={"promo"+index} className="email-events">
							<h4><div>{promo.name}</div></h4>
							{promo.events.map((event, index) => {
								return(
									<li key={event.eventId}>
										{(event.name !== null && event.name !== "" ) ? (
											<div>{event.name}</div>
										):(
											<div>Email {index + 1}</div>
										)}
										<div>{event.delivered}</div>
										<div>{numberToPercent((event.opened/event.delivered)*100)} ({event.opened})</div>
										<div>{numberToPercent((event.clicked/event.opened)*100)} ({event.clicked})</div>
										<div>{event.bounced}</div>
										<div>{event.deferred}</div>
										<div>{event.dropped}</div>
										<div>{numberToPercent((event.unsubscribed/event.delivered)*100)} ({event.unsubscribed} )</div>
										<div>{event.spamreport}</div>
									</li>
								)
							})}
						</div>
					)}
				</React.Fragment>
			);
		});

		return (
			<div id="metrics-emails" className="report metrics-emails">
				<ReportHeading
					title="Promotional Emails"
					name={this.props.currentClient.name}
					dates={this.props.dates}
					hideHistorical={hideHistorical}
				/>
				{(error) ? (
					<React.Fragment>
						<h3>Fetching your Promotion Email Statistics...</h3>
		    			<div className="report-loading">
		    				<p><i className="fas fa-circle-notch"></i></p>
		    			</div>
		    		</React.Fragment>
				) : (
					<React.Fragment>
						<section className="stats row">
							<Statistic
								label="Emails Sent"
								value={numberToLocal(stats1.delivered)}
								status={getPercentChange(stats1.delivered, stats2.delivered)[1]}
								percent={getPercentChange(stats1.delivered, stats2.delivered)[0]}
								hideHistorical={hideHistorical}
							/>
							<Statistic
								label="Open Rate (Count)"
								value={numberToPercent((stats1.opened/stats1.delivered)*100)+" ("+numberToLocal(stats1.opened)+")"}
								status={getPercentChange(stats1.opened, stats2.opened)[1]}
								percent={getPercentChange(stats1.opened, stats2.opened)[0]}
								hideHistorical={hideHistorical}
							/>
							<Statistic
								label="Click Through Rate (Count)"
								value={numberToPercent((stats1.clicked/stats1.opened)*100)+" ("+numberToLocal(stats1.clicked)+")"}
								status={getPercentChange(stats1.clicked, stats2.clicked)[1]}
								percent={getPercentChange(stats1.clicked, stats2.clicked)[0]}
								hideHistorical={hideHistorical}
							/>
							<Statistic
								label="Opt Outs"
								value={numberToLocal(stats1.unsubscribed)}
								status={getPercentChange(stats1.unsubscribed, stats2.unsubscribed)[1]}
								percent={getPercentChange(stats1.unsubscribed, stats2.unsubscribed)[0]}
								hideHistorical={hideHistorical}
							/>
						</section>

						<section className="calls-list">
							<div className="table-heading">
								<div>Promotion</div>
								<div>Delivered</div>
								<div>Open Rate (Count)</div>
								<div>Clicked Through Rate (Count)</div>
								<div>Bounced</div>
								<div>Deferred</div>
								<div>Dropped</div>
								<div>Unsubscribe Rate (Count)</div>
								<div>Spam Report</div>
							</div>
							<ul className="table-body">
								{renderPromos}
								<li className="row-totals">
									<div>Total</div>
									<div>{stats1.delivered}</div>
									<div>{numberToPercent(stats1.opened/stats1.delivered)} ({stats1.opened})</div>
									<div>{numberToPercent(stats1.clicked/stats1.opened)} ({stats1.clicked})</div>
									<div>{stats1.bounced}</div>
									<div>{stats1.deferred}</div>
									<div>{stats1.dropped}</div>
									<div>{numberToPercent(stats1.unsubscribed/stats1.delivered)} ({stats1.unsubscribed} )</div>
									<div>{stats1.spamreport}</div>
								</li>
							</ul>
						</section>
					</React.Fragment>
				)}
			</div>
		)
	}

}
export default EmailSends;