import React from 'react';
import Chart from 'chart.js/auto';

import { ReportHeading, Statistic } from '../../helpers/uiComponents';
import { numberToLocal } from '../../helpers/uiFunctions';
import { chartColors, errorMessage } from '../../helpers/uiHelpers';
import { loadGoogleMyBusiness } from '../data/supermetricsService';

class GoogleRatingsReviews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quickStats: [[],[]],
            chartsLoading: true,
            loading: true,
            errorMessage: null,
            tableHeadings: ["Review Date", "Location", "Reviewer Name", "Star Rating", "Comment", "Reply"],
        }
    }
    componentDidMount() {
        loadGoogleMyBusiness(
            ["total_review_star_rating", "total_review_count", "review_avg_star_rating", "review_total_count",],
            10,
            null,
            {"blanks_to_zero": true},
            this.props.endpoints,this.props.dates,this.props.currentClient.clientCode,this.props.accessToken
        ).then(response => {
            if (response.success) {
                this.setState({
                    loading: false,
                    quickStats: response.result.data,
                });
            } else {
                console.log(errorMessage);
                this.setState({
                    loading: false,
                    errorMessage: errorMessage.message,
                });
            }
            
        });
        loadGoogleMyBusiness(
            ["location_address_lines","total_review_star_rating","total_review_count","review_avg_star_rating","review_total_count",],
            50,
            ["location_address_lines asc"],
            null,
            this.props.endpoints,this.props.dates,this.props.currentClient.clientCode,this.props.accessToken
        ).then(response => {
            if (response.success) {
                this.setState({
                    chartsLoading: false,
                    loading: false,
                });
                let data = response.result.data;
                data.shift();
                setTimeout(() => {
                    this.buildChart("Star Rating Avg. - All Time", data, 1, 'graph-');
                    this.buildChart("Reviews - All Time", data, 2, 'graph-');
                    this.buildChart("Star Rating Avg. - This Period", data, 3, 'graph-');
                    this.buildChart("Reviews - This Period", data, 4, 'graph-');
                }, 100);
            } else {
                this.setState({
                    loading: false,
                    errorMessage: errorMessage.message,
                });
            }
        });
        loadGoogleMyBusiness(
            ["review_create_date","location_address_lines","review_reviewer_name","review_star_rating","review_comment","review_reply_comment",],
            10,
            ["review_create_date desc"],
            {"timezone": "UTC"},
            this.props.endpoints,this.props.dates,this.props.currentClient.clientCode,this.props.accessToken
        ).then(response => {
            if (response.success) {
                // console.log(response);
                let data = response.result.data
                data.shift()
                //console.log(data);
                this.setState({
                    loading: false,
                    tableData: data,
                });
            } else {
                this.setState({
                    loading: false,
                    error: errorMessage.message,
                });
            }
        });

    }
    buildChart(title, inputData, index, elemId) {
        var options = [...new Set(inputData.map(x => x[0]))];
        var data = [];
        for (var i = inputData.length - 1; i >= 0; i--) {
            data.push(inputData[i][index]);
        }
        data.reverse(); //options.reverse();
        var ctx = document.getElementById(elemId+index);
        new Chart(ctx, {
            "type":"bar",
            "data": {
                "labels":options,
                "datasets":[
                    {
                        "data":data,
                        "backgroundColor": chartColors[index],
                    }
                ]
            },
            options: {
                plugins: {
                    maintainAspectRatio: false,
                    aspectRatio: 1,
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: title,
                        fontSize: 16,
                    },
                },
                layout: {
                    padding: {
                        left: 50,
                        right: 50,
                        top: 25,
                        bottom: 25
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                },
                animations: {
                    linear: {
                        duration: 1000,
                        easing: 'linear',
                        from: 1,
                        to: 0,
                        loop: true
                    }
                },
            }
        });
    }
    view(a) {
    	var elem = document.getElementById("review-"+a)
    	elem.classList.toggle("active");
    }
    render() {
        const {quickStats, tableData, tableHeadings, chartsLoading, loading, errorMessage} = this.state
        return(
            <div id="metrics-googleRatings" className="report metrics-googleRatings">
                <ReportHeading
                    title="Google Ratings & Reviews"
                    name={this.props.currentClient.name}
                    dates={this.props.dates}
                    hideHistorical={this.props.hideHistorical}
                />
                {loading && (
                    <React.Fragment>
                        <h3>Fetching your Google Ratings...</h3>
                        <div className="report-loading">
                            <p><i className="fas fa-circle-notch"></i></p>
                        </div>
                    </React.Fragment>
                )}
                {(errorMessage) && (
                    <p>{errorMessage}</p>
                )}
                {(!loading && !errorMessage) && (
                    <React.Fragment>
                        <section className="stats row">
                            <Statistic
                                label={"Star Rating Avg. - This Period"}
                                value={(quickStats[1][0]) ? numberToLocal(quickStats[1][2]) : ("Loading...")}
                                hideHistorical={this.props.hideHistorical}
                            />
                            <Statistic
                                label={"Reviews - This Period"}
                                value={(quickStats[1][0]) ? numberToLocal(quickStats[1][3]) : ("Loading...")}
                                hideHistorical={this.props.hideHistorical}
                            />
                            <Statistic
                                label={"Star Rating Avg. - All Time"}
                                value={(quickStats[1][0]) ? numberToLocal(quickStats[1][0]) : ("Loading...")}
                                hideHistorical={this.props.hideHistorical}
                            />
                            <Statistic
                                label={"Reviews - All Time"}
                                value={(quickStats[1][0]) ? numberToLocal(quickStats[1][1]) : ("Loading...")}
                                hideHistorical={this.props.hideHistorical}
                            />
                        </section>


                        <section className="stats-graph">
                            <div className="graph">
                                <div className="graph-container">
                                    {(chartsLoading) && (
                                        <div className="report-loading small">
                                            <p><i className="fas fa-circle-notch"></i></p>
                                        </div>
                                    )}
                                    <canvas className="chart" id="graph-3" />
                                </div>
                                <p className="description">The avg. star rating you have received from reviews to your Google Business listing(s) during the date range you have selected.</p>
                            </div>
                            <div className="graph">
                                <div className="graph-container">
                                    {(chartsLoading) && (
                                        <div className="report-loading small">
                                            <p><i className="fas fa-circle-notch"></i></p>
                                        </div>
                                    )}
                                    <canvas className="chart" id="graph-4" />
                                </div>
                                <p className="description">The total number of reviews you have received to your Google Business listing(s) during the date range you have selected.</p>
                            </div>
                        </section>
                        <section className="stats-graph">
                            <div className="graph">
                                <div className="graph-container">
                                    {(chartsLoading) && (
                                        <div className="report-loading small">
                                            <p><i className="fas fa-circle-notch"></i></p>
                                        </div>
                                    )}
                                    <canvas className="chart" id="graph-1" />
                                </div>
                                <p className="description">Your avg. star rating throughout the lifetime of your Google Business listing(s). This will always be current, no matter the date range you select.</p>
                            </div>
                            <div className="graph">
                                <div className="graph-container">
                                    {(chartsLoading) && (
                                        <div className="report-loading small">
                                            <p><i className="fas fa-circle-notch"></i></p>
                                        </div>
                                    )}
                                    <canvas className="chart" id="graph-2" />
                                </div>
                                <p className="description">The total number of reviews you have gained throughout the lifetime of your Google Business listing(s). This will always be current, no matter the date range you select.</p>
                            </div>
                        </section>
                        

                        <section className="calls-list">
                            <h5 style={{"textAlign":"center"}}>Recent Reviews</h5>
                            {(tableHeadings) ? (
                                <div className="table-heading">
                                    {tableHeadings.map((heading, index) =>
                                        <div key={index}>{heading}</div>
                                    )}
                                </div>
                            ) : (
                                <React.Fragment>
                                    <div className="report-loading small">
                                        <p><i className="fas fa-circle-notch"></i></p>
                                    </div>
                                </React.Fragment>
                            )}
                            {(tableData) && (
                                <ul className="table-body">
                                    {tableData.map((record, index) =>
                                        <React.Fragment key={index}>
                                            <li onClick={() => this.view(index)}>
                                                {record.map((field, index) =>
                                                    (index > 3) ? (
                                                        <div key={index}>{field.substring(0, 48)+"..."}</div>
                                                    ) : (
                                                        <div key={index}>{field}</div>
                                                    )
                                                )}
                                            </li>
                                            <div id={"review-"+index} className="email-events">
                                                <div className="columns">
                                                    <div>
                                                        <h4>{record[2]} - {record[0]}</h4>
                                                        <p>{record[3]}</p>
                                                        <p>{record[4]}</p>
                                                    </div>
                                                    <div>
                                                        <h4>Reply</h4>
                                                        <p>{record[5]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </ul>
                            )}
                        </section>
                    </React.Fragment>
                )}
            </div>
        )
    }
}
export default GoogleRatingsReviews;