import React from 'react';

export class Invoicing extends React.Component {

	render() {
		return (
			<ul>
				<li>Invoices are produced at the beginning of the month for the following month.  This practice allows for invoicing and payment before advertising and website maintenance charges are incurred by Mobile Marketing.</li>
				<li>Additional advertising can be requested at any time.  Charges will be invoiced as a supplemental invoice or placed on the next monthly invoice, depending on the start date of the additional advertising.</li>
				<li>Additional site features, as requested, are billed on a client’s next monthly invoice.  Examples include email renewals, email mailbox size increases, domain charges, etc.</li>
				<li>All invoice terms are NET 15.</li>
			</ul>
		)
	}

}