import { basicGET, basicJSONPOST } from "../../api/fetchHelpers";

export async function getAccountId(endpoints, clientCode, type, token) {
    const response = await fetch(endpoints.serviceClients+clientCode+"/credential/type/"+type, basicGET(token));
    if (!response.ok) {
        const message = 'An error has occured: '+response.status;
        throw new Error(message);
    }
    const data = await response.json();
    return data;
}
export async function getSocialAccountsByGroup(endpoints, clientCode, group, token) {
    const response = await fetch(endpoints.serviceClients+clientCode+"/credential/group/"+group, basicGET(token));
    if (!response.ok) {
        const message = 'An error has occured: '+response.status;
        throw new Error(message);
    }
    const data = await response.json();
    return data;
}
export async function loadFBtotals(endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "fbAccount", token);
    // console.log(account);
    if (account.success === false || !account.result[0].accountId) {
        return {success: false}
    } else {
        var accountIDs = account.result[0].accountId.split(',');
        for (let i = 0; i < accountIDs.length; i++) {
            accountIDs[i] = "act_"+accountIDs[i];
        }
        console.log(accountIDs);
        let body = {
            "ds_id": "FA",
            "ds_accounts": accountIDs,
            "ds_user": "116844288836302",
            "start_date": dates[0],
            "end_date": dates[1],
            "fields": [
                "cost",
                "impressions",
                "reach",
                "Clicks",
                "onsite_conversion.lead_grouped",
                "UCPC",
                "cost_per_lead_form",
            ],
            "settings": {
                "blanks_to_zero": true,
            },
            "max_rows": 1000
        }
        const response = await fetch(endpoints.serviceMetrics+"get", basicJSONPOST(body));
        console.log(response);
        if (!response.ok) {
            const message = 'An error has occured: '+response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }  
}
export async function loadFBaccounts(endpoints, dates, clientCode, token) {
    var account = await getAccountId(endpoints, clientCode, "fbAccount", token);
    // console.log(account);
    if (account.success === false || !account.result[0].accountId) {
        return {success: false}
    } else {
        var accountIDs = account.result[0].accountId.split(',');
        for (let i = 0; i < accountIDs.length; i++) {
            accountIDs[i] = "act_"+accountIDs[i];
        }
        // console.log(accountIDs);
        let body = {
            "ds_id": "FA",
            "ds_accounts": accountIDs,
            "ds_user": "116844288836302",
            "start_date": dates[0],
            "end_date": dates[1],
            "fields": [
                "profileID",
                "profile",
                "adcampaign_name",
                "cost",
                "impressions",
                "reach",
                "Clicks",
                "onsite_conversion.lead_grouped",
                "UCPC",
                "cost_per_lead_form",
                "conversions_ad_click_mobile_app",
                "action_link_click",
                "action_post_like",
                "action_comment",
                "action_post",
                "unique_action_video_play",
                "creative_title",
                "creative_body",
                "mobile_feed_preview_url",
            ],
            "settings": {
                "blanks_to_zero": true,
            },
            "max_rows": 1000
        }
        const response = await fetch(endpoints.serviceMetrics+"get", basicJSONPOST(body));
        if (!response.ok) {
            const message = 'An error has occured: '+response.status;
            throw new Error(message);
        }
        const data = await response.json();
        return data;
    }  
}