export const tokenParams = (user, pass) => {
	var params = {
		headers: {'Content-Type':'application/x-www-form-urlencoded'},
	    method: "POST",
	    referrer: "no-referrer",
	    body: 'grant_type=client_credentials&client_id='+user+'&client_secret='+pass,
	}
	return params;
}

export const basicGET = () => {
	var params = {
		headers: {
			"authorization": "bearer " + localStorage.getItem("accessToken"),
		},
		method: "GET",
	}
	return params;
}

export const basicJSONPOST = (body) => {
	var params = {
		headers: {
			"authorization": "bearer " + localStorage.getItem("accessToken"),
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
		method: "POST",
	}
	return params;
}

export const basicJSONDELETE = (body) => {
	var params = {
		headers: {
			"authorization": "bearer " + localStorage.getItem("accessToken"),
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
		method: "DELETE",
	}
	return params;
}