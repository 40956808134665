import React from 'react';

import * as Api from './api/endpoints';
import * as Data from './components/data/data';
import { loadRoomvoDailyViews, loadRoomvoQuickStats, loadRoomvoTopEvent, loadRoomvoTopEventGA4 } from './components/data/supermetricsService';
import { tokenParams, basicGET, basicJSONPOST } from './api/fetchHelpers';

import Header from './components/header/header';
import Navigation from './components/nav/nav';
import Dash from './components/dashboard/dash';
import Analytics from './components/metrics/analytics';
import GA4Analytics from './components/metrics/ga4analytics';
import Adwords from './components/metrics/adwords';
import LeadTracking from './components/metrics/leadTracking';
import LeadReports from './components/metrics/leadChannels';
import FormReports from './components/metrics/formSubmissions';
import CallTracking from './components/metrics/callTracking';
import EmailSends from './components/metrics/emailSends';
import SocialFacebook from './components/metrics/facebook';
import RugsShopOrders from './components/metrics/rugsShop';
import GetStats from './components/metrics/getStats';
import Blogs from './components/content/blogs.js';
import ProgramDetails from './components/programDetails/programDetails';
import Footer from './components/footer/footer';

import { InputRow } from './helpers/inputRow';
import { getCallAverageTime, getUrlParameter, checkStringMatch, getQuickRange, getstatRange } from './helpers/uiFunctions';
import { errorMessage } from './helpers/uiHelpers';
import GoogleMyBusiness from './components/metrics/googleMyBusiness';
import { testLoginExpires } from './components/data/serviceSso';
import Roomvo from './components/metrics/roomvo';
import GoogleRatingsReviews from './components/metrics/googleRatings';
import SearchConsole from './components/metrics/searchConsole';



class App extends React.Component {
    constructor(props) {
        super(props);
        var dates;
        if (localStorage.getItem('dates')) {
            dates = localStorage.getItem('dates');
            dates = dates.split(",");
        } else {
            dates = getQuickRange("month");
            localStorage.setItem('dates', dates);
        }
        
        var getstatDates = getstatRange();

        this.state = {
            dates: dates,
            reportStart: dates[0],
            reportEnd: dates[1],
            prevReportStart: dates[2],
            prevReportEnd: dates[3],
            getStatDates: getstatDates,
            hideHistorical: false,

            isLoggedIn: false,
            accessToken: null,
            forgotPass: false,

            roles: [],
            endpoints: [],
            currentClient: null,
            isRetailer: false,
            affiliateCode: null,
            retailer: null,
            retailerLeadList: null,
            retailerCallsList: null,
            retailerAdwordsList: null,
            retailerFbList: null,

            leads: null,
            retailerLeads: null,
            retailerForms: null,
            calls: null,
            emailStats: null,
            getStats: null,
            roomvoQuickStats: null,
            roomvoDailyViews: null,
            roomvoTopRooms: null,
            roomvoTopSku: null,
            rugOrders: null,

            oldLeads: null,
            oldRetailerLeads: null,
            oldForms: null,
            oldCalls: null,
        };
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        this.showReset = this.showReset.bind(this);
        this.submitReset = this.submitReset.bind(this);
        this.handleClientLoad = this.handleClientLoad.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateClear = this.handleDateClear.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.getClientData = this.getClientData.bind(this);
        this.getLeads = this.getLeads.bind(this);
        this.getCallStats = this.getCallStats.bind(this);
        this.getEmailStats = this.getEmailStats.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
        this.getStatData = this.getStatData.bind(this);
    }

    componentDidMount() {
        let token = localStorage.getItem('accessToken');
        let page = getUrlParameter("page");

        if (page === "forgot-password") {
            //console.log("forgot pass");
            this.setState({ accessToken: null, isLoggedIn: false, forgotPass: true });
            localStorage.clear();
        } else if(!token) {
            //console.log("already logged out")
            this.setState({ accessToken: null, isLoggedIn: false, forgotPass: false });
            localStorage.clear();
        } else {
            //console.log("has token, test expires");
            var isExpired = testLoginExpires();
            if (isExpired) {
                //console.log("expired, log out")
                this.logout();
            } else {
                //console.log("not expired, has token")
                this.setState({ accessToken: token, isLoggedIn: true, forgotPass: false });
                this.getCredentials(token);
            }
            setInterval(() => {
                // test expires every 10 seconds, loggout session if isExpired === true
                isExpired = testLoginExpires();
                if(isExpired) { this.logout(); /*console.log("interval expired")*/ }
                else { /*console.log("interval not expired")*/ }
            }, 10000);
        }
    }
// ------------------------------------------------------------------- CREDENTIALS FUNCTIONS
    getCredentials(token) {
        fetch(Api.serviceSso+'decode', basicGET(token))
        .then(response => {
            if (response.ok) { return response.json(); } 
            else { throw new Error('Something went wrong getting the client role from sso ...'); }
        })
        .then(data => {
            if (data.success) {
                // console.log(data);
                // console.log(data.result.endpoints);
                this.setState({
                    roles: data.result.roles,
                    endpoints: data.result.endpoints,
                    clientCodes: data.result.clientCodes,
                    affiliateCode: data.result.affiliateCode,
                });

                if (data.result.affiliateCode) {
                    this.setState({ isRetailer: true });
                }

                if (data.result.roles.includes("portalCst")) {
                    this.handleClientLoad(data.result.clientCodes[0], data.result.affiliateCode);
                    document.cookie = "role=portalCst;";
                } else {
                    let clientCode = localStorage.getItem('clientCode');
                    let affiliateCode = localStorage.getItem('affiliateCode');
                    if (clientCode) {
                        this.handleClientLoad(clientCode, affiliateCode);
                    }
                    document.cookie = "role=portalAdm;";
                }
            }
        })
        .catch(error => console.log(error));
    }

// ------------------------------------------------------------------- LOGIN/LOGOUT FUNCTIONS
    login() {
        let user = document.getElementById("email").value;
        let pass = document.getElementById("password").value;
        var params = tokenParams(user, pass);

        fetch(Api.serviceToken, params)
        .then(response => {
            if (response.ok) { return response.json(); } 
            else {
                throw new Error('The Email and Password you entered did not work. Please check and try again.');
            }
        })
        .then(data => {
            // console.log(data);

            var timestamp = Date.now();
            var exp = timestamp + (data.expires_in * 1000);
            
            localStorage.setItem('expires', exp);
            localStorage.setItem('accessToken', data.access_token);
            localStorage.setItem('pageId', 'metrics-leads');
            localStorage.setItem('showTest', false);

            this.setState({ accessToken: data.access_token, isLoggedIn: true });
            this.componentDidMount();
        })
        .catch(error => {
            console.log(error);
            document.querySelector('.login-messages').innerHTML = "The Email and Password you entered did not work.</br>Please check and try again.";
        });
    }
    logout() {
        
        this.setState({
            isLoggedIn: false,
            accessToken: null,
            forgotPass: false,

            roles: [],
            endpoints: [],
            currentClient: null,
            affiliateCode: null,
            retailer: null,
            retailerLeadList: null,
            retailerCallsList: null,
            retailerAdwordsList: null,
            retailerFbList: null,

            leads: null,
            retailerLeads: null,
            retailerForms: null,
            calls: null,
            emailStats: null,
            getStats: null,
            roomvoQuickStats: null,
            roomvoDailyViews: null,
            roomvoTopRooms: null,
            roomvoTopSku: null,
            rugOrders: null,
            oldLeads: null,
            oldRetailerLeads: null,
            oldForms: null,
            oldCalls: null,
        });
        localStorage.clear();
        window.location.href = "/";
        document.cookie = "clientCode=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        document.cookie = "role=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    }
    showReset() {
        var login = document.getElementById("login-form");
        var pass = document.getElementById("forgot-pass");
        if (login.style.display === "none") {
            login.style.display = "block";
            pass.style.display = "none";
        } else {
            login.style.display = "none";
            pass.style.display = "block";
        }
    }
    sendResetEmail() {
        var email = document.getElementById("forgot-email").value;
        //console.log(email);

        if (email) {
            const Http = new XMLHttpRequest();
            const url= Api.serviceSso+"forgot/"+email;
            Http.open("POST", url);
            Http.send();

            Http.onreadystatechange = (e) => {
                // console.log(Http.responseText);

                if (Http.status === 200) {
                    this.showReset();
                    document.querySelector('.login-messages').innerHTML = "An email with a password reset link has been sent.";
                    setTimeout(function() {
                        document.querySelector('.login-messages').innerHTML = "";
                    }, 7000);
                } else {
                    document.querySelector('.forgot-messages').innerHTML = "There was a problem sending the password reset email, please check your email and try again.";
                    console.log("password reset returned "+Http.status);
                }
            }
        } else {
            document.querySelector('.forgot-messages').innerHTML = "Please provide a valid email address and try again.";
        }

    }
    validatePassword(a, b, idStatus) {
        var status = checkStringMatch(a, b);
        var button = document.getElementById("reset-submit");

        if (status) {
            document.querySelector('#'+idStatus).classList.remove("fail");
            document.querySelector('#'+idStatus).classList.add("success");
            button.removeAttribute("disabled");

        } else {
            document.querySelector('#'+idStatus).classList.remove("success");
            document.querySelector('#'+idStatus).classList.add("fail");
            button.setAttribute("disabled", true);
        }
    }
    submitReset() {
        //console.log("reset");
        var username = document.querySelector('#reset-email').value;
        var password = document.querySelector('#reset-password').value;
        var noonce = document.querySelector('#reset-noonce').value;
        var body = {
            "pw": password,
            "n": noonce,
        }
        if ( password.length > 9 ) {
            fetch(Api.serviceSso+"password/"+username, basicJSONPOST(body))
            .then(response => {
                if (response.ok) { return response.json(); } 
                else { throw new Error('Something went wrong submitting the new password...'); }
            })
            .then(data => {
                // console.log(data);

                if (data.success) {
                    this.setState({ isLoggedIn: false, forgotPass: false });
                    window.location = window.location.href.split("?")[0];
                } else {
                    document.querySelector('.reset-messages').innerHTML = "There was a problem resetting your password, please try again.";
                    setTimeout(function() {
                        window.location = window.location.href.split("?")[0];
                    }, 3000);
                }
            })
            .catch(error => console.log(error));
        } else {
            document.querySelector('.reset-messages').innerHTML = "Your password must be at least 10 characters long.";
        }
    }
// ------------------------------------------------------------------- UI FUNCTIONS
    handlePageChange(pageId, metric) {
        // console.log(pageId, metric)
        if (pageId !== localStorage.getItem('pageId')) {
            localStorage.setItem('pageId', pageId);
            this.setState({page: pageId})
        }
        this.setState({pageId: pageId})

        // var reports = document.querySelectorAll("#reports-wrapper .report");
        var links = document.querySelectorAll("nav ul a");
        // Object.entries(reports).forEach(([reports, report]) => {
        //     report.classList.remove("active");
        // });
        Object.entries(links).forEach(([links, link]) => {
            link.classList.remove("active");
        });
        // document.querySelector("#"+pageId).classList.add("active");
        document.querySelector("nav a."+pageId).classList.add("active");

        var button = document.querySelector("header .nav-mobile-nav");
        var dropdown = document.querySelector(".body-container > nav");
        dropdown.classList.toggle("active");
        button.classList.toggle("active");

        if (metric) {
            document.querySelector("."+pageId+" .graph-controls ."+metric).click();
        }

        window.scrollTo({ top: 97, behavior: 'smooth' });

        let portal = this.state.currentClient.portal;
        let roles = this.state.roles;
        let clientCode = this.state.currentClient.clientCode;
        // let affiliateCode = this.state.affiliateCode;
        let currentClient = this.state.currentClient;
        if (
            (roles.includes("portalAdm") || portal.leads === true) && 
            (pageId === 'metrics-leads' || pageId === 'metrics-leadsChannel' || pageId === 'metrics-forms' || pageId === 'metrics-summary') &&
            (!this.state.leads) )
            {this.getLeads(clientCode); }
        if ((roles.includes("portalAdm") || portal.calls === true) &&
            (pageId === 'metrics-calls' || pageId === 'metrics-summary') &&
            (!this.state.calls) )
            {this.getCallStats(clientCode); }
        if ((roles.includes("portalAdm") || portal.emails === true) &&
            (pageId === 'metrics-emails' || pageId === 'metrics-summary') &&
            (!this.state.emailStats) ) 
            {this.getEmailStats(clientCode); }
        if ((roles.includes("portalAdm")) &&
            (pageId === 'metrics-getstats') &&
            (!this.state.getStats) )
            {this.getStatData(clientCode); }
        if ((roles.includes("portalAdm") || portal.roomvo === true) &&
            (pageId === 'metrics-roomvo' || pageId === 'metrics-summary') &&
            (!this.state.roomvoQuickStats) )
            {this.getRoomvo(clientCode); }
        if ((roles.includes("portalAdm") || portal.rugsShop === true) &&
            (pageId === 'metrics-rugsShop' || pageId === 'metrics-summary') &&
            (!this.state.rugOrders) ) {
            if (currentClient.rugAffiliateCode) {
                this.getRugShopOrders(currentClient.rugAffiliateCode, clientCode);
            }
        }
        if ((roles.includes("portalAdm") || portal.blogs === true) &&
            (pageId === 'content-blogs') &&
            (!this.state.blogQuickStats) )
            { this.getBlogList(clientCode); }
    }
    handleDateChange(quickRange) {
        var reportStart, reportEnd, prevReportStart, prevReportEnd;
        if (!quickRange) {
            reportStart = document.querySelector(".date-controls .inputs #reportStart").value;
            reportEnd = document.querySelector(".date-controls .inputs #reportEnd").value;
            prevReportStart = document.querySelector(".date-controls .inputs #prevReportStart").value;
            prevReportEnd = document.querySelector(".date-controls .inputs #prevReportEnd").value;
        } else {
            var dates = getQuickRange(quickRange);
            reportStart = dates[0];
            reportEnd = dates[1];
            prevReportStart = dates[2];
            prevReportEnd = dates[3];

            document.querySelector(".date-controls .inputs #reportStart").value = dates[0];
            document.querySelector(".date-controls .inputs #reportEnd").value = dates[1];
            document.querySelector(".date-controls .inputs #prevReportStart").value = dates[2];
            document.querySelector(".date-controls .inputs #prevReportEnd").value = dates[3];
        }
        // console.log(reportStart, reportEnd, prevReportStart, prevReportEnd);
        var update = this.state;
        update["reportStart"] = reportStart;
        update["reportEnd"] = reportEnd;
        update["prevReportStart"] = prevReportStart;
        update["prevReportEnd"] = prevReportEnd;
        update.dates[0] = reportStart;
        update.dates[1] = reportEnd;
        update.dates[2] = prevReportStart;
        update.dates[3] = prevReportEnd;

        var setDates = [reportStart, reportEnd, prevReportStart, prevReportEnd]
        localStorage.setItem('dates', setDates);
        this.setState( update );
        
        if (this.state.currentClient.clientCode) {
            this.handleClientLoad(this.state.currentClient.clientCode, this.state.affiliateCode);
        }
        var button = document.querySelector(".date-controls button.set-dates");
        var dropdown = document.querySelector(".date-controls .date-inputs");
        dropdown.classList.toggle("active");
        button.classList.toggle("active");
    }
    handleDateClear(e) {
        var bool = !this.state.hideHistorical;
        var clearDates = document.querySelector('.clear-dates');

        if (bool === true) {
            clearDates.innerHTML = "<i class='far fa-window-close'></i> Show";
        } else {
            clearDates.innerHTML = "<i class='far fa-window-close'></i> Clear";
        }
        this.setState({ hideHistorical: bool });

        var button = document.querySelector(".date-controls button.set-dates");
        var dropdown = document.querySelector(".date-controls .date-inputs");
        dropdown.classList.toggle("active");
        button.classList.toggle("active");

        var setDates = [this.state.reportStart, this.state.reportEnd, this.state.reportStart, this.state.reportEnd]
        localStorage.setItem('dates', setDates);
    }

// ------------------------------------------------------------------- DATA FUNCTIONS
    handleClientLoad(clientCode, affiliateCode) {
        // console.log(clientCode, affiliateCode);
        localStorage.setItem('clientCode', clientCode);
        document.cookie = "clientCode="+clientCode+";";

        window.dataLayer = window.dataLayer || []; 
        window.dataLayer.push({
            'event' : 'clientChange'
        });


        if (affiliateCode) {
            localStorage.setItem('affiliateCode', affiliateCode);
        } else {
            localStorage.removeItem('affiliateCode');
        }

        if (clientCode !== "fyw") {
            this.setState({
                retailerLeadList: null,
                retailerCallsList: null,
                retailerAdwordsList: null,
                retailerFbList: null,
            })
        };

        this.setState({
            currentClient: null,
            affiliateCode: null,
            retailer: null,
            retailerLeads: null,
            oldRetailerLeads: null,
            retailerForms: null,
            leads: null,
            calls: null,
            emailStats: null,
            getStats: null,
            oldLeads: null,
            oldForms: null,
            oldCalls: null,
            roomvoQuickStats: null,
            roomvoDailyViews: null,
            roomvoTopRooms: null,
            roomvoTopSku: null,
            rugOrders: null,
        });
        this.getClientData(clientCode, affiliateCode);
    }
    getClientData(clientCode, affiliateCode) {
        if (!clientCode) {
            this.setState({
                currentClient: null,
                affiliateCode: null,
                retailer: null,
                retailerLeads: null,
                oldRetailerLeads: null,
                retailerForms: null,
                leads: null,
                calls: null,
                emailStats: null,
                getStats: null,
                oldLeads: null,
                oldForms: null,
                oldCalls: null,
                roomvoQuickStats: null,
                roomvoDailyViews: null,
                roomvoTopRooms: null,
                roomvoTopSku: null,
                rugOrders: null,
            });
            localStorage.removeItem('clientCode');
            document.cookie = "clientCode=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
            localStorage.removeItem('affiliateCode');
        } else {
            fetch(this.state.endpoints.serviceClients + clientCode, basicGET(this.state.accessToken))
            .then(response => {
                if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the client data ...'); }
            })
            .then(data => {
                this.setState({
                    currentClient: data.result,
                    clientCode: data.result.clientCode,
                    portal: data.result.portal,
                });

            }).then(() => {
                let pageId = localStorage.getItem('pageId');
                if (pageId) {
                    this.handlePageChange(pageId);
                }
            })
            .catch(error => console.log(error));


            
        }
    }
    getRetailerData(clientCode, affiliateCode) {
        // console.log(clientCode, affiliateCode)
        Data.getRetailer(
            this.state.endpoints.serviceLeadgen,
            affiliateCode,
            this.state.accessToken
        ).then(data => {
            // console.log(data);
            if (data.success === true) {
                this.setState({
                    retailer: data.result,
                    affiliateCode: affiliateCode,
                });
            }
        });
    }
    getLeads(clientCode, affiliateCode){
        Data.getLeadData(
            this.state.endpoints.serviceCrm,
            clientCode,
            this.state.reportStart,
            this.state.reportEnd,
            this.state.prevReportStart,
            this.state.prevReportEnd,
            this.state.accessToken,
            affiliateCode,
        ).then(data => {
            // console.log(data);
            var leads = data.result[0];
            var oldLeads = data.result[1];
            
            // manipulate the lead and form lists
            if (data.result[0].count > 0) {
                leads.leads.sort((a, b) => (a["createdTime"] < b["createdTime"]) ? 1 : -1);
            } else {
                leads.leads = [];
            }
            if (data.result[1].count <= 0) {
                oldLeads.leads = [];
            }

            if (clientCode === "fyw" && !affiliateCode) {
                var retailerArr = {};
                retailerArr = this.fywRetailerList(data.result[0].leads, "retailerCompany")
                this.setState({ retailerLeadList: retailerArr, });
            }

            // Set the states
            this.setState({
                leads: leads,
                oldLeads: oldLeads,
                affiliateCode: affiliateCode
            });

        });
    }

    fywRetailerList(data, name) {
        let retailerArr = data.map(x => ({value: x.affiliateCode, name: x[name]}));

        retailerArr = retailerArr.filter((ret, index, self) =>
            index === self.findIndex((t) => (
                t.value === ret.value
            ))
        )
        retailerArr = retailerArr.sort((a, b) => (a["name"] > b["name"]) ? 1 : -1);
        // console.log(retailerArr);
        return retailerArr;
    }

    getCallStats(clientCode, affiliateCode) {
        Data.getCalls(
            this.state.endpoints.serviceCalls,
            clientCode,
            this.state.reportStart,
            this.state.reportEnd,
            this.state.prevReportStart,
            this.state.prevReportEnd,
            this.state.accessToken,
            affiliateCode
        ).then(data => {
            //console.log(data);
            var duration1 = 0;
            var duration2 = 0;
            var calls1 = {"count": 0, "calls": []};
            var oldCalls = {"count": 0, "calls": []};

            if (data.result[0].count > 0) {
                duration1 = getCallAverageTime(data.result[0].calls, "Duration");
                calls1 = data.result[0];
            }
            if (data.result[1].count > 0) {
                duration2 = getCallAverageTime(data.result[1].calls, "Duration");
                oldCalls = data.result[1];
            }
            // 
            if (clientCode === "fyw" && !affiliateCode) {
                var retailerArr = {};
                retailerArr = this.fywRetailerList(calls1.calls, "retailerName")
                this.setState({ retailerCallsList: retailerArr, });
            }

            this.setState({
                calls: calls1,
                callAvgDuration: duration1,
                oldCalls: oldCalls,
                oldCallAvgDuration: duration2
            });
        });
    }
    getEmailStats(clientCode) {
        Data.getEmails(
            this.state.endpoints.serviceCrm,
            clientCode,
            this.state.dates,
            this.state.accessToken
        ).then(data => {
            // console.log(data);
            this.setState({emailStats: data});
            if (!data) {
                this.setState({emailStats: errorMessage});
            } else {
                this.setState({emailStats: data});
            }
        });
    }
    
    getRoomvo(clientCode) {
        var dates = this.state.dates;
        var endpoints = this.state.endpoints;
        loadRoomvoQuickStats(endpoints, dates, clientCode, localStorage.getItem('accessToken')).then(response => {
            if (response.success) {
                var data = response.result.data;
                data.shift();
                //console.log(data)

                this.setState({roomvoQuickStats: data});

                loadRoomvoDailyViews(endpoints, dates, clientCode, localStorage.getItem('accessToken')).then(response => {
                    if (response.success){ this.setState({roomvoDailyViews: response.result.data}); }
                });
                loadRoomvoTopEventGA4(endpoints, dates, clientCode, "Selected Room", localStorage.getItem('accessToken')).then(response => {
                    if (response.success){ this.setState({roomvoTopRooms: response.result.data}); }
                });
                loadRoomvoTopEventGA4(endpoints, dates, clientCode, "product view", localStorage.getItem('accessToken')).then(response => {
                    if (response.success){ this.setState({roomvoTopSku: response.result.data}); }
                });
            }
        });
        
    }
    getStatData(clientCode) {
        Data.getStatDistributions(
            this.state.endpoints.serviceAnalytics,
            clientCode,
            this.state.getStatDates[0],
            this.state.getStatDates[1],
            this.state.accessToken
        ).then(data => {
            // console.log(data);
            this.setState({getStats: data});
        });
    }

    getRugShopOrders(rugAffiliateCode, clientCode) {
        Data.getRugsData(
            clientCode,
            this.state.endpoints.serviceProducts,
            this.state.reportStart,
            this.state.reportEnd,
            rugAffiliateCode,
            this.state.accessToken
        ).then(data => {
            if (data.success === false) {
                this.setState({rugOrders: errorMessage});
            } else {
                // console.log(data.result);
                this.setState({rugOrders: data.result});
            }
        });
    }

    getBlogList() {
        // do nothing here
    }

// ------------------------------------------------------------------- DOWNLOAD DATA FUNCTIONS
    downloadReport(type) {
        var file;
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        // fetch URLs
        if (type === "leads") {
            file = this.state.endpoints.serviceCrm+"report.csv?startDate="+this.state.reportStart+"&endDate="+this.state.reportEnd+"&clientCode="+this.state.currentClient.clientCode;
        } else if (type === "allLeads") {
            file = this.state.endpoints.serviceCrm+"report.csv?startDate="+this.state.reportStart+"&endDate="+this.state.reportEnd;
        } else if (type === "allCalls") {
            file = this.state.endpoints.serviceCalls+"report.csv?startDate="+this.state.reportStart+"&endDate="+this.state.reportEnd;
        } else {
            file = this.state.endpoints.serviceCalls+"report.csv?startDate="+this.state.reportStart+"&endDate="+this.state.reportEnd+"&clientCode="+this.state.currentClient.clientCode;
        }

        fetch(file, basicGET(localStorage.getItem('accessToken')))
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);

            anchor.href = objectUrl;
            if (this.state.currentClient) {
                anchor.download = this.state.currentClient.clientCode+'-'+type+'-report.csv';
            } else {
                anchor.download = type+'-report.csv';
            }
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        })
        .catch(error => {
            // do stuff to show error messages
            console.log(error)
        });
    }

// ------------------------------------------------------------------- RENDER
    render() {
        // console.log('Portal--', this.state.currentClient);
        const {
            isLoggedIn, forgotPass, roles, endpoints, currentClient, clientCodes, accessToken, hideHistorical, portal, pageId,
            reportStart, reportEnd, prevReportStart, prevReportEnd, getStatDates,
            isRetailer, retailer, retailerLeadList, retailerCallsList, retailerAdwordsList, retailerFbList, affiliateCode, retailerLeads, oldRetailerLeads, retailerForms,
            leads, calls, callAvgDuration, getStats, emailStats, rugOrders,
            oldLeads, oldCalls, oldCallAvgDuration,
            roomvoQuickStats, roomvoDailyViews, roomvoTopRooms, roomvoTopSku,
            } = this.state;

        return (
            (roles !== []) && (
                <div className={'velocity-portal ' + (isLoggedIn ? 'logged-in' : 'logged-out')}>
                    <Header
                        currentClient={currentClient}
                        clientCodes={clientCodes}
                        retailer={retailer}
                        isRetailer={isRetailer}
                        logout={this.logout}
                        isLoggedIn={isLoggedIn}
                        roles={roles}
                        endpoints={endpoints}
                        token={accessToken}
                        changeClient={this.handleClientLoad}
                        dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                        changeDates={this.handleDateChange}
                        clearDates={this.handleDateClear}
                        hideHistorical={hideHistorical}
                    />
                    { isLoggedIn && (
                        <div className="body-container">
                            <nav>
                                <Navigation
                                    currentClient={currentClient}
                                    isRetailer={isRetailer}
                                    onClick={this.handlePageChange}
                                    portal={portal}
                                    roles={roles}
                                />
                            </nav>
                            <section id="reports-wrapper">
                                { currentClient ? (
                                    <React.Fragment>
                                        {((roles.includes("portalAdm") || currentClient.manager !== "Julie Cross") && (pageId === "metrics-summary")) && (
                                            <Dash
                                                currentClient={currentClient}
                                                portal={portal}
                                                roles={roles}
                                                isRetailer={isRetailer}
                                                retailer={retailer}
                                                leads={leads}
                                                oldLeads={oldLeads}
                                                calls={calls}
                                                oldCalls={oldCalls}
                                                avgDuration={callAvgDuration}
                                                oldDuration={oldCallAvgDuration}
                                                emails={emailStats}
                                                roomvo={roomvoQuickStats}
                                                orders={rugOrders}
                                                onClick={this.handlePageChange}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                endpoints={endpoints}
                                                hideHistorical={hideHistorical}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.leads === true) && (pageId === "metrics-leads")) && (
                                            <LeadTracking
                                                currentClient={currentClient}
                                                retailer={retailer}
                                                isRetailer={isRetailer}
                                                token={accessToken}
                                                leads={leads}
                                                oldLeads={oldLeads}
                                                retailerLeads={retailerLeads}
                                                oldRetailerLeads={oldRetailerLeads}
                                                roles={roles}
                                                retailerList={retailerLeadList}
                                                affiliateCode={affiliateCode}
                                                changeClient={this.getLeads}
                                                downloadReport={this.downloadReport}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                hideHistorical={hideHistorical}
                                                endpoints={endpoints}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.leads === true) && (pageId === "metrics-leadsChannel")) && (
                                            <LeadReports
                                                currentClient={currentClient}
                                                retailer={retailer}
                                                isRetailer={isRetailer}
                                                leads={leads}
                                                oldLeads={oldLeads}
                                                retailerLeads={retailerLeads}
                                                oldRetailerLeads={oldRetailerLeads}
                                                roles={roles}
                                                retailerList={retailerLeadList}
                                                affiliateCode={affiliateCode}
                                                changeClient={this.getLeads}
                                                downloadReport={this.downloadReport}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                hideHistorical={hideHistorical}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.leads === true) && (pageId === "metrics-forms")) && (
                                            <FormReports
                                                currentClient={currentClient}
                                                retailer={retailer}
                                                isRetailer={isRetailer}
                                                leads={leads}
                                                oldLeads={oldLeads}
                                                retailerLeads={retailerLeads}
                                                oldRetailerLeads={oldRetailerLeads}
                                                retailerForms={retailerForms}
                                                roles={roles}
                                                retailerList={retailerLeadList}
                                                affiliateCode={affiliateCode}
                                                changeClient={this.getLeads}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                hideHistorical={hideHistorical}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.calls === true) && (pageId === "metrics-calls")) && (
                                            <CallTracking
                                                currentClient={currentClient}
                                                retailer={retailer}
                                                isRetailer={isRetailer}
                                                token={accessToken}
                                                calls={calls}
                                                oldCalls={oldCalls}
                                                avgDuration={callAvgDuration}
                                                oldDuration={oldCallAvgDuration}
                                                downloadReport={this.downloadReport}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                hideHistorical={hideHistorical}
                                                roles={roles}
                                                retailerList={retailerCallsList}
                                                affiliateCode={affiliateCode}
                                                changeClient={this.getCallStats}
                                                endpoints={endpoints}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.emails === true) && (pageId === "metrics-emails")) && (
                                            <EmailSends
                                                currentClient={currentClient}
                                                retailer={retailer}
                                                stats={emailStats}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                hideHistorical={hideHistorical}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.analytics === true) && (pageId === "metrics-analytics")) && (
                                            <Analytics
                                                currentClient={currentClient}
                                                retailer={retailer}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                hideHistorical={hideHistorical}
                                                endpoints={endpoints}
                                                onClick={this.handlePageChange}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.analytics === true) && (pageId === "metrics-ga4analytics")) && (
                                            <GA4Analytics
                                                currentClient={currentClient}
                                                retailer={retailer}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                hideHistorical={hideHistorical}
                                                endpoints={endpoints}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.adwords === true) && (pageId === "metrics-adwords")) && (
                                            <Adwords
                                                currentClient={currentClient}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                retailer={retailer}
                                                isRetailer={isRetailer}
                                                retailerList={retailerAdwordsList}
                                                affiliateCode={affiliateCode}
                                                roles={roles}
                                                hideHistorical={hideHistorical}
                                                changeClient={this.handleClientLoad}
                                                endpoints={endpoints}
                                            />
                                        )}
                                        {((roles.includes("portalAdm")) && (pageId === "metrics-getstats")) && (
                                            <GetStats
                                                currentClient={currentClient}
                                                retailer={retailer}
                                                stats={getStats}
                                                accessToken={accessToken}
                                                serviceAnalytics={endpoints.serviceAnalytics}
                                                dates={getStatDates}
                                                hideHistorical={true}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.googleMyBusiness === true) && (pageId === "metrics-gmb")) && (
                                            <GoogleMyBusiness
                                                currentClient={currentClient}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                endpoints={endpoints}
                                                hideHistorical={true}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.SearchConsole === true) && (pageId === "metrics-searchConsole")) && (
                                            <SearchConsole
                                                currentClient={currentClient}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                endpoints={endpoints}
                                                accessToken={accessToken}
                                                hideHistorical={hideHistorical}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.googleMyBusiness === true) && (pageId === "metrics-googleRatings")) && (
                                            <GoogleRatingsReviews
                                                currentClient={currentClient}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                endpoints={endpoints}
                                                accessToken={accessToken}
                                                hideHistorical={true}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.facebook === true) && (pageId === "metrics-socialFB")) && (
                                            <SocialFacebook
                                                currentClient={currentClient}
                                                retailerList={retailerFbList}
                                                isRetailer={isRetailer}
                                                retailer={retailer}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                hideHistorical={hideHistorical}
                                                roles={roles}
                                                endpoints={endpoints}
                                            />
                                        )}
                                        {((roles.includes("portalAdm") || portal.roomvo === true) && (pageId === "metrics-roomvo")) && (
                                            <Roomvo
                                                currentClient={currentClient}
                                                retailer={retailer}
                                                data={[roomvoQuickStats, roomvoDailyViews, roomvoTopRooms, roomvoTopSku]}
                                                reportStart={this.state.reportStart}
                                                reportEnd={this.state.reportEnd}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                hideHistorical={hideHistorical}
                                                endpoints={endpoints}
                                            />
                                        )}
                                        {(
                                            (roles.includes("portalAdm") || portal.rugsShop === true) &&
                                            (currentClient.rugAffiliateCode !== null && currentClient.rugAffiliateCode !== "") &&
                                            (pageId === "metrics-rugsShop") ) && (
                                            <RugsShopOrders
                                                currentClient={currentClient}
                                                retailer={retailer}
                                                orders={rugOrders}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                hideHistorical={hideHistorical}
                                            />
                                        )}
                                        

                                        {((roles.includes("dev")) && (pageId === "content-blogs")) && (
                                            <Blogs
                                                currentClient={currentClient}
                                                dates={[reportStart, reportEnd, prevReportStart, prevReportEnd]}
                                                endpoints={endpoints}
                                                hideHistorical={hideHistorical}
                                            />
                                        )}

                                        {((currentClient.velocity === true) && (pageId === "program")) && (
                                            <ProgramDetails
                                                currentClient={currentClient}
                                                roles={roles}
                                            />
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {roles.includes("portalAdm") && (
                                            <React.Fragment>
                                                <h1 className='reported_head'>Reporting for <span className='hightlighted_text'>ALL</span> Clients</h1>
                                                <div className='reported_list_wrapper'>
                                                    <div className='reported_item'>
                                                        <h3>Retailer <span className='hightlighted_text'>PHONE CALL</span> Reports</h3>
                                                        <p className='inner-content'>Builds the chosen report, for all clients, for the currently applied date range.</p>
                                                        <p className='btn-wrap'><button className="report_btn" onClick={() => this.downloadReport("allCalls")}><i className="fas fa-file-excel"></i> DOWNLOAD REPORT</button></p>
                                                    </div>
                                                    <div className='reported_item'>
                                                        <h3>Retailer <span className='hightlighted_text'>LEAD</span> Reports</h3>
                                                        <p className='inner-content'>Builds the chosen report, for all clients, for the currently applied date range.</p>
                                                        <p className='btn-wrap'><button className="report_btn" onClick={() => this.downloadReport("allLeads")}><i className="fas fa-file-excel"></i> DOWNLOAD REPORT</button></p>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        {!roles.includes("portalAdm") && (
                                            <React.Fragment>
                                                <h1>Please Choose A Client...</h1>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </section>
                        </div>
                    )}
                    {(!isLoggedIn && !forgotPass) && (
                        <React.Fragment>
                            <form id="login-form" className='before_login_form'>
                                <h3>Log In</h3>
                                <p className="login-messages"></p>
                                <InputRow label="Email" type="text" property="email" id="email" />
                                <InputRow label="Password" type="password" property="password" id="password" />
                                <input type="button" className="button white" onClick={() => this.login("click")} value="Log In" />

                                <p className="forgot-link bottom-link">Having trouble logging in? <a onClick={() => this.showReset()}>Reset your password</a></p>
                            </form>

                            <form id="forgot-pass" style={{display: "none"}}>
                                <h3>Forgot/Reset Password</h3>
                                <p className="forgot-messages"></p>
                                <InputRow label="Account Email" type="text" property="email" id="forgot-email" />
                                <input type="button" onClick={() => this.sendResetEmail()} className="button white" value="Send Password Reset Email" />

                                <p className="forgot-link" onClick={() => this.showReset()}>Back To Login</p>
                            </form>
                        </React.Fragment>
                    )}
                    {forgotPass && (
                        <form id="reset-form">
                            <h3>Set New Password</h3>
                            <p className="reset-messages"></p>
                            <InputRow label="Email" type="text" property="email" id="reset-email" value={getUrlParameter("user")} />
                            <input type="hidden" id="reset-noonce" value={getUrlParameter("n")} />
                            <em>Passwords must be at least 10 characters long.</em>
                            <InputRow label="New Password" type="password" property="password" id="reset-password" />
                            <InputRow label="Confirm New Password" type="password" property="confirm-password" id="reset-confirm-password" onChange={() => this.validatePassword("reset-password", "reset-confirm-password", "confirm-pass-status")} />
                            <div id="confirm-pass-status" className="fail"></div>

                            <input type="button" onClick={() => this.submitReset()} id="reset-submit" className="button white" value="Reset Password" />
                        </form>
                    )}
                    <Footer />
                </div>
            )
        );
    }
}

export default App;