

export function testLoginExpires() {
    let now = Date.now();
    let expires = localStorage.getItem('expires')
    var expired = true;
    
    if (expires < now || !localStorage.getItem('accessToken')) {
        // silence is golden
    } else {
        expired = false;
    }

    return expired;
}