import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Statistic } from '../../../helpers/uiComponents';
import { numberToLocal } from '../../../helpers/uiFunctions';
import { loadGoogleMyBusiness } from '../../data/supermetricsService';

class GmbQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totals: null,
        }
    }
    componentDidMount() {
        loadGoogleMyBusiness(
            ["location_address_lines", "views_search", "views_total", "actions_total", "actions_website", "actions_phone", "actions_driving_directions"],
            1000,
            null,
            null,
            this.props.endpoints, [this.props.dates[0], this.props.dates[1], ],
            this.props.currentClient.clientCode,
            localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success === false) {
                this.setState({ totals: "failed" });
            } else {
                var data = response.result.data
                data.shift();
                var totals = data.reduce((r, a) => a.map((b, i) => (r[i] || 0) + b), []);
                totals.shift();

                this.setState({
                    totals: totals,
                });
            }
        });
    }
    render() {
        const { totals } = this.state;
        return ( <
            React.Fragment > {
                (this.props.onClick) && ( <
                    Router >
                    <
                    Link to = "metrics-gmb"
                    className = "button white learn-more"
                    onClick = {
                        () => this.props.onClick("metrics-gmb") } >
                    <
                    i className = "fas fa-chart-line" > < /i> <
                    /Link> <
                    /Router>
                )
            } <
            h3 > Google My Business < /h3> <
            section className = "stats row" > {
                (totals === "failed") ? ( <
                    p > There may have been an error connecting to Google My Business, or Google My Business is not configured
                    for your Portal. < /p>
                ) : ( <
                    React.Fragment >
                    <
                    Statistic label = "Total Search Impressions"
                    value = { totals ? numberToLocal(totals[0]) : "calculating..." }
                    hideHistorical = { this.props.hideHistorical }
                    /> <
                    Statistic label = "Total Views"
                    value = { totals ? numberToLocal(totals[1]) : "calculating..." }
                    hideHistorical = { this.props.hideHistorical }
                    /> <
                    Statistic label = "Total Actions"
                    value = { totals ? numberToLocal(totals[2]) : "calculating..." }
                    hideHistorical = { this.props.hideHistorical }
                    /> <
                    Statistic label = "Website Visits"
                    value = { totals ? numberToLocal(totals[3]) : "calculating..." }
                    hideHistorical = { this.props.hideHistorical }
                    /> <
                    Statistic label = "Phone Calls"
                    value = { totals ? numberToLocal(totals[4]) : "calculating..." }
                    hideHistorical = { this.props.hideHistorical }
                    /> <
                    Statistic label = "Directions Requests"
                    value = { totals ? numberToLocal(totals[5]) : "calculating..." }
                    hideHistorical = { this.props.hideHistorical }
                    /> <
                    /React.Fragment>
                )
            } <
            /section> <
            /React.Fragment>
        )
    }
}
export default GmbQuickStat;