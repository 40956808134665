import React from 'react';
import Chart from 'chart.js/auto';

import { countLeads, getPercentChange, getTopResult, numberToLocal, countUniqueKeys } from '../../helpers/uiFunctions';
import { Statistic, ReportHeading, Breakdown } from '../../helpers/uiComponents';
import { chartColors } from '../../helpers/uiHelpers';
import { InputRow } from '../../helpers/inputRow';

class LeadReports extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            distinctChannels: [],
			leads: [],
			count: 0,
			oldCount: 0,
			affiliateCode: null,
			name: null,
			loading: true,
        };
        this.loadRetailer = this.loadRetailer.bind(this);
    }
	componentDidMount() {
		try {
			if (this.props.leads) {
				this.handleData();
				
			}
		} catch (error) {
			console.log(error);
		}
	}
	componentDidUpdate(prevProps) {
		try {
			if (this.props.leads) {
				if (this.props.leads.daily && (this.props.leads !== prevProps.leads)  && !this.props.leads.message) {
					this.handleData();
				}	
			}
		} catch (error) {
			console.log(error);
		}
	}
	handleData() {
		this.setState({
			distinctChannels: countUniqueKeys(this.props.leads.leads, "channel"),
			name: this.props.currentClient.name,
			leads: this.props.leads.leads,
			oldLeads: this.props.oldLeads.leads,
			count: this.props.leads.count,
			oldCount: this.props.oldLeads.count,
			loading: false,
		})
		this.buildChart(countUniqueKeys(this.props.leads.leads, "channel"));
	}

	loadRetailer(e) {
		var affiliateCode = e.target.value;
		var clientCode = this.props.currentClient.clientCode;
		var changeClient = this.props.changeClient;

		changeClient(clientCode, affiliateCode);
	}
	clearRetailer() {
		var affiliateCode = null;
		var clientCode = this.props.currentClient.clientCode;
		var changeClient = this.props.changeClient;

		changeClient(clientCode, affiliateCode);
	}

	buildChart(inputData) {
		if (inputData) {
			var data = [];
			var options = [];

			for (let i = 0; i < inputData.length; i++) {
				options.push(inputData[i][0]);
				data.push(inputData[i][1]);
			}

			var ctx = document.getElementById('graph-lead-by-source');
			new Chart(ctx, {
				"type":"doughnut",
				"data": {
					"labels":options,
					"datasets":[
						{
							"data":data,
							"backgroundColor": chartColors,
						}
					]
				},
				options: {
					plugins: {
						aspectRatio: 1,
						maintainAspectRatio: false,
						legend: {
							display: false,
						},
						title: {
							display: true,
							text: "Leads By Channel",
							fontSize: 16,
						},
					},
					layout: {
						padding: {
							left: 50,
							right: 50,
							top: 25,
							bottom: 25
						}
					},
					animations: {
						linear: {
							duration: 1000,
							easing: 'linear',
							from: 1,
							to: 0,
							loop: true
						}
					},
				}
			});
		}
	}

	render() {
		const {distinctChannels, leads, oldLeads, count, oldCount, name, loading } = this.state;
		const hideHistorical = this.props.hideHistorical;
		const isRetailer = this.props.isRetailer;
		const retailerList = this.props.retailerList;

		var top1, top2, top3 = {};
		if (leads) {
			top1 = getTopResult(leads, "channel", 0);
			top2 = getTopResult(leads, "channel", 1);
			top3 = getTopResult(leads, "channel", 2);
		}
		// console.log(isRetailer, retailerList);

		const affCode = this.props.affiliateCode;
		if (this.props.affiliateCode) {
			document.querySelectorAll(".lead-retailer-list").forEach(function(select) {
  				select.value = affCode
			});
		}

		return(
			(this.props.currentClient) && (

				<div id="metrics-leadsChannel" className="report metrics-leadsChannel">
					{(loading === true) && (
            			<div className="report-loading">
            				<p><i className="fas fa-circle-notch"></i></p>
            			</div>
            		)}
					<ReportHeading
						title="Web Leads By Channel"
						name={name}
						dates={this.props.dates}
						hideHistorical={hideHistorical}
					/>

					{( isRetailer === false && retailerList ) && (
        				<div className="retailer-selector">
	            			Retailer:
	            			<span className="clear-client"><i className="far fa-window-close" onClick={() => this.clearRetailer()}></i></span>
	            			<InputRow type="select" className="lead-retailer-list" property="retailerList" options={retailerList} onChange={this.loadRetailer} />
	            		</div>
            		)}

					<React.Fragment>
						<section className="stats row">
							<Statistic
								html="Total Leads"
								value={numberToLocal(count)}
								status={getPercentChange(count, oldCount)[1]}
								percent={getPercentChange(count, oldCount)[0]}
								hideHistorical={hideHistorical}
							/>
							{(top1 && oldLeads) && (
								<Statistic
									label={top1.key}
									value={numberToLocal(top1.val)}
									status={ getPercentChange( (top1.val), (countLeads(oldLeads, top1.key, "channel")) )[1]}
									percent={getPercentChange( (top1.val), (countLeads(oldLeads, top1.key, "channel")) )[0]}
									hideHistorical={hideHistorical}
								/>
							)}
							{(top2 && oldLeads) && (
								<Statistic
									label={top2.key}
									value={numberToLocal(top2.val)}
									status={ getPercentChange( (top2.val), (countLeads(oldLeads, top2.key, "channel")) )[1]}
									percent={getPercentChange( (top2.val), (countLeads(oldLeads, top2.key, "channel")) )[0]}
									hideHistorical={hideHistorical}
								/>
							)}
							{(top3 && oldLeads) && (
								<Statistic
									label={top3.key}
									value={numberToLocal(top3.val)}
									status={ getPercentChange( (top3.val), (countLeads(oldLeads, top3.key, "channel")) )[1]}
									percent={getPercentChange( (top3.val), (countLeads(oldLeads, top3.key, "channel")) )[0]}
									hideHistorical={hideHistorical}
								/>
							)}
						</section>


						<section className="stats-graph">
							<div className="graph">
								<div className="graph-container">
									<canvas className="chart doughnut" id="graph-lead-by-source" />
								</div>
							</div>
							<Breakdown
								data={distinctChannels}
								title={"Leads By Channel"}
								format={"wide"}
								dataIndex="1"
							/>
						</section>
						
					</React.Fragment>
				</div>
			)
		)

	}
}

export default LeadReports;