import React from 'react';

class Footer extends React.Component {

	render() {
		return(
			<footer className='common-footer'>
				<p>Powered by <a href="https://mobile-marketing.agency" target="_blank" rel="noopener noreferrer">Mobile Marketing</a>, a Cyncly Company</p>
				<small>©{(new Date().getFullYear())} Mobile Marketing, LLC. All rights reserved.</small>
			</footer>
		);
	}

}

export default Footer;