import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Statistic } from '../../../helpers/uiComponents';
import { numberToLocal } from '../../../helpers/uiFunctions';
import { loadRoomvoQuickStats } from '../../data/supermetricsService';

class RoomvoQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        }
    }
    componentDidMount() {
        loadRoomvoQuickStats(
            this.props.endpoints,
            this.props.dates,
            this.props.currentClient.clientCode,
            localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success) {
                var data = response.result.data;
                data.shift();
                this.setState({data: data});
            }
        });
    }
    render() {
        const { data } = this.state;
        return(
            <React.Fragment>
                {(this.props.onClick) && (
                    <Router>
                        <Link to="metrics-roomvo" className="button white learn-more" onClick={() => this.props.onClick("metrics-roomvo")}>
                            <i className="fas fa-chart-line"></i>
                        </Link>
                    </Router>
                )}
                <h3>Room Visualizer</h3>
                <section className="stats row">
                    {(data) ? (
                        (data.length > 0) ? (
                            data.map((stat, index) => 
                                <Statistic
                                    key={index}
                                    label={stat[0]}
                                    value={numberToLocal(stat[1])}
                                    status={(stat[2] < 0) ? ("down") : ("up")}
                                    percent={(stat[2]) ? (stat[2]) : (0)}
                                    hideHistorical={this.props.hideHistorical}
                                />
                            )
                        ) : (
                            <p>There are no stats reported for Room Visualizer during this time.</p>
                        )
                    ) : (
                        <p>There may have been an error connecting to Room Visualizer, or Room Visualizer is not configured for your Portal.</p>
                    )}
                </section>
            </React.Fragment>
        )
    }
}
export default RoomvoQuickStat;