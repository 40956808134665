import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Statistic } from '../../../helpers/uiComponents';
import { numberToLocal } from '../../../helpers/uiFunctions';
import { loadSearchConsole } from '../../data/supermetricsService';

class SearchconsoleQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quickStats: null,
            errorMessage: null,
        }
    }
    componentDidMount() {
        loadSearchConsole( // fields, rows, order, compareType, filter, settings, endpoints, dates, clientCode, token
            ["impressions", "clicks", "ctr",],
            10,
            null,
            "custom",
            null,
            {"blanks_to_zero": true},
            this.props.endpoints,
            this.props.dates,
            this.props.currentClient.clientCode,
            localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success) {
                this.setState({
                    quickStats: response.result.data,
                    errorMessage: null,
                });
            } else {
                this.setState({
                    errorMessage: true,
                    quickStats: null,
                });
            }
        });
    }
    render() {
        const { quickStats, errorMessage } = this.state
        return(
            <React.Fragment>
                {(this.props.onClick) && (
                    <Router>
                        <Link to="metrics-searchConsole" className="button white learn-more" onClick={() => this.props.onClick("metrics-searchConsole")}>
                            <i className="fas fa-chart-line"></i>
                        </Link>
                    </Router>
                )}
                <h3>Search Console</h3>
                <section className="stats row">
                    {(errorMessage) ? (
                        <p>There may have been an error connecting to Search Console, or Search Console is not connected for your Portal</p>
                    ) : (
                        <React.Fragment>
                            <Statistic
                                label={quickStats && quickStats[0][0]}
                                value={quickStats && (quickStats[1][0]) ? numberToLocal(quickStats[1][0]) : ("calculating...")}
                                status={quickStats && (quickStats[1][1] < 0) ? ("down") : ("up")}
                                percent={quickStats && (quickStats[1][1]) ? (quickStats[1][1]) : (0)}
                                hideHistorical={this.props.hideHistorical}
                            />
                            <Statistic
                                label={quickStats && quickStats[0][2]}
                                value={quickStats && (quickStats[1][2]) ? numberToLocal(quickStats[1][2]) : ("calculating...")}
                                status={quickStats && (quickStats[1][3] < 0) ? ("down") : ("up")}
                                percent={quickStats && (quickStats[1][3]) ? (quickStats[1][3]) : (0)}
                            />
                            <Statistic
                                label={quickStats && quickStats[0][4]+"%"}
                                value={quickStats && (quickStats[1][4]) ? numberToLocal(quickStats[1][4]) : ("calculating...")}
                                status={quickStats && (quickStats[1][5] < 0) ? ("down") : ("up")}
                                percent={quickStats && (quickStats[1][5]) ? (quickStats[1][5]) : (0)}
                            />
                        </React.Fragment>
                    )}
                </section>
            </React.Fragment>
        )
    }
}
export default SearchconsoleQuickStat;