import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Statistic } from '../../../helpers/uiComponents';
import { numberToLocal } from '../../../helpers/uiFunctions';
import { loadGoogleMyBusiness } from '../../data/supermetricsService';

class GoogleratingsQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ratings: null,
        }
    }
    componentDidMount() {
		loadGoogleMyBusiness(
            ["total_review_star_rating", "total_review_count", "review_avg_star_rating", "review_total_count",],
            10,
            null,
            {"blanks_to_zero": true},
            this.props.endpoints,
            this.props.dates,
            this.props.currentClient.clientCode,
            localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success) {
                this.setState({
                    ratings: response.result.data,
                });
            } else {
                this.setState({
                    ratings: response,
                });
            }
        });
	}
    render() {
        const { ratings } = this.state
        return(
            <React.Fragment>
                {(this.props.onClick) && (
                    <Router>
                        <Link to="metrics-googleRatings" className="button white learn-more" onClick={() => this.props.onClick("metrics-googleRatings")}>
                            <i className="fas fa-chart-line"></i>
                        </Link>
                    </Router>
                )}
                <h3>GOOGLE RATINGS & REVIEWS</h3>
                <section className="stats row">
                    {(ratings && ratings.success === false) ? (
                        <p>There may have been an error connecting to Google Ratings & Reviews, or Google Ratings & Reviews is not configured for your Portal.</p>
                    ) : (
                        <React.Fragment>
                            <Statistic
                                label={"Star Rating Avg. - This Period"}
                                value={(ratings && ratings[1][0]) ? numberToLocal(ratings[1][2]) : ("calculating...")}
                                hideHistorical={true}
                            />
                            <Statistic
                                label={"Reviews - This Period"}
                                value={(ratings && ratings[1][0]) ? numberToLocal(ratings[1][3]) : ("calculating...")}
                                hideHistorical={true}
                            />
                            <Statistic
                                label={"Star Rating Avg. - All Time"}
                                value={(ratings && ratings[1][0]) ? numberToLocal(ratings[1][0]) : ("calculating...")}
                                hideHistorical={true}
                            />
                            <Statistic
                                label={"Reviews - All Time"}
                                value={(ratings && ratings[1][0]) ? numberToLocal(ratings[1][1]) : ("calculating...")}
                                hideHistorical={true}
                            />
                        </React.Fragment>
                    )}
                </section>
            </React.Fragment>
        )
    }
}
export default GoogleratingsQuickStat;