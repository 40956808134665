import React from 'react';
import { getPercentChange, numberToLocal, getTopResult, getCallAverageTime } from '../../helpers/uiFunctions';
import { Statistic, ReportHeading } from '../../helpers/uiComponents';
import FacebookQuickStat from './quickStats/facebookSummary';
import GoogleratingsQuickStat from './quickStats/googleratingsSummary';
import AdwordsQuickStat from './quickStats/adwordsSummary';
import RoomvoQuickStat from './quickStats/roomvoSummary';
import RugorderQuickStat from './quickStats/rugordersSummary';
import AnalyticsQuickStat from './quickStats/googleanalyticsSummary';
import EmailQuickStat from './quickStats/emailSummary';
import CallsQuickStat from './quickStats/callsSummary';
import { getCalls, getLeadData } from '../data/data';
import LeadsQuickStat from './quickStats/leadsSummary';
import GmbQuickStat from './quickStats/gmbSummary';
import SearchconsoleQuickStat from './quickStats/searchconsoleSummary';


class Dash extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            name: null,
            leadCount: null,
			oldLeadCount: null,
			topChannel: null,
			topForm: null,
			callsCount: null,
			oldCallsCount: null,
			callValue: null,
			callWon: null,
        };
    }

	componentDidMount() {
		if (this.props.isRetailer === true) {
			this.setState({name: this.props.retailer.name,})
		} else {
			this.setState({name: this.props.currentClient.name,})
		}
		getCalls(
            this.props.endpoints.serviceCalls,
            this.props.currentClient.clientCode,
            this.props.dates[0],
            this.props.dates[1],
            this.props.dates[2],
            this.props.dates[3],
            localStorage.getItem('accessToken'),
            this.props.currentClient.affiliateCode
        ).then(response => {
            if (response.success) {
				var calls = response.result[0].calls;
				var oldcalls = response.result[1].calls;
				var value = 0;
                var won = 0;
				if (calls) {
					calls.forEach(function(call) {
						value = value + call.value;
						if (call.conversionStatus === "Won") {
							won = won + call.value;
						}
					});
				}
                this.setState({
                    callsCount: calls ? response.result[0].count : 0,
                    oldCallsCount: oldcalls ? response.result[1].count : 0,
					callDuration: calls ? getCallAverageTime(calls, "Duration") : 0,
					oldCallDuration: oldcalls ? getCallAverageTime(oldcalls, "Duration") : 0,
                    callValue: value,
                    callWon: won,
                });
            }
        });

		getLeadData(
            this.props.endpoints.serviceCrm,
            this.props.currentClient.clientCode,
            this.props.dates[0],
            this.props.dates[1],
            this.props.dates[2],
            this.props.dates[3],
            localStorage.getItem('accessToken'),
            this.props.currentClient.affiliateCode
        ).then(response => {			
            var leads = response.result[0];
            var oldLeads = response.result[1];

			var channel = getTopResult(leads.leads, "channel", 0);
			var oldchannel = getTopResult(oldLeads.leads, "channel", 0);
			var form = getTopResult(leads.leads, "form", 0);
			var oldform = getTopResult(oldLeads.leads, "form", 0);

			if (!channel) {channel = "empty";}
			if (!form) {form = "empty";}

			this.setState({
				leadCount: leads.count,
				oldLeadCount: oldLeads.count,
				topChannel: channel,
				oldTopChannel: oldchannel,
				topForm: form,
				oldTopForm: oldform,
			});
        });
	}
	
	render() {
		const {
			name,
			leadCount, oldLeadCount, topChannel, oldTopChannel, topForm, oldTopForm,
			callsCount, oldCallsCount, callDuration, oldCallDuration, callValue, callWon,
		} = this.state;
		const portal = this.props.portal;
		const roles = this.props.roles;
		
		return(
			<div id="metrics-summary" className="report metrics-summary dashboard">
				<ReportHeading
					title="Digital Marketing Summary"
					name={name}
					dates={this.props.dates}
					hideHistorical={this.props.hideHistorical}
				/>
				
				{(roles.includes("portalAdm") || portal.leads === true || portal.calls === true) && (
					<React.Fragment>
						<h3>MARKETING SUMMARY</h3>
						<section className="stats row">
							<Statistic
								label="Total Web & Call Leads"
								value={(leadCount !== null) ? numberToLocal(leadCount + callsCount) : ("calculating...")}
								status={(leadCount !== null || callsCount !== null) && getPercentChange((leadCount+callsCount), (oldLeadCount+oldCallsCount))[1]}
								percent={(leadCount !== null || callsCount !== null) && getPercentChange((leadCount+callsCount), (oldLeadCount+oldCallsCount))[0]}
								hideHistorical={this.props.hideHistorical}
							/>
						</section>
					</React.Fragment>
				)}				
				{(roles.includes("portalAdm") || portal.leads === true) && (
					<LeadsQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
						data={[leadCount, oldLeadCount, topChannel, oldTopChannel, topForm, oldTopForm]}
						hideHistorical={this.props.hideHistorical}
					/>
				)}
				{(roles.includes("portalAdm") || portal.calls === true) && (
					<CallsQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
						data={[callsCount, oldCallsCount, callDuration, oldCallDuration, callValue, callWon]}
						hideHistorical={this.props.hideHistorical}
					/>
				)}
				{(roles.includes("portalAdm") || portal.emails === true) && (
					<EmailQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
						hideHistorical={this.props.hideHistorical}
					/>
				)}
				{(roles.includes("portalAdm") || portal.searchConsole === true) && (
					<SearchconsoleQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
						hideHistorical={this.props.hideHistorical}
					/>
				)}
				{(roles.includes("portalAdm") || portal.analytics === true) && (
					<AnalyticsQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
						hideHistorical={this.props.hideHistorical}
					/>
				)}
				{(roles.includes("portalAdm") || portal.adwords === true) && (
					<AdwordsQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
						hideHistorical={this.props.hideHistorical}
					/>
				)}
				{(roles.includes("portalAdm") || portal.googleMyBusiness === true) && (
					<GmbQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
						hideHistorical={true}
					/>
				)}
				{(roles.includes("portalAdm") || portal.googleRatings === true) && (
					<GoogleratingsQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
					/>
				)}
				{(roles.includes("portalAdm") || portal.facebook === true) && (
					<FacebookQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
					/>
				)}
				{(roles.includes("portalAdm") || portal.roomvo === true) && (
					<RoomvoQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
						hideHistorical={this.props.hideHistorical}
					/>
				)}
				{(roles.includes("portalAdm") || portal.rugsShop === true) && (
					<RugorderQuickStat
						endpoints={this.props.endpoints}
						dates={this.props.dates}
						currentClient={this.props.currentClient}
						onClick={this.props.onClick}
					/>
				)}
			</div>
		)
	}

}

export default Dash;