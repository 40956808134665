import React from 'react';
import { formatDateDisplay } from '../../helpers/uiFunctions';

export class ServiceAgreement extends React.Component {

	render() {
		const currentClient = this.props.currentClient;
		const prodSite = this.props.prodSite;

		const title = {
    		textAlign: 'center',
    	};
    	const allcaps = {
    		textTransform: 'uppercase',
    	};

    	// let serviceFee = 0;
		// if (currentClient) {
		// 	if (currentClient.servicePackage === "prime") { serviceFee = "$1,700"; }
		// 	else if (currentClient.servicePackage === "premium") { serviceFee = "$2,900"; } 
		// 	else if (currentClient.servicePackage === "platinum") { serviceFee = "$3,500"; }
		// }


		return (
			<div className="conditions" id="print-window">
<h3 style={title}>RETAILER MASTER SERVICES AGREEMENT</h3>
<p>This Retailer Master Services Agreement (“Agreement”) is made and entered into as of {(prodSite.goLive) && (formatDateDisplay(prodSite.goLive))} (“Contract Date”) between Mobile Marketing, LLC (“Mobile Marketing”) and {(currentClient) && (currentClient.name)} (“Retailer”). Mobile Marketing and Retailer are collectively referred to as the “Parties” and individually as a “Party.”</p>
<p>The Agreement will be updated (in Appendix A) to document the date the Retailer website is live (the “Go Live Date” or the “Effective Date”).</p>

<h3 style={title}>RECITALS</h3>
<p>WHEREAS, Mobile Marketing provides certain website development, hosting, and digital marketing services, and Retailer desires Mobile Marketing to make its services available to it in connection with the Velocity Program operated by Shaw Industries, Inc. (“Shaw”);</p>
<p>WHEREAS, Mobile Marketing agrees to provide the Services on the terms and conditions of this Agreement;</p>
<p>NOW, THEREFORE, in consideration of the mutual promises and covenants contained herein, the receipt and sufficiency of which is hereby acknowledged by the Parties hereto, the Parties agree as follows:</p>

<ol>
	<li>
		<h4>Definitions</h4>
		<ol type="a">
			<li>“Affiliate” means, with respect to a person, any other person directly or indirectly controlling, controlled by, or under common control with the first person. For purposes of this Agreement, Shaw’s Affiliates shall expressly exclude Berkshire Hathaway Inc. and its subsidiaries, except Shaw Industries Group, Inc.</li>
			<li>“Call Recordings” means any and all call recordings Mobile Marketing makes in connection with services performed pursuant to this Agreement, including calls between Mobile Marketing and Retailer.</li>
			<li>“Confidential Information” includes information relating to the processes, techniques, work practices, lists of current and prospective customers, suppliers, vendors, business practices, discoveries, inventions, source code, price information, strategies, current and future business plans, manufacturing methods, financial information and data, marketing, products, machinery, apparatus, specifications, drawings, sketches, models, samples, tools, technical information, proprietary information or trade secrets, and any information marked “Confidential” or which, by its nature, a reasonable person would consider confidential. “Confidential Information” also includes all Call Recordings and Customer Data, which shall for the purposes of this Agreement be considered Retailer’s Confidential Information. Confidential Information does not include information that: (1) was publicly available at the time of disclosure; (2) the Receiver lawfully received from a third party that was not prohibited from disclosing such information without restrictions (excluding Customer Data and Call Recordings); (3) was independently developed by the Receiver without breach of this Agreement or (4) the Receiver knew prior to receiving such information from the Discloser.</li>
			<li>“Customer Data” means customer and lead data collected from Retailer’s Website developed or managed pursuant to this Agreement.</li>
			<li>“Fees” means the fees payable with respect to the Services. Fees for the Services will be set forth in individual Statements of Work.</li>
			<li>“Intellectual Property” means registered or unregistered patents, trade secrets, rights to inventions, copyright and related rights, trademarks, trade dress, service marks, know-how, knowledge, trade or business names, domain names, goodwill, software, algorithms, user interfaces, ideas, concepts, techniques, methods, the right to sue for passing off, rights in designs, database rights (including rights to extraction), rights to use and protect the confidentiality of Confidential Information, and all other intellectual property rights recognized under law, including the right to sue in respect thereof, pending or eligible for renewals or extensions for, and such rights and all similar or equivalent rights or forms of protection existing now or will exist in any part of the world.</li>
			<li>“Pre-Existing Work” means Intellectual Property owned by Mobile Marketing prior to the Effective Date or developed by Mobile Marketing independent of the Work Product developed for Shaw or Retailer and any Intellectual Property licensed by a non-Affiliate third party to Mobile Marketing or Mobile Marketing’s Affiliates.</li>
			<li>“Retailer Data” means information and, at Retailer’s discretion, other information regarding Retailer, its business, or its relationship with Shaw, including Retailer Materials.</li>
			<li>“Retailer Materials” means all Retailer Intellectual Property code, content, data, designs, documentation, images, information, service marks, trademarks, videos, and other materials provided by Retailer, its employees, or agents.</li>
			<li>“Services” means the professional services provided by Mobile Marketing or its subcontractors in accordance with this Agreement or any Statement of Work.</li>
			<li>“Statement of Work” means a document created by one or both of the Parties describing Services that Service Provider will perform for Retailer, including online orders, ShawNow sign-ups, order forms, purchase orders, and invoices, as well as any accompanying terms and conditions.</li>
			<li>“Velocity Program” means the Velocity retailer marketing program operated by Mobile Marketing and program name owned by Shaw.</li>
			<li>“Website” means any website, mobile application, or other internet-accessible Work Product.</li>
			<li>“Work Product” means Intellectual Property developed by Mobile Marketing for Shaw or Retailer and does not include Pre-Existing Work.</li>
		</ol>
	</li>
	<li>
		<h4>Services</h4>
		<ol type="a">
			<li>Mobile Marketing shall provide Website development, hosting, and/or maintenance services, as well as digital marketing and customer support services in furtherance of the Velocity Program in a manner consistent with this Agreement and any Statement(s) of Work.
				<ol type="i">
					<li>Mobile Marketing shall be solely responsible for all domain name registrations and renewals in connection with any Website covered by this Agreement unless expressly instructed otherwise in writing by Retailer.</li>
					<li>For each Website covered by this Agreement, Mobile Marketing shall post the privacy policy provided to it by Shaw and promptly update such privacy policy when a new version is provided to it by Shaw, except to the extent a Retailer directs Mobile Marketing to use a different privacy policy on its Website. Retailer acknowledges that Shaw has the right to update the privacy policy from time to time at its sole discretion. Both Parties shall strictly adhere to the privacy policy.</li>
					<li>On each Website covered by this Agreement, any external link associated with financing (including the “Apply for Financing” function) shall direct browsers to the official Wells Fargo website, unless specifically requested otherwise by a Retailer for its Website.</li>
					<li>Mobile Marketing shall be solely responsible for the maintenance and operation of Rugs.Shop, including all customer service, expenses, legal or regulatory compliance, orders, payment processing, product warranty claims or obligations, returns, and transactions. Retailer shall have no liability or responsibility for any aspect of Rugs.Shop or any action taken on or through Rugs.Shop. Mobile Marketing shall pay Retailer a rebate equal to 20% of any net sales originating from the applicable Retailer Website.</li>
				</ol>
			</li>
			<li>Design Services. To the extent any Statement(s) of Work requires the delivery of Website code, content, designs, advertisements, promotions, social media posts, or other Work Product, all such Work Product shall be subject to the following acceptance process:
				<ol type="i">
					<li>Mobile Marketing shall promptly notify Retailer in writing of the completion of each item of Work Product. Where appropriate, Mobile Marketing shall deploy such Work Product in a test environment for review and approval by Retailer.</li>
					<li>Upon receipt of a notice that Work Product has been completed, Retailer shall: (i) test where appropriate and evaluate the Work Product to determine whether it substantially conforms to Retailer’s specifications and performance requirements as stated herein and in the applicable Statement(s) of Work; and (ii) will provide a written notice to Mobile Marketing of its acceptance of the Work Product, or provide a written notice of nonconformity specifying why and how the Work Product is not acceptable to Retailer.</li>
					<li>Excluding third party content and Shaw Intellectual Property, Retailer shall have the sole artistic and editorial control with respect to all aspects of the selection, presentation, look and feel, placement, design, headings, layout, and any content of any Website covered by this Agreement, except as set forth herein. With respect to Work Product created in connection with marketing services, including advertisements, promotions, and social media posts, Retailer has the right to accept or reject such Work Product at its sole discretion, excluding third party content and Shaw Intellectual Property.</li>
					<li>If Mobile Marketing receives a notice of a nonconformity from Retailer, it shall promptly and, to the extent such nonconformity is within the defined project scope, at no expense to Retailer: (a) take such steps as are necessary to remedy the error or deficiency to ensure that the Work Product does conform to the applicable description and criteria as set forth above; and (b) provide to Retailer a written notice of remedy.</li>
					<li>Upon receipt of a notice of remedy, Retailer shall conduct such further tests and evaluations on the Work Product as it deems necessary and either accept or reject such Work Product. If the Work Product is not accepted by Retailer, Mobile Marketing shall at its own expense take whatever steps are required by Retailer to ensure that the Work Product is acceptable to Retailer.</li>
					<li>As used herein the term “Final Acceptance” shall mean the receipt by Mobile Marketing of written notification from Retailer that the Services and Work Product have been reviewed and tested by Retailer as a whole and found to: (i) substantially conform to the specifications and descriptions set forth in the applicable Statement of Work; and (ii) conform to Mobile Marketing’s representations and warranties in this Agreement.</li>
					<li>Prior to distributing or otherwise using, posting, or making publicly available any Work Product, Mobile Marketing must receive Retailer’s Final Acceptance of such Work Product.</li>
				</ol>
			</li>
			<li>Hosting Services. To the extent any Statement(s) of Work require Mobile Marketing to provide Retailer any web hosting services (“Hosting Services”), Mobile Marketing shall provide such Hosting Services subject to the following terms:
				<ol type="i">
					<li>Mobile Marketing will provide Retailer with all hardware required for an independent server instance, including connectivity hardware as well as associated peripherals, and shall install, set up, configure, manage, operate, and maintain all such hardware, in accordance with generally accepted industry standards, as part of its provision of the Hosting Services.</li>
					<li>Mobile Marketing shall proactively and continuously monitor and manage the Hosting Services to optimize availability that meets or exceeds the availability requirements set forth in the attached Service Level Agreement. If such monitoring identifies, or Mobile Marketing otherwise becomes aware of, any circumstance that is reasonably likely to threaten the availability of the Hosting Services, Mobile Marketing shall take all necessary and reasonable remedial measures to promptly eliminate such threat and ensure full availability. If Mobile Marketing receives knowledge that the Hosting Services or any Hosting Services function or component is not available, Mobile Marketing will confirm or disconfirm the outage by a direct check of the associated facility or facilities, and, if the outage is confirmed in whole or in part, notify Retailer in writing that an outage has occurred, providing such details as may be available, and resolve all problems causing and caused by the outage.</li>
					<li>Mobile Marketing shall be responsible for repairing, maintaining, and keeping in good working condition throughout the Term in accordance with generally accepted industry standards (“Repairing and Maintaining”) the electrical system, air conditioning equipment, fire suppression system, and all other facilities of the physical location(s) at which it is providing Hosting Services (the “Premises”). Mobile Marketing will provide all necessary electric power to the Premises, as well as a generator to back up the power supply, and shall be solely responsible for all costs of such electric power and the related equipment and infrastructure.</li>
					<li>Mobile Marketing shall be solely responsible for Repairing and Maintaining all equipment and systems used to provide the Hosting Services. Mobile Marketing’s use of any third party services shall not relieve its obligations under this Agreement.</li>
				</ol>
			</li>
			<li>Marketing Services. To the extent any Statement(s) of Work require Mobile Marketing to provide Retailer any marketing services, Mobile Marketing shall provide such services subject to the following terms:
				<ol type="i">
					<li>Retailer has the sole right, at its sole discretion, to control the distribution of all Work Product created in connection with marketing services, including advertisements, promotions, and social media posts. Upon Retailer’s request, Mobile Marketing shall immediately take down, delete, or otherwise disable or limit access to such Work Product, whether or not such Work Product previously received Retailer’s Final Acceptance.</li>
					<li>For any localized advertising programs, Mobile Marketing shall be compensated solely on a per-click/per-item basis or as otherwise detailed in the applicable Statement of Work.</li>
				</ol>
			</li>
			<li>Rugs.Shop Services. To the extent any Retailer Website uses the Rugs.Shop functionality, Mobile Marketing shall provide or arrange for all credit card processing and settlement services. Retailer shall have no liability or responsibility for any aspect of any payment processing conducted on or through any Website covered by this Agreement.
				<ol type="i">
					<li>The Rugs.Shop LLC Ecommerce Affiliate Agreement is included as Appendix B.  As such, acceptance of this Agreement shall accept that agreement as well.</li>
					<li>Mobile Marketing shall be solely responsible for maintaining the databases relevant to such end users, including the obligation to monitor and respect any communications from such end users (including opt-in and opt-out requests), and shall maintain all such information as set forth in Section 12.</li>
					<li>Mobile Marketing will offer the following customer support hours of operation: Monday - Friday– 9AM - 5PM EDT. In the event that demand dictates, Mobile Marketing will consider proactively extending its hours of operation to accommodate such additional demand. In addition, in the event of any additional special campaigns, the Parties agree to meet to agree upon in good faith regarding additional customer service hours for the applicable duration of such campaigns.</li>
				</ol>
			</li>
			<li>Intentionally blank.</li>
			<li>Provision of Labor and Supplies. Mobile Marketing shall perform and furnish all design, work, labor, supervision, services, materials, equipment, tools, transportation, and all other services, facilities, and things necessary to perform and complete the Services described in, or which can be reasonably inferred from, this Agreement or any Statement of Work.</li>
		</ol>
	</li>
	<li>
		<h4>Fees</h4>
		<ol type="a">
			<li>Fees. Fees for the Services (“Fees”) are set forth in individual Statements of Work. Retailer shall not pay any amounts for any travel and other reimbursable expense except where it has given prior written approval of that specific expense.</li>
			<li>Payment. Retailer shall pay all properly invoiced amounts payable and due hereunder within 15 days of Retailer’s receipt of the invoice, except that Retailer may withhold from any payment any charge or amount disputed in good faith by Retailer pending resolution of such dispute. Pending the resolution of such payment dispute, Mobile Marketing shall continue performing its obligations in accordance with this Agreement.</li>
			<li>Reimbursement. Retailer acknowledges that it is solely responsible for paying all Fees. To the extent it is eligible for any reimbursement from Shaw, including from any co-op funds, any request for such funds must be issued directly to Shaw and shall not in any way reduce Retailer’s obligation to pay the Fees owed under this Agreement. Retailer further acknowledges that the use of co-op funds is subject to limitations and rules established by Shaw.</li>
			<li>Taxes. All customs, value-added tax, levies, charges, and other taxes and duties other than income taxes owed by Mobile Marketing (“Taxes”) arising in connection with the Fees shall be paid by Retailer in full without any withholding or deduction whether on account of any set-off, Taxes or otherwise.</li>
		</ol>
	</li>
	<li>
		<h4>Intellectual Property</h4>
		<ol type="a">
			<li>Retailer Materials. Retailer may make certain Retailer Materials available to Mobile Marketing. All Retailer Materials, including all Intellectual Property rights inherent therein and appurtenant thereto, shall remain the sole and exclusive property of Retailer. Retailer hereby grants Mobile Marketing a non-exclusive, revocable, worldwide, royalty-free, limited license to use the Retailer Materials solely during the Term for the purpose of providing the Services contemplated under this Agreement. Mobile Marketing shall have no right to use, reproduce, disclose, distribute, alter, make derivative works, publish, and/or sublicense Retailer Materials for any purpose other than performance of the Services provided for in this Agreement.</li>
			<li>Data. Retailer hereby grants Mobile Marketing the right to grant to Shaw a non-exclusive, worldwide, royalty-free, perpetual, irrevocable license to use, reproduce, disclose, distribute, alter, make derivative works, publish, and/or sublicense all Customer Data and Call Recordings, excluding credit card information, for any purpose without limitation. As between Retailer and Mobile Marketing, Retailer owns all rights and title to the Customer Data and Call Recordings, including all Intellectual Property rights inherent therein and appurtenant thereto.</li>
			<li>Publicity. Neither Party shall use the other Party’s name or any logo, service mark, or trademark or any depiction of any Website or other proprietary material in its marketing, promotion, or website or any press release, except with such Party’s prior written approval of the materials, such approval to be granted at such Party’s sole discretion.</li>
			<li>Work Product. All Work Product is deemed “work-made-for-hire” under U.S. copyright laws (17 U.S.C. §101 et seq.), and all Intellectual Property rights in such Work Product shall be the sole and exclusive property of Retailer. To the extent that ownership of all such rights to any such Work Product may not, by operation of law, vest in Retailer, or may not be considered a work-made-for-hire, then Mobile Marketing hereby irrevocably assigns to Retailer all rights, title and interest in and to the Work Product, including but not limited to all copyrights, patent rights, trade secrets and trademarks. Mobile Marketing waives any moral rights in the Work Product. Further, Mobile Marketing will obtain from itself and any of its Affiliates’ employees, consultants and other persons performing any services on its behalf in connection with the Work Product a written assignment of such persons’ rights as required to permit Mobile Marketing to make the assignment required hereunder. To the extent any Pre-Existing Work is embedded or incorporated in any Work Product, Mobile Marketing hereby grants to Retailer a perpetual, irrevocable, royalty-free, non-exclusive license to use, reproduce, disclose, distribute, alter, make derivative works, publish, and/or sublicense any such Pre-Existing Work.</li>
			<li>Domain Names and Websites. All domain names used in connection with any Website covered by this Agreement shall be owned at all times by Retailer, regardless of whether Retailer is identified as the official registrant of such domain name. All such Websites and the contents thereof shall be owned at all times by Retailer, except to the extent they are owned and provided by Shaw in which case the Parties agree and acknowledge that Shaw retains all rights and title to such materials. Mobile Marketing hereby waives any claim of ownership or title to all Retailer domain names, Websites, and Website contents, and shall cooperate with all Retailer efforts to protect its rights thereto.</li>
		</ol>
	</li>
	<li>
		<h4>Confidential Information</h4>
		<ol type="a">
			<li>Disclosure. Except to fulfill its obligations or exercise its rights under this Agreement, the party that receives Confidential Information (the “Receiver”) shall: (1) not use or disclose to any third party any Confidential Information disclosed by the party that discloses Confidential Information (the “Discloser”); (2) maintain the confidentiality of the information using at least the same degree of care it uses to maintain its own Confidential Information of a similar nature, but in no case less than a commercially reasonable degree of care; and (3) disclose the Discloser’s Confidential Information only to Receiver’s employees, subcontractors, agents or other third parties (“Sub-Receivers”) who have a need to know such information in order to fulfil their duties to Receiver. Prior to commencing work, the Sub-Receivers must sign non-disclosure agreements with Receiver obliging them to maintain the confidentiality of the Confidential Information using at least the same standard of care required of Receiver under this Agreement. Receiver is responsible for any use or disclosure of Discloser’s Confidential Information by its Sub-Receivers.</li>
			<li>Permissions. Notwithstanding anything else in this Agreement: (i) Retailer has the right to freely disclose Mobile Marketing Confidential Information to Shaw and Shaw’s Affiliates solely for the purpose of marketing, implementing, running, and supporting the Velocity Program; and (ii) Mobile Marketing has the right to freely disclose Retailer Confidential Information to Shaw.</li>
			<li>Court Order. If the Receiver becomes legally obliged, whether by judicial or administrative order, applicable law, rule, regulation or otherwise, to disclose a Discloser’s Confidential Information, the Receiver shall use all reasonable efforts to notify the Discloser so the Discloser may seek a protective order or other remedy to prevent such disclosure. If the Discloser is not able to obtain such protective order or remedy prior to the time disclosure is required, the Receiver will disclose only that portion of the Confidential Information that the Receiver is required to disclose.</li>
			<li>Equitable Relief. Notwithstanding any other provision of this Agreement, either Party may apply to a court of competent jurisdiction seeking injunctive or other equitable relief for a breach of this Section.</li>
		</ol>
	</li>
	<li>
		<h4>Information Security and Back-Ups.</h4>
		<p>Mobile Marketing shall implement and maintain a comprehensive written information security program (“WISP”) containing administrative, physical, technical, and procedural safeguards consistent with reasonable industry practices and sufficient to (i) protect the security, confidentiality, and integrity of its networks and systems; and (ii) secure Retailer Confidential Information from unauthorized access, destruction, use, loss, modification, or disclosure. Mobile Marketing’s WISP shall, at a minimum: (i) designate an individual who shall be responsible for Mobile Marketing’s implementation of the WISP, ensuring Mobile Marketing’s ongoing compliance with the WISP, and providing all necessary training; (ii) establish procedures for assessing and monitoring the information security practices and protections of third party service providers and contractors; (iii) establish data breach and security incident response procedures, including customer notification requirements, internal reporting requirements, governmental and law enforcement notification plans, remediation measures, and post-incident systems assessments; (iv) establish technical controls including user identification and access controls, restricting access rights of visitors and of former and terminated employees, encryption of Confidential Information during transmission, updated firewall and anti-malware software, and intrusion detection and counter-measures; and (v) establish internal controls including password policies, employee handbooks, and information disposal practices.</p>
		<p>Mobile Marketing shall also maintain or cause to be maintained disaster avoidance procedures designed to safeguard the availability of the Services and all Retailer Data in Mobile Marketing’s custody or control, and conduct daily backups of Retailer Data and store such backup data in a commercially reasonable location and manner. Upon request from Retailer, Mobile Marketing shall, at its own expense, provide Retailer with a copy of the backed up Retailer Data in such format as Retailer reasonably requests. No backup of Retailer Data shall be counted in allotting or calculating any data storage actually used or permitted to be used by Retailer, nor shall it incur any associated payment or fee. Mobile Marketing’s disaster recovery and back-up obligations under this Section are not limited by any force majeure provision.</p>
	</li>
	<li>
		<h4>Indemnification</h4>
		<ol type="a">
			<li>Mobile Marketing shall indemnify, defend and hold harmless Retailer, its Affiliates, officers, directors, employees, agents and customers (the “Indemnitees”) from and against any and all loss, liability, cost, damages and expense, including attorneys’ fees, which may be incurred by Indemnitees in connection with any claims, actions, or demands by a third party arising out of or related to: (a) a breach of this Agreement by Mobile Marketing; (b) any violation of law, regulation, rule, or licensing requirement by Mobile Marketing or its employees or agents; (c) negligence, willful misconduct, or misrepresentation by Mobile Marketing or its employees or agents; (d) any actual or alleged infringement, misappropriation, or other violation of any Intellectual Property right or other right of a third party attributable to the Work Product or Services or Retailer’s authorized use of the Work Product or Services; (e) Mobile Marketing’s use of any privacy policy on a Website covered by this Agreement other than the most recent version of the privacy policy provided by Shaw; and (f) Mobile Marketing’s failure to strictly adhere to the most recent version of the privacy policy provided by Shaw.</li>
			<li>Retailer shall indemnify, defend and hold harmless Mobile Marketing, its Affiliates, officers, directors, employees, and agents (the “Mobile Marketing Indemnitees”) from and against any and all loss, liability, cost, damages and expense, including attorneys’ fees, which may be incurred by Mobile Marketing Indemnitees arising out of or related to: (a) a breach of this Agreement by Retailer; (b) any violation of applicable law or regulation by Retailer or its employees or agents; and (c) negligence, willful misconduct, or misrepresentation by Retailer or its employees or agents.</li>
		</ol>
	</li>
	<li>
		<h4>Representations and Warranties</h4>
		<ol type="a">
			<li>By Mobile Marketing. Mobile Marketing represents and warrants that:
				<ol type="i">
					<li>It will perform the Services in a good and workmanlike manner using personnel experienced and highly skilled in their profession and in accordance with the highest industry standards and applicable standards of professionalism for comparable or similar services, that it shall be responsible for the professional quality, timeliness, coordination, and completeness of the Services, and that the Services and Work Product will conform to the descriptions ad specifications set forth in the applicable Statement of Work and are and will be fit for their particular intended purpose.</li>
					<li>It is and will be in compliance with all applicable foreign and domestic laws, regulations, rules, and licensing requirements during the Term, including CAN-SPAM, TCPA, CASL, all information security and data privacy laws and regulations, and all other laws or regulations that require notification of individuals in the event of the unauthorized release of information, and shall not, by any act or omission (where it has an obligation to act under this Agreement), cause Retailer to be non-compliant with any applicable law, regulation, rule, or licensing requirement.</li>
					<li>The Work Product and all other materials used or provided by Mobile Marketing in connection with the Services, and Retailer’s authorized use of the Work Product and Services, do not and will not infringe upon or misappropriate any Intellectual Property rights or other rights of any third party, and Mobile Marketing has no actual knowledge of any infringement claims against it in connection with such materials.</li>
					<li>Mobile Marketing will utilize up-to-date and comprehensive virus protection capabilities, and industry best practices, including detection, scanning and removal of known viruses, worms and other malware on Mobile Marketing’s systems. Mobile Marketing warrants that these virus protection capabilities will be in force on all computers and/or devices utilized in connection with its Services, as well as on all data files or other transfers that have access or are connected to the Mobile Marketing system. The Work Product and all other materials used or provided by Mobile Marketing in connection with the Services do not and will not contain, and Mobile Marketing shall not in the future introduce or allow to be introduced, any programming devices (e.g., viruses, adware, spyware, malware, malicious codes, key locks, back doors, trap doors, shut down keys, etc.) which would (a) disrupt the use of the Work Product, or any Retailer’s network or system; (b) destroy or damage data or make data inaccessible or delayed; or (c) permit any individual access to any Retailer network or system after having their approved access privileges revoked or suspended.</li>
					<li>It has the full authority and sufficient technical and human resources to enter into and perform its obligations under this Agreement, and there are no pending claims or litigation against it that might affect its ability to carry out its obligations under this Agreement.</li>
				</ol>
			</li>
			<li>By Retailer. Retailer represents and warrants that it has the full authority to enter into and perform its obligations under this Agreement.</li>
			<li>Remedies. In the event of Mobile Marketing’s breach of the warranty set forth in Section 8(a)(i), it shall promptly re-perform the deficient Services until the Services are performed in accordance with the warranties. This remedy is non-exclusive, and Retailer may be entitled to additional damages and/or remedies</li>
		</ol>
	</li>
	<li><h4>Limitations of Liability. TO THE MAXIMUM EXTENT ALLOWED UNDER APPLICABLE LAW, RETAILER’S AGGREGATE LIABILITY ARISING FROM OR RELATING TO THIS AGREEMENT IS LIMITED TO THE FEES PAID BY RETAILER DURING THE 12-MONTH PERIOD PRIOR TO THE DAY THE EVENT GIVING RISE TO THE CLAIM FIRST AROSE. TO THE MAXIMUM EXTENT ALLOWABLE UNDER APPLICABLE LAW, RETAILER SHALL NOT BE LIABLE FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, INDIRECT OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION LOST REVENUES, LOSS OF DATA, EVEN IF RETAILER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</h4></li>
	<li>
		<h4>Term and Termination</h4>
		<ol type="a">
			<li>Term of the Agreement. Unless terminated earlier in accordance with Section 10.b, the term of this Agreement shall begin on the Effective Date and shall continue for one (1) year (the “Initial Term”). After the Initial Term, this Agreement shall renew automatically for successive one (1) year periods unless: (i) one Party gives notice to the other at least ninety (90) days prior to the expiration of the then-current term; or (ii) the Agreement is terminated pursuant to Section 10.b. The Initial Term plus all renewal periods is the “Term.”</li>
			<li>Pilot Program Participants. In the event Retailer participated in the pilot version of the Velocity Program that concluded in March of 2019, the Parties hereby agree that this Agreement is effective and binding retroactively, beginning as of the date on which Mobile Marketing first provided services to Retailer in connection with such pilot program. This retroactive effect shall not in any way alter the duration of the Initial Term as defined above.</li>
			<li>Termination. Either Party may terminate this Agreement: (i) for the other Party’s material breach, by providing notice, with a 30-day cure period; or (ii) immediately if, without a successor, the other Party becomes insolvent, dissolves, liquidates, or ceases to conduct its business operations in the ordinary course. Retailer may terminate this Agreement without cause by providing thirty (30) days written notice. Mobile Marketing shall also have the right to terminate this Agreement in the event the Retailer Velocity Program executed by Mobile Marketing and Shaw terminates for any reason and Shaw instructs Mobile Marketing to terminate this Agreement.</li>
			<li>Effect of Termination. Upon termination of this Agreement for any reason:
				<ol type="i">
					<li>Mobile Marketing’s rights to use Customer Data, Retailer Data, Work Product, Retailer Materials, and Retailer Intellectual Property all non-perpetual licenses granted hereunder, terminate immediately except as necessary for performance of Transition Services, in which case all non-perpetual rights shall terminate immediately upon completion of Transition Services.</li>
					<li>Upon Retailer’s request, Mobile Marketing shall provide transition services to Retailer prior to and up to six (6) months following the termination or expiration of the Agreement to ensure a smooth and effective change of responsibility for all Services provided by Mobile Marketing (“Transition Services”) under this Agreement. The Transition Services shall include, at a minimum, promptly: (a) developing and executing a plan for the orderly transition of Services to the successor service provider or such other entity designed by Retailer, such transition to provide for a seamless cut-over of Services at the time and in the manner directed by Retailer; (b) transferring all Retailer Data (including Websites and all associated content) to the successor service provider or such other entity or entities designated by Shaw or Retailer in media and format requested by Shaw or Retailer and as directed by Shaw or Retailer; (c) transferring all domain names associated with Retailer Websites to a new registrar designated by Shaw or Retailer and, if requested by Shaw or Retailer, changing the registrant of such domain names to Retailer; (d) using commercially reasonable efforts to make available to Retailer, pursuant to mutually agreeable terms and conditions, any third party software, content, or services then being used by Retailer in connection with Mobile Marketing’s provision of services; and (e) such other activities upon which Mobile Marketing and Shaw or Retailer agree.</li>
					<li>Upon completion of the Transition Services or notice from Retailer that no Transition Services will be required, each Party will return to the other Party all of the other Party’s Confidential Information or permanently delete such Confidential Information in a manner consistent with then-current industry standards. Upon request, such Party shall certify to the other Party that all of such Party’s Confidential Information in its possession, custody, and control has been deleted consistent with this requirement.</li>
				</ol>
			</li>
		</ol>
	</li>
	<li>
		<h4>Insurance.</h4>
		Mobile Marketing will maintain Commercial General Liability Insurance, including Product Liability, Completed Operations, Umbrella, and Commercial Automobile Liability (arising out of the use of vehicles) insurance having at a minimum limits of no less than $1,000,000 per occurrence with Shaw named as an additional insured. Mobile Marketing shall maintain Worker’s Compensation Insurance providing statutory coverage and employer’s liability covering Mobile Marketing’s employees and having a minimum limit of at least $1,000,000, dependent upon state requirements.
	</li>
	<li>
		<h4>Relationship of Parties.</h4>
		Mobile Marketing and Retailer are independent contractors. Mobile Marketing acknowledges that it has no authority to bind or contract in the name or for the account of Retailer, to create any liability against Retailer, or to exert any direction or control over Retailer’s personnel. Mobile Marketing is solely responsible for all taxes and insurance with respect to its personnel.
	</li>
	<li>
		<h4>Entire Agreement; Modifications.</h4>
		This Agreement constitutes the entire agreement between Retailer and Mobile Marketing with respect to the subject matter thereof, and supersedes all prior oral or written agreements. This Agreement shall govern the Services, unless otherwise expressly agreed to by the Parties. This Agreement may not be amended or modified, except by a further written agreement signed by the Parties hereto.
	</li>
	<li>
		<h4>Force Majeure.</h4>
		Neither Party shall be liable to the other for any delay or failure in performing its obligations under this Agreement to the extent that such delay or failure is caused by an event or circumstance that is beyond the reasonable control of that party, without such Party’s fault or negligence, and which by its nature could not have been foreseen by such Party or, if it could have been foreseen, was unavoidable (“Force Majeure Event”). Force Majeure Events include, but are not limited to, acts of God, government restrictions, floods, fire, hurricanes, earthquakes, explosion, epidemic, war, invasion, hostilities, terrorist acts, riots, strike, embargoes, or industrial disturbances. Mobile Marketing’s economic hardship or changes in market conditions do not constitute Force Majeure Events. Mobile Marketing shall use all diligent efforts to end the failure or delay of its performance, ensure that the effects of any Force Majeure Event are minimized, and resume performance under this Agreement as soon as possible. If a Force Majeure Event prevents Mobile Marketing from carrying out its obligations under this Agreement for a continuous period of more than ten (10) business days, Retailer may terminate this Agreement, in whole or part, immediately by giving notice to Mobile Marketing.
	</li>
	<li>
		<h4>Non-Exclusive Rights.</h4>
		This Agreement does not grant to Mobile Marketing any exclusive privileges or rights to provide to Retailer goods, products, materials, or services of any type that Retailer may require, nor does this Agreement require the purchase of such goods, products, materials, or services by Retailer. Retailer may contract with other companies or individuals for the procurement of comparable goods, products, materials, or services or may cause such goods, products, materials, or services to be performed by Retailer’s own personnel.
	</li>
	<li>
		<h4>Governing Law; Severability.</h4>
		This Agreement shall be governed by, and construed in accordance with, the laws of the State of Georgia, without reference to any conflicts of law principles. The Parties hereby submit themselves to the exclusive jurisdiction of the federal and state courts located in Whitfield County, Georgia for any disputes arising hereunder. The Uniform Computer Information Transactions Act and the U.N. Convention on Contracts for the International Sale of Goods shall not apply to this Agreement. If any term, provision or condition of an Agreement is held invalid or unenforceable, the same shall not invalidate or otherwise affect any other provision thereof. Any reproduction of an Agreement by any reliable means shall be deemed an original. Any claim against Retailer must be brought within one year of the event giving rise the claim or be barred forever.
	</li>
	<li>
		<h4>Notices.</h4>
		Notices provided from by either Party to the other under this Agreement shall be in writing to the agent or contact person designated by Retailer and Mobile Marketing.
	</li>
	<li>
		<h4>Time Is of the Essence.</h4>
		The Parties agree that time is of the essence in the performance of Services hereunder.
	</li>
	<li>
		<h4>Assignability; Waiver.</h4>
		Without Retailer’s written consent, Mobile Marketing shall not assign this Agreement, or any rights or obligations herein and any attempt to assign, or assignment of, this Agreement, in whole or in part, by operation of law or otherwise, without such consent shall be null and void. No waiver of any term, right, remedy, or condition of this Agreement shall be valid unless it is set forth in a writing duly executed by both Parties. No delay or failure by Retailer to exercise or enforce at any time any right, remedy, or provision of this Agreement will be considered a waiver thereof or of Retailer’s right thereafter to exercise or enforce each and every right, remedy, and provision of this Agreement. No waiver of any right by Retailer shall be deemed a waiver of such right on other occasions.
	</li>
	<li>
		<h4>Survival.</h4>
		Notwithstanding any provisions to the contrary contained in this Agreement, any provision herein which (a) by its terms state or evidence the intent of the Parties that the provisions survive the expiration or termination thereof or (b) must survive to give effect to such provision, shall survive the expiration or termination of this Agreement.
	</li>
	<li>
		<h4>Miscellaneous.</h4>
		The rights and remedies under this Agreement are cumulative and are in addition to any other rights and remedies available at law or in equity or otherwise. The headings of the Sections of this Agreement are inserted for convenience or reference only and are not intended to be part of, or to affect, the meaning or interpretation of this Agreement. This Agreement is for the sole benefit of the Parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or shall confer upon any other person or entity any legal or equitable right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.
	</li>
</ol>
<p>IN WITNESS WHEREOF, Mobile Marketing and Retailer have caused this Agreement to be executed by their duly authorized representatives as of the Effective Date.</p>

<h3 style={title}>ADDITIONAL PROGRAM DETAILS</h3>
<h4>INVOICING</h4>
<ul>
	<li>Invoices are produced at the beginning of the month for the following month. This practice allows for invoicing and payment before advertising and website maintenance charges are incurred by Mobile Marketing.</li>
	<li>Additional advertising can be requested at any time. Charges will be invoiced as a supplemental invoice or placed on the next monthly invoice, depending on the start date of the additional advertising.</li>
	<li>Additional site features, as requested, are billed on a client’s next monthly invoice. Examples include email renewals, email mailbox size increases, domain charges, etc.</li>
	<li>All invoice terms are NET 15.</li>
</ul>
<h4>PAYMENT</h4>
<ul>
	<li>Payments can be made by check, credit card, or wire transfer.</li>
	<li>Auto-pay options include credit card on file and ACH (wire transfer) on file. Clients must fill out either the Credit Card Authorization form or the ACH Wire Transfer Authorization form. Once received, Mobile Marketing will pay all prior invoices at one time (unless prior arrangements are worked out). Future invoices will be paid on the day they are created.</li>
</ul>	
<h4>SEPARATION</h4>
<ul>
	<li>Separation activities will commence only after all outstanding Mobile Marketing invoices are paid.</li>
	<li>Separation will occur within the 45 day period from notification. The client will be immediately billed for the hosting and site maintenance charges required to span the 45 day work period.</li>
	<li>Separation from the Velocity program grants the Retailer the following assets and transition activities :
		<ul>
			<li>Domain (including any associated email services)</li>
			<li>All existing site pages (pages, content, navigation)</li>
			<li>All Blogs</li>
			<li>Continuity of Google Analytics</li>
			<li>Return of Facebook account credit card to client’s card</li>
			<li>Removal of client’s credit card information from Mobile Marketing billing system (if present)</li>
		</ul>
	</li>
	<li>Separation from the Velocity program removes the following Mobile Marketing assets :
		<ul>
			<li>Item details</li>
			<li>Item catalog management service</li>
			<li>Reputation management service</li>
			<li>Automated lead tracking</li>
			<li>Mobile business cards</li>
			<li>Social advertising</li>
			<li>Google Paid advertising</li>
			<li>Call tracking numbers (replaced by 999-999-9999)</li>
			<li>Automated email responses (client can enable email responses on site forms)</li>
			<li>Rugs.Shop (could be offered on their new site, if desired)</li>
			<li>Website hosting</li>
			<li>Image hosting and management</li>
			<li>Website security certificate management</li>
		</ul>
	</li>
</ul>	
<h4>TERM</h4>
<ul>
	<li>The Velocity program is a 12-month term, with automatic renewal as of the day your site went live.</li>
	<li>Cancellation of the automatic renewal must be made in writing 45 days before your renewal date.</li>
</ul>
<h4>Billing Details</h4>
<p style={allcaps}><em>VELOCITY {(currentClient) && (currentClient.servicePackage)}</em></p>

<h3 style={title}>Appendix A</h3>
 
<p>Go Live Date: {(prodSite.goLive) && (formatDateDisplay(prodSite.goLive))}</p>
<p>Term Renewal Date: {(prodSite.termRenewal) && (formatDateDisplay(prodSite.termRenewal))} Annually</p>



 
<h3 style={title}>Appendix B</h3>
<h3 style={title}>Rugs.shop LLC. Ecommerce Affiliate Agreement </h3>

<ol>
	<li>
		<h4>The Parties:</h4>
		This Ecommerce Affiliate Agreement (the “Agreement”), made and entered by and between Rugs.shop LLC. with its principal place of business at 341 10th Ave Suite 201 Royersford, PA 19468, and you, the affiliate (referred to henceforth as "you" or "Affiliate"), contains the complete terms and conditions that apply to your participation in the Rug Shop Website Affiliate Program (the "Affiliate Program" or "Program"). Your participation in the Affiliate Program is non-exclusive as there are and will be other Affiliates.
	</li>
	<li>
		<h4>Term and Termination:</h4>
		<ol>
			<li>The term of this Agreement will begin upon our acceptance of your Affiliate Application (“Effective Date”) and will end when terminated by either party as provided for herein. By agreeing to the terms and conditions herein, this Agreement is the entire agreement between the parties and supersedes all prior communications, understandings and agreements relating to the Affiliate Program, whether oral or written. Either you or we may terminate this Agreement at any time, with or without cause, by giving the other party written notice of termination. You are eligible to earn referral fees on referrals occurring only during the term of this Agreement. Upon termination of this Agreement for any reason, you shall immediately cease to represent that you are an Affiliate of Rug.shop LLC., and you shall immediately cease all use of Rugs.shop LLC website services and/or any of web priorities, admin portals, our logos and/or trademarks, and you shall remove from your site, all links to our serviced websites, and all Rugs.shop LLC trademarks and those of any Rugs.shop LLC company, trade dress, and logos, and any materials provided by or on behalf of us to you pursuant to this Agreement and/or in connection with the Program including Rugs.shop LLC.</li>
			<li>Rugs.shop LLC reserves the right to terminate any accounts we deem as "inactive" accounts. An affiliate account would be considered "inactive" if it fails to generate any sales for a period of at least six (6) months. </li>
			<li>The right of termination of this Agreement is absolute and neither you nor we shall incur any liability by reason thereof. We and you mutually release the other from any claim of any nature arising out of such termination, provided however, that nothing in this Agreement shall be construed as a release of any referral fee obligation which shall have accrued from us to you prior to the effective date of such termination.</li>
		</ol>
	</li>
	<li>
		<h4>Enrollment in the Program:</h4>
		You will submit an Affiliate Program request. We may reject your request (at our sole discretion) if we feel your site is unsuitable or inconsistent with the mission of Rugs.Shop LLC. If your site is accepted, Rugs.shop LLC may terminate this Agreement anytime thereafter if your site is determined (at our sole discretion) to be unsuitable for the Program, even if your site has not changed since the time that your Affiliate Program application was accepted. You hereby acknowledge that we can rely on all of your representations in your request.  You may leverage your personal branded Rugs.shop website however, it is disclosed in the privacy policy and terms and conditions as well as during checkout to consumers that Rugs.shop LLC is the seller of record.
	</li>
	<li>
		<h4>Promotion of our Affiliate Program:</h4>
		Upon your acceptance as an Affiliate Site, we will make available to you, an affiliate branded, fully serviced ecommerce website site and service. You may use, link, promote and display this website as often and in as many areas as you desire online and offline. The Links will serve to channel potential buyers to the privately branded ecommerce website where you will be paid a commission on all sales resulting from the website.
	</li>
	<li>
		<h4>Rugs.shop LLC. Agrees To:</h4>
		<ol>
			<li>Pay Affiliate a commission (see Referral Commissions for commission details) for each qualified online order completed via your affiliate linked ecommerce website and serviced by Rugs.shop LLC.  Your earned commissions shall be the only consideration from us to you. </li>
			<li>We will pay Affiliates on a quarterly basis when commissions due have reached a minimum of $25.00. Accounts with a balance of less than $25.00 will roll over to the next quarter. Rugs.shop LLC sends out payments to affiliates within 15 days from the end of each calendar quarter for the previous quarter’s commissions.</li>
		</ol>
	</li>
	<li>
		<h4>Affiliate Agrees To:</h4>
		<ol>
			<li>Place banners and/or text links from your site, use online and offline advertising or any other methods of your choice to promote your privately serviced ecommerce rugs website. </li>
			<li>Observe and honor Rugs.shop LLC's exclusive right of ownership as to the subdomain names of its properties, including rugs.shop/?store=retailername. We grant you a limited right to the use of its trademarked names solely for promotional purposes in conjunction with the relationship as described in this Agreement.  We may also consider allowing a specified new domain, however this will be determined on a case-by-case basis.</li>
			<li>Ensure that all materials provided to use are not illegal and do not infringe on the rights of any person or entity of any kind. We disclaim all liability for any materials used on your own websites to promote these products and services.</li>
			<li>Indemnify, defend, and hold us harmless from all claims, damages, and expenses relating to the development, operation, and contents of your privately branded and serviced website.</li>
			<li>Not copy directly or indirectly for your private use the look and feel of any of the Rugs.shop LLC websites and services or create copy our products and services.</li>
			<li>If any immoral or unethical links are added to your site pointing to Rugs.Shop we reserve the right to pursue all legal courses of action against you to the fullest extent possible under the law and you will be responsible for returning all commissions earned to us as a result of such links to Rug.shop.</li>
			<li>Obtain all permits, licenses, and approvals necessary to perform this Agreement.</li>
			<li>Perform this Agreement in a manner that reflects favorably upon Rugs.shop and our business reputation. Affiliate shall not present or cast our name, our products or services, logo or trademarks in a disparaging or negative manner of any kind.</li>
			<li>Comply with all applicable federal, state, and local laws, statutes, regulations, ordinances and other legislative or administrative rules imposed or required by any legal public authority having jurisdiction or authority, including and not limited to the Federal FTC Act and the CAN-SPAM Act.</li>
			<li>Not use any part of any compensation paid to you under this Agreement to pay or accrue for the benefit of, directly or indirectly, anyone who is an official, agent, director, officer or employee of any governmental entity.</li>
			<li>Make no representation with respect to our products or services which in any way conflicts or is inconsistent with our products or services descriptions, performance, specifications, features, and functionality as published or provided by us or make or pass on to any individual or entity any warranty or representation on our behalf.</li>
			<li>Continuously retained our copyright attributions, notices and markings on all demonstration articles and marketing collateral and literature, and not in any way obliterate or alter same.</li>
		</ol>
	</li>
	<li>
		<h4>Referral Commissions:</h4>
		<ol>
			<li>Rugs.shop LLC affiliate program pays commissions for each qualified subscription Net Sale (As defined under Section 7.2 below). A qualified sale consists of an online transaction placed and successfully paid for without return. </li>
			<li>
				The affiliate team will audit all referred orders to ensure they are valid. The amount of commissions due to affiliates will be as follows for each valid online order submitted by a visitor as a direct result of sales through your privately branded website.
				<p>Affiliate Profit Share/Commission:</p>
				<p>1. Scenario 1 – Straight 20% commission on all orders off the net sale amount.</p>
				<p>Net Sale commission is calculated as follows.</p>
				<p><strong>20% of Sale (less tax and shipping)</strong></p>
				<p>Commission is calculated as follows (example)</p>
				<p>Consumer Orders Area Rug Online              $ 3,100.00 (rug only)</p>
				<p>Customer 10% Coupon Applies of Sale        $    310.00 (customer coupon)</p>
				<p>Net Sale Amount                                           $  2,790.00 (*net sale amount)</p>
				<p>20% Affiliate Commission                             $    558.00 (commission)</p>
				<p>Note: * Net Sales are all valid order transactions minus any chargebacks, refunds and returns. (if retailer chooses to have a 5% retailer sales rep commission program enabled, retailer will need to pay their employees directly; however detailed reporting will be provided to retailer with amounts). Commission is not paid on tax and shipping. If Free shipping is offered it will be deducted from sale price before commissions are paid.</p>
			</li>
			<li>Rugs.shop LLC may offer additional periodic bonuses and performance incentives.</li>
		</ol>
	</li>
	<li>
		<h4>Commission Payment Procedures:</h4>
		<ol>
			<li>Rugs.shop LLC will issue payment to affiliates within 30 days of the end of each calendar quarter for all commissions earned on sales during the prior quarter.  This may be changed to monthly once commissions are over $100 for 3 months continuous.</li>
			<li>All affiliates who have met the $25 minimum payout requirement will receive the full amount of commissions due, less any amount Rugs.shop LLC determines, in its sole discretion, not validly earned from a proper use. Once a year Rugs.shop LLC. will provide an audit of all transactional data if affiliate requests and had met a minimum of $100 monthly.</li>
			<li>Payments will be issued via check or direct deposit, payable to you at the address submitted by you in your registration.</li>
		</ol>
	</li>
	<li>
		<h4>License / Setup Fee:</h4>
		<ol>
			<li>Affiliate shall pay to Rugs.shop LLC.
				<p>Setup Fee: $0 (included in Shaw Web Services Program)</p>
				<p>Monthly License Fee: $0 (included in Shaw Web Services Program)</p>
			</li>
		</ol>
	</li>
	<li>
		<h4>Tracking:</h4>
		<ol>
			<li>We will be solely responsible for tracking referrals and providing monthly website analytics for your site through our Google analytics as requested.</li>
			<li>Commissions are tracked through your specific ecommerce website.  </li>
			<li>Rugs.shop LLC uses a referral url to as a measuring system for each affiliate branded website and services.  </li>
			<li>Rugs.shop LLC will endeavor to do its best to ensure accurate tracking of referrals made by all affiliates. All affiliates will themselves be solely responsible for ensuring that their URLs within their own web properties are formatted properly leading to your branded ecommerce website.
				<ol type="a">
					<li>Your failure to use proper integration and linking from your existing websites for promotion of the ecommerce site and other links, ads.  It is the responsibility of the affiliate for all online demand generation tactics.  The Rug Shop website powered by Rugs.shop LLC will not be responsible for the promotion of your branded site other than providing some best practices and idea sharing.</li>
					<li>Deliberate or accidental actions by customers to circumvent your private branded website. </li>
					<li>Bugs, glitches or crashes of any software that render it unusable.</li>
					<li>Acts of nature that cause irretrievable data loss on the computers and back-up media that store information.  We do have redundant backups to provide some level of additional protection, however this cannot be 100% guaranteed.</li>
				</ol>
			</li>
			<li>As such, you will not hold Rugs.shop liable to compensate for any claimed commissions that were not tracked and recorded by the software.</li>
		</ol>
	</li>
	<li>
		<h4>Confidentiality:</h4>
		Except as otherwise provided in this Agreement or with the consent of the other party hereto, each of the parties hereto agrees that all information, including, without limitation, the terms of this Agreement, business and financial information, customer and vendor lists, and pricing and sales information, concerning us or you, respectively, or any of our Affiliates provided by or on behalf of any of them shall remain strictly confidential and secret and shall not be utilized, directly or indirectly, by such party for its own business purposes or for any other purpose except and solely to the extent that any such information is generally known or available to the public through a source or sources other than such party hereto or its affiliates. This provision shall survive termination of this Agreement.
	</li>
	<li>
		<h4>Limitation of Liability:</h4>
		We will not be liable for indirect, special, or consequential damages, or any loss of revenue, profits, or data, arising in connection with this Agreement or the affiliate relationship, even if we have been advised of the possibility of such damages. Further, our aggregate liability arising with respect to this Agreement and the Affiliate Program will not exceed the total commissions paid or payable to you under this Agreement.
	</li>
	<li>
		<h4>Limited License:</h4>
		We grant you a nonexclusive, nontransferable, revocable right to a branded and serviced rug website in accordance with the terms of this Agreement, for the sole purpose of linking from your site to our site and services, solely for the purpose of identifying your site as a participant in the Program and assisting in promoting ecommerce sales. We may revoke your license anytime by giving you written notice.
	</li>
	<li>
		<h4>Disclaimers:</h4>
		We make no express or implied warranties or representations with respect to the Rugs.shop LLC Affiliate Program (including, without limitation, warranties of fitness, merchantability, non-infringement, or any implied warranties arising out of course of performance, dealing, or trade usage). In addition, we make no representation that the operation of our site will be uninterrupted or error-free, and we will not be liable for the consequences of any interruptions or errors.
	</li>
	<li>
		<h4>Modifications:</h4>
		We may modify any of the terms and conditions of this Agreement at any time. You will be notified by e-mail, using the e-mail address currently listed in your Affiliate registration and a change notice will be sent to you. If the modifications are unacceptable to you, your only recourse is to terminate this Agreement. Your continued participation in this Affiliate Program after a change notice has been posted will constitute binding acceptance of the change.
	</li>
	<li>
		<h4>Independent Contractors:</h4>
		You and Rugs.shop LLC are independent contractors and nothing in this Agreement is intended nor shall be construed to create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between the parties. You will have no authority to make or accept any offers or representations on our behalf.
	</li>
	<li>
		<h4>Representations and Warranties:</h4>
		You hereby represent and warrant to us as follows:
		<ol>
			<li>This Agreement constitutes your legal, valid, and binding obligation, enforceable against you in accordance with its terms.</li>
			<li>The development, operation, and contents of your site do not and shall not infringe upon the copyright, trademark, or any other right of any person or entity.</li>
		</ol>
	</li>
	<li>
		<h4>Independent Investigation:</h4>
		You acknowledge that you have read this Agreement and agree to all its terms and conditions. You understand that we may at any time (directly or indirectly) solicit customer referrals on terms that may differ from those contained in this Agreement or operate websites that are similar to or compete with your website. You have independently evaluated the desirability of participating in Rugs.shop LLC Affiliate Program and are not relying on any representation, guarantee, or statement other than as set forth in this Agreement.
	</li>
	<li>
		<h4>Fraud:</h4>
		If you commit fraud or falsify information in connection with the submission of referrals to Rugs.shop LLC services through the Links on your site, this Agreement will be terminated immediately. In addition, you will be liable to Rugs.shop LLC for any and all damages that Rugs.shop LLC suffers as a result of such actions. You will also be responsible for returning to Rugs.shop LLC all commissions received for fraudulent/falsified referrals submitted, and we will not hesitate to pursue all legal courses of action against you to the fullest extent possible under the law.
	</li>
	<li>
		<h4>Anti-Predatory Advertising:</h4>
		<ol>
			<li>Affiliates will be removed from Rugs.shop LLC Affiliate Program and forfeit any pending commissions if they are utilizing diversion techniques.</li>
			<li>Such systems include those commonly known as "ParasiteWare" or that carry out functions commonly known as "Parasitic Marketing." ParasiteWare is software (including, but not restricted to, browser helpers, browser plug-ins, toolbars and pop ups/sliders) that knowingly or unknowingly undermines the program. ParasiteWare may be installed knowingly or unknowingly by the end user, altering normal web browser functions and/or installing a third party application that works through the user's altered browser.</li>
			<li>Affiliate may use all industry standard online advertising techniques to drive traffic through to the ecommerce website. Including natural search, paid search, retargeting, remarketing, mobile marketing, social marketing and other traditional online and offline advertising.</li>
			<li>Affiliate agrees not to use any technique or marketing program that might reasonably be considered manipulative. Rugs.shop LLC reserves the right to determine whether a technique or program is unreasonable. In the event of a breach of this Agreement by Affiliate and/or a third party or licensee, Rugs.shop LLC shall immediately terminate this agreement, and, in addition to all other remedies available to it, Rugs.shop LLC shall have the right to refuse to make payments to an Affiliate as a result of the Affiliate's efforts promoting Rugs.shop LLC in such a manner.</li>
			<li>Affiliate agrees to indemnify, defend and hold Rugs.shop LLC harmless from any cost, expense or liability arising out of any breach or alleged breach of your obligations under this Anti-Predatory Policy.</li>
		</ol>
	</li>
	<li>
		<h4>E-mail Marketing and Endorsements:</h4>
		<ol>
			<li>Affiliate agrees to comply with the requirements of the CAN-SPAM Act of 2003 (the "Act") in its distribution of e-mail that contains messaging regarding Rugs.shop LLC. More specifically, e-mail must meet these criteria:
				<ol type="a">
					<li>May only be sent to recipients who have expressly agreed directly with Affiliate, in advance, to receive such communications from Affiliate.</li>
					<li>Must clearly and conspicuously identify that the message is an advertisement or solicitation unless recipient has given prior affirmative consent to receipt of the message. Affirmative consent means that the recipient expressly consented to receive the message either in response to a clear and conspicuous request for such consent or at the recipient's own initiative.</li>
					<li>Must clearly and conspicuously notify the recipient of the opportunity to decline to receive further commercial e-mail from Affiliate.</li>
					<li>Must provide to us a valid physical postal address of Affiliate.</li>
					<li>Must provide a functioning return e-mail address or other Internet-based mechanism, clearly and conspicuously displayed, that a recipient may use to submit, in a manner specified in the commercial e-mail, a reply e-mail or other Internet-based mechanism a request not to receive future commercial e-mail from Affiliate. </li>
					<li>May not contain materially false or materially misleading header information, or deceptive subject heading.</li>
					<li>May not contain sexually oriented material.</li>
				</ol>
			</li>
			<li>Affiliate may not initiate or assist in the transmission of commercial e-mail using an e-mail address: (1) that was collected through automated means, from a third party website in violation of that third party's posted privacy policy, or (2) that was generated through automated means by combining names, letters or numbers into numerous permutations. Affiliate may not conduct fraudulent activities related to electronic mail, including but not limited to the material falsification of header or transmission information, the unauthorized use of someone else's computer to send bulk commercial e-mail, and the registration of an e-mail account that materially falsifies the identity of the actual registrant.</li>
			<li>Endorsements: Every endorsement, including testimonial, published by Affiliate regarding us or any of our products or services must be truthful, substantiated, and be neither deceptive nor unfair, as required by the Federal Trade Commission’s Guides Concerning Use of Endorsements and Testimonials in Advertising (16 Code of Federal Regulations Part 255) as now or hereafter amended.</li>
			<li>In addition, in the event of a breach of this E-mail Marketing and Endorsements provision by Affiliate and/or a third party or licensee, in addition to all other remedies available to it, Rugs.shop LLC shall have the right to refuse to make payments to an Affiliate. </li>
		</ol>
	</li>
	<li>
		<h4>Assignment:</h4>
		Neither this Agreement nor any of your rights, interests, duties, or obligations shall be assigned, transferred or delegated to any other person, firm, or corporation without our prior written consent. Any attempted assignment or delegation by Affiliate without such prior written consent shall be void and without effect. We may assign this Agreement upon written notice to you.
	</li>
	<li>
		<h4>Reservation of Rights:</h4>
		Rugs.shop LLC may terminate this Agreement at any time if your site is determined (at our sole discretion) to be unsuitable for the Program, even if your site has not changed since the time that your Affiliate Program application was accepted. Rugs.shop LLC also reserves the right to monitor Affiliate sites at any time to determine whether they are in compliance with this Agreement.
	</li>
	<li>
		<h4>Governing Law:</h4>
		The laws of the United States and the Commonwealth of Pennsylvania will govern this Agreement, without reference to rules governing choice of laws. Any action relating to this Agreement must be brought in the federal or state courts located in Royersford, PA and you irrevocably consent to the jurisdiction of such courts. You may not assign this Agreement, by operation of law or otherwise, without our prior written consent. Subject to that restriction and Section 21, this Agreement will be binding on, inure to the benefit of, and enforceable against the parties and their respective successors and assigns. Our failure to enforce your strict performance of any provision of this Agreement will not constitute a waiver of our right to subsequently enforce such a provision or any other provision of this Agreement. If any provision of part of this Agreement shall be held unenforceable, such unenforceable provision shall be stricken from the Agreement, and the remainder of this Agreement shall nevertheless remain in full force and effect, and the Agreement shall be construed to the greatest extent possible to give effect to the intent of the parties with respect to the stricken provision.
	</li>
	<li>
		<h4>Intellectual Property:</h4>
		We and our licensors own and retain all rights, title, and interest in and to the products and services available on Rugs.shop LLC website and all ideas, concepts, methodologies, formats, specifications, and other know-how furnished by us or our licensors in connection with the performance of its obligations hereunder, as well as all related patents, copyrights, trademarks, trade secrets, and other proprietary and intellectual property rights. The products and services available on Rugs.shop LLC website are protected by the laws of copyright, patent, trade secret, trademarks, and any other intellectual or industrial property rights applicable to the Products. Affiliate neither acquires nor is granted in any manner whatsoever any right, title or interest of any nature in and to the products and services available on Rugs.shop LLC website by virtue of this Agreement.
	</li>
	<li>
		<h4>Fulfillment:</h4>
		Rugs.shop LLC. will be responsible for all order fulfillment services, we will ship products directly to customers that purchase from the ecommerce site. In most cases, we have direct manufacturer relationships and products will ship directly from manufacturer warehouses.
	</li>
	<li>
		<h4>Customer Returns:</h4>
		Rugs.shop LLC. will be responsible for and will accept and process returns of and provide refunds and adjustments to consumers as needed for any orders placed through our websites.  We have a 30 day return policy for all consumers and they may send the product back for any reason but are required to pay for the return shipping.  Unless the product was damaged during shipping and/or a manufacturer defect.  Rugs.shop LLC as part of servicing online transactions will manage all of these services. 
	</li>
	<li>
		<h4>Returns to You and Disposal:</h4>
		If a consumer tries to return products directly to you, please call our customer service number immediately and we will instruct you and the customer on the next steps to complete the return.  We also have an online RMA process that consumers will be able to use.  We recommend that you immediately direct them back online to fill out the return request authorization (RMA). 
	</li>
	<li>
		<h4>Customer Service:</h4>
		Rugs.shop LLC will provide all customer service support as a result of consumers purchasing directly online through the Rugs.shop LLC owned ecommerce website.  You will have no customer service obligations other than to pass any inquiries onto our attention at the customer service phone provided on the branded website or the support email. We will be responsible for and have sole discretion regarding all customer service issues relating to packaging, handling and shipment, and customer returns, refunds, and adjustments related to Rugs.shop LLC. fulfillment of ecommerce transaction orders. We will have the right to determine whether a customer will receive a refund, adjustment or replacement for any Rugs.shop LLC. order.  You will have no obligation or responsibility for this.
	</li>
	<li>
		<h4>Online Pricing:</h4>
		Rugs.shop LLC will have ultimate responsibility for all pricing provided on any Rug Shop website powered by Rugs.shop LLC. We will from time to time have a customized catalog and/or pricing for specific affiliates.  However, these are decided on a case by case basis and Rugs.shop LLC shall have final decision and an affiliate can then decide if they wish to participate in the program or remove themselves.  We do comply with all manufacturers required online MAP pricing.
	</li>
	<li>
		<h4>Online Products:</h4>
		Rugs.shop LLC will be responsible for all products provided on any Rug Shop website powered by Rugs.shop LLC. We will from time to time have a customized catalog of products for specific affiliates.  However, these are decided on a case by case basis and Rugs.shop LLC shall have final decision approval on all products and an affiliate can decide if they wish to participate in the program or remove themselves. For certain designer and manufacture brands we cannot guarantee that the site will be approved by all brands and manufacturers.  This will also be determined on case by case basis.  We do comply with all manufacture and designer brand standards and use of the product policies. 
	</li>
	<li>
		<h4>Shipping:</h4>
		Rugs.shop LLC will be responsible for all shipping promotions and/or calculations within the ecommerce websites.  Free shipping is utilized as a common online promotion.  Affiliates recognize and accept these terms.
	</li>
	<li>
		<h4>Taxes:</h4>
		Rugs.shop LLC will be responsible for all collection and submission of taxes.  We charge tax for all orders shipping to a location in Pennsylvania.  
	</li>
</ol>
            </div>
		)
	}

}