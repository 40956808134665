import { basicJSONPOST } from "../../api/fetchHelpers";

export async function emailCall(id, email, endpoint) {
    const response = await fetch(endpoint+"send/"+id+"/"+email, basicJSONPOST());
    if (!response.ok) {
        const message = 'An error has occured: '+response.status;
        throw new Error(message);
    }
    const data = await response.json();
    return data;
}