import React from 'react';

import { basicGET } from '../../api/fetchHelpers';
import velocityLogo from '../../images/velocity-logo.png';
import mmLogo from '../../images/mm-logo.svg';
import { InputRow } from '../../helpers/inputRow';
import { managers, testLoginExpires } from '../../helpers/uiHelpers';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			clientList: [],
			currentRoles: [],
			allClients: [],
			managerList: managers,
			logo: false,
			multiClient: false,
		}
		this.loadClient = this.loadClient.bind(this);
		this.buildList = this.buildList.bind(this);
	}
	componentDidUpdate(prevProps) {
		if ((this.props.roles !== prevProps.roles) && ( this.props.roles.includes("portalAdm") || this.props.roles.includes("portalVlctyMngr")) ) {
			this.getClientList();
		}
		if (this.props.currentClient && (this.props.currentClient.velocity !== this.state.logo)) {
			this.setState({logo: this.props.currentClient.velocity});
		}
		if ((this.props.roles !== prevProps.roles) && (this.props.roles.includes("portalCst") && this.props.clientCodes.length > 1)) {
			this.setState({multiClient: true})
			this.getClientList();
		}
	}
	getClientList() {
		testLoginExpires();
		//console.log("is admin, get the clients");
		fetch(this.props.endpoints.serviceClientsList, basicGET())
		.then(response => {
			if (response.ok) { return response.json(); } 
			else { throw new Error('Something went wrong fetching all the clients...'); }
		})
		.then(data => {
			// console.log(data)
			this.setState({allClients: data});
			this.buildList();
		})
		.catch(error => console.log(error));
	}
	buildList() {
		var optionsArr = [];
		var data = this.state.allClients;
		
		var i;
		if (this.props.roles.includes("portalCst") && this.props.clientCodes.length > 1) {
			for (i = data.length - 1; i >= 0; i--) {
				if ( this.props.clientCodes.includes(data[i].clientCode)) {
					console.log("found the clientCode")
					optionsArr.push(
						{ "name" : data[i].name, "value" : data[i].clientCode },
					);
				}
			}
		}
		if (this.props.roles.includes("portalVlctyMngr")) {
			for (i = data.length - 1; i >= 0; i--) {
				if (data[i].status === "active" && data[i].velocity === true && data[i].clientCode !== "bar") {
					optionsArr.push(
						{ "name" : data[i].name, "value" : data[i].clientCode },
					);
				}
			}
		} 
		if (this.props.roles.includes("portalAdm")) {
			var manager = document.querySelector('select[name="managerList"]').value;
			// console.log(manager);
			if (manager !== "Select..." && manager !== "none") {
				data = data.filter( client => { return client.manager === manager; });
			}

			for (i = data.length - 1; i >= 0; i--) {
				if (data[i].status === "active") {
					optionsArr.push(
						{ "name" : data[i].name, "value" : data[i].clientCode },
					);
				}
			}
		}
		optionsArr = optionsArr.reverse();
		// console.log(optionsArr);
		if (JSON.stringify(this.state.clientList) !== JSON.stringify(optionsArr)) {
			this.setState({ clientList: optionsArr });
		}
	}
	loadClient(e) {
		testLoginExpires();
		var clientCode = e.target.value
		var changeClient = this.props.changeClient

		changeClient(clientCode);
	}
	toggleDates() {
		testLoginExpires();
		var button = document.querySelector(".date-controls button.set-dates");
		var dropdown = document.querySelector(".date-controls .date-inputs");
		dropdown.classList.toggle("active");
		button.classList.toggle("active");
	}
	toggleAccountMenu() {
		testLoginExpires();
		var button = document.querySelector("header .account-mobile-nav");
		var dropdown = document.querySelector("header .account-controls");
		dropdown.classList.toggle("active");
		button.classList.toggle("active");

		var nav = document.querySelector("header .nav-mobile-nav");
		var menu = document.querySelector(".body-container > nav");
		menu.classList.remove("active");
		nav.classList.remove("active");
	}
	toggleNavMenu() {
		testLoginExpires();
		var button = document.querySelector("header .nav-mobile-nav");
		var dropdown = document.querySelector(".body-container > nav");
		dropdown.classList.toggle("active");
		button.classList.toggle("active");

		var nav = document.querySelector("header .account-mobile-nav");
		var menu = document.querySelector("header .account-controls");
		menu.classList.remove("active");
		nav.classList.remove("active");
	}
	clearClient() {
		localStorage.removeItem('clientCode');
		var changeClient = this.props.changeClient
		changeClient();
	}

	render() {
		const { clientList, managerList, logo, multiClient } = this.state;

		const client = this.props.currentClient;
		const retailer = this.props.retailer;
		const isLoggedIn = this.props.isLoggedIn;
		const logout = this.props.logout;
		const roles = this.props.roles;
		// console.log(roles);
		const dates = this.props.dates;
		const changeDates = this.props.changeDates;
		const clearDates = this.props.clearDates;
		const hideHistorical = this.props.hideHistorical;

		return (
			<header className={isLoggedIn ? 'logged-in' : 'logged-out'}>
	            {logo === true ? (
	            	<img src={velocityLogo} alt="logo" className="header-logo" />
	            ) : (
	            	<img src={mmLogo} alt="logo" className="header-logo" />
	            )}

	            {(isLoggedIn) && (
	            	<React.Fragment>
	            		<div className="account-mobile-nav" onClick={() => this.toggleAccountMenu()}>
	            			<i className="fas fa-cog"></i>
	            		</div>
	            		<div className="nav-mobile-nav" onClick={() => this.toggleNavMenu()}>
	            			<i className="fas fa-bars"></i>
	            		</div>
		            	<div className="account-controls">
		            		{(roles.includes("portalAdm")) && (
		            			<div id="am-picker">
			            			AM:
			            			<InputRow type="select" property="managerList" options={managerList} onChange={this.buildList} />
			            		</div>
		            		)}
		            		{( (roles.includes("portalAdm") || roles.includes("portalVlctyMngr") || multiClient) && clientList && client ) && (
								<div id="client-picker">
									Client:
									<span className="clear-client"><i className="far fa-window-close" onClick={() => this.clearClient()}></i></span>
									<InputRow type="select" property="clientList" options={clientList} value={client.clientCode} onChange={this.loadClient} />
								</div>
		            		)}
		            		
		            		{((roles.includes("portalAdm") || roles.includes("portalVlctyMngr") || multiClient) && clientList && !client ) && (
		            			<React.Fragment>
		            				<InputRow type="select" property="clientList" options={clientList} onChange={this.loadClient} />
		            			</React.Fragment>
		            		)}
			            	{(roles.includes("portalCst") && !multiClient && client && !retailer) && (
		            			<span className="client-name">{client.name}</span>
		            		)}
		            		{(roles.includes("portalCst") && !multiClient && client && retailer) && (
		            			<span className="client-name">{retailer.name}</span>
		            		)}
		            		<div className="date-controls">
			            		<button className="white set-dates" onClick={() => this.toggleDates()}>Dates</button>
			            		<div className="date-inputs">
			            			<h5>Quick Date Range:</h5>
			            			<div className="inputs">
			            				<button className="white run-dates" onClick={() => changeDates("week")}>Week</button>
			            				<button className="white run-dates" onClick={() => changeDates("month")}>Month</button>
			            				<button className="white run-dates" onClick={() => changeDates("quarter")}>Quarter</button>
			            				<button className="white run-dates" onClick={() => changeDates("year")}>Year</button>
			            			</div>
			            			<h5>Custom Date Range</h5>
				            		<strong>Display From:</strong>
				            		<div className="inputs">
					            		<InputRow
					            			type="date"
					            			property="reportStart"
					            			id="reportStart"
					            			value={dates[0]}
					            			required="required"
					            		/>
					            		<InputRow
					            			label="To:"
					            			type="date"
					            			property="reportEnd"
					            			id="reportEnd"
					            			value={dates[1]}
					            			required="required"
					            		/>
					            	</div>
					            	<strong>
					            		Compare To:
					            		<span className="clear-dates" onClick={clearDates}>
					            			<i className="far fa-window-close"></i> Clear
					            		</span>
					            	</strong>
					            	{(!hideHistorical) ? (
					            		<div className="inputs">
						            		<InputRow
						            			type="date"
						            			property="prevReportStart"
						            			id="prevReportStart"
						            			value={dates[2]}
						            			required="required"
						            		/>
						            		<InputRow
						            			label="To:"
						            			type="date"
						            			property="prevReportEnd"
						            			id="prevReportEnd"
						            			value={dates[3]}
						            			required="required"
						            		/>
						            	</div>
					            	) : (
					            		<div className="inputs">
					            			<InputRow
						            			type="date"
						            			property="prevReportStart"
						            			id="prevReportStart"
						            			value={dates[2]}
						            			required="required"
						            			disabled
						            		/>
						            		<InputRow
						            			label="To:"
						            			type="date"
						            			property="prevReportEnd"
						            			id="prevReportEnd"
						            			value={dates[3]}
						            			required="required"
						            			disabled
						            		/>
						            	</div>
					            	)}
					            	<div className="apply">
					            		<button className="white run-dates" onClick={() => changeDates()}>Apply Custom Date Range</button>
					            	</div>
				            	</div>
			            		
			            	</div>
			                <button className="green" onClick={logout}>Sign Out</button>
			            </div>
			        </React.Fragment>
	            )}
	        </header>
		)
	}
}

export default Header;