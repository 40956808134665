import {basicGET} from '../../api/fetchHelpers'


export const getRetailer = (endpoint, affiliateCode, accessToken) => {
    return fetch(endpoint +"fyw/retailer?affiliateCode="+ affiliateCode, basicGET(accessToken))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the retailer data ...'); }
    })
    .then(data => {
        // console.log(data);
        return data;
    })
    .catch(error => console.log(error));
}


export const getLeadData = (endpoint, clientCode, startDate1, endDate1, startDate2, endDate2, accessToken, affiliateCode, showTest) => {
	let clientCodeStr = "&clientCode="+clientCode
    if (clientCode === "allvlcty") {
        clientCodeStr = "&velocity=true";
    }
    let display = localStorage.getItem('showTest');
    if (display === "true") {
        showTest = "&showTest=true";
    } else {
        showTest = "&showTest=false";
    }

    let reqURL = endpoint+"report"+
        "?startDate1="+startDate1+
        "&endDate1="+endDate1+
        "&startDate2="+startDate2+
        "&endDate2="+endDate2+
        clientCodeStr+
        showTest;
    if (affiliateCode) {
        reqURL = reqURL + "&affiliateCode="+affiliateCode;
    }

    return fetch(reqURL, basicGET(accessToken))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the clients lead tracking data ...'); }
    })
    .then(data => { return data; })
    .catch(error => { console.log(error); });
}


export const getCalls = (endpoint, clientCode, startDate1, endDate1, startDate2, endDate2, accessToken, affiliateCode) => {
	let clientCodeStr = "&clientCode="+clientCode
    if (clientCode === "allvlcty") {
        clientCodeStr = "&velocity=true";
    }

    let reqURL = endpoint+"report"+
        "?startDate1="+startDate1+
        "&endDate1="+endDate1+
        "&startDate2="+startDate2+
        "&endDate2="+endDate2+
        clientCodeStr;
    if (affiliateCode) {
        reqURL = reqURL + "&affiliateCode="+affiliateCode;
    }
    return fetch(reqURL, basicGET(accessToken))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the clients phone call tracking data ...'); }
    })
    .then(data => { return data; })
    .catch(error => { console.log(error); });
}


export const getEmails = (endpoint, clientCode, dates, accessToken) => {
	return fetch(
        endpoint+"report/email"+
        "?startDate1="+dates[0]+
        "&endDate1="+dates[1]+
        "&startDate2="+dates[2]+
        "&endDate2="+dates[3]+
        "&clientCode="+clientCode, basicGET(accessToken))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the clients promotional emails data ...'); }
    })
    .then(data => { return data; })
    .catch(error => { console.log(error); });
}


export const getGA = (endpoint, clientCode, startDate1, endDate1, startDate2, endDate2, accessToken) => {
	return fetch(
        endpoint+clientCode+
        "/summary?"+
        "startDate1="+startDate1+
        "&endDate1="+endDate1+
        "&startDate2="+startDate2+
        "&endDate2="+endDate2, basicGET(accessToken))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the clients google analytics data ...'); }
    })
    .then(data => { return data; })
    .catch(error => { console.log(error); });
}

export const getStatDistributions = (endpoint, clientCode, startDate, endDate, accessToken) => {
    return fetch (
        endpoint+clientCode+
        "/stats?"+
        "startDate="+startDate+
        "&endDate="+endDate,
        basicGET(accessToken)
    ).then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the clients getSTAT data ...'); }
    })
    .then(data => {
        // console.log(data);
        if (data.length !== 0) {
            // console.log("pass");
            var keywords = data[1].Response.Result;
            for (var i = keywords.length - 1; i >= 0; i--) {
                var rank = keywords[i].KeywordRanking.Google.Rank;
                // var url = keywords[i].KeywordRanking.Google.Url;
                if (rank === null) {
                    data[1].Response.Result[i].KeywordGoogleRank = 100;
                    data[1].Response.Result[i].KeywordGoogleUrl = "not available";
                } else {
                    data[1].Response.Result[i].KeywordGoogleRank = keywords[i].KeywordRanking.Google.Rank;
                    data[1].Response.Result[i].KeywordGoogleUrl = keywords[i].KeywordRanking.Google.Url;
                }
            }
        }
        return data;
    })
    .catch(error => { console.log(error); });
}

export const getKeywordRankingOverTime = (endpoint, clientCode, keywordId, accessToken) => {
    return fetch(
        endpoint+clientCode+
        "/stats/ranking/"+
        keywordId,
        basicGET(accessToken)
    ).then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the clients getSTAT data ...'); }
    })
    .then(data => { return data; })
    .catch(error => { console.log(error); });
}


export const getFB = (endpoint, clientCode, startDate, endDate, accessToken, affiliateCode) => {
	if (!affiliateCode) {
        affiliateCode = "";
    }

    return fetch(
        endpoint+"facebook/"+clientCode+
        "/details/"+
        affiliateCode+
        "?startDate1="+startDate+
        "&endDate1="+endDate,
        basicGET(accessToken))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the clients facebook data ...'); }
    })
    .then(data => { return data; })
    .catch(error => {
    	// console.log(error);
    	error.success = false;
    	return error;
    });
}


export const getRugsData = (clientCode, endpoint, startDate, endDate, affiliateCode, accessToken) => {
	// using products 1 instead of endpoint, due to issues with load balancer..
    return fetch(
		"https://products.mm-api.agency/orders/rugs"+
		"?reportStart="+startDate+
		"&reportEnd="+endDate+
		"&affiliateCode="+affiliateCode, basicGET(accessToken))
	.then(response => {
		if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the clients rug shop order data ...'); }
	})
	.then(data => { //console.log(data);
        return data;
    })
    .catch(error => {
    	console.log(error);
    	error.success = false;
    	return error;
    });
}


export const getBlogs = (endpoint, clientCode, accessToken) => {
    return fetch(
        endpoint+clientCode,
        basicGET(accessToken))
    .then(response => {
        if (response.ok) { return response.json(); } else { throw new Error('Something went wrong fetching the clients facebook data ...'); }
    })
    .then(data => { return data })
    .catch(error => {
        // console.log(error);
        error.success = false;
        return error;
    });
}