import React from 'react';

export class SearchForm extends React.Component {
	constructor(props) {
		super(props);
		this.searchList = this.searchList.bind(this);
	}

	searchList(page) {
		// Declare variables
		var input, filter, li, errorMessage, i;
		input = document.querySelectorAll('#metrics-'+page+' input#search-'+page)[0];
		filter = input.value.toUpperCase();
		li = document.querySelectorAll("#metrics-"+page+" ."+page+"-list .table-body li");
		errorMessage = document.querySelectorAll("#metrics-"+page+" #search-error")[0];

		if (filter) {
			if (errorMessage) {
				errorMessage.remove();
				input.classList.remove("error");
			}
			var count = li.length;
			for (i = 0; i < li.length; i++) {

				if (li[i].innerHTML.toUpperCase().indexOf(filter) > -1) {
					li[i].style.display = "block";
					count++;
				} else {
					li[i].style.display = "none";
					count--;
				}
				
			}

			if (count === 0) {
				document.querySelectorAll("#metrics-"+page+" #search-"+page)[0].insertAdjacentHTML(
					'beforeend',
					'<em id="search-error">Your search returned no results. Please try searching another status, or increasing the Results Per Page...</em>'
					);
				input.classList.add("error");
			}
		} else {
			if (filter) {
				if (!errorMessage) {
					document.querySelectorAll("#metrics-"+page+" .search-"+page)[0].insertAdjacentHTML(
						'beforeend',
						'<em id="search-error">Something went wrong with your search</em>'
						);
				}
			} else {
				for (var b=0;b<li.length;b+=1){
					li[b].style.display = '';
				}
			}
		}
	}

	render() {
		let page = this.props.page;
		
		return (
			<form className="form search-form" id={"search-form-"+page}>
				<input
				type="text"
				className="input"
				id={"search-"+page}
				placeholder={"search "+page+" results"}
				onKeyUp={() => this.searchList(page)}
				/>
			</form>
		)
	}
}